import React from "react";

function hPage10(props) {
	return (
		<>
			<div className="hpage10">
				<div className="wrap wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
					<h4>常見問題</h4>
					<ul>
						<li>
							<h3>如何立即付費，完成購買本課程？</h3>
							<div className="del">
								1. 登入連結本課程購買頁版面，可用 line 挷定本網站帳號，即可購買老師的課程。<br />
								2. 進入付款頁版面，選擇您的支付方式，（Web ATM、信用卡）。<br />
								3. 完成支付並經會計審核後，收據連結會發送至您註冊的 line 帳號，敬請自行查詢及列
								印出來。<br />
								4. 完成付款程序後，您即可享受本網站該購得課程的線上使用權利。
							</div>
						</li>
						<li>
							<h3>本課程服務聲明</h3>
							<div className="del">
								Videostores 於此聲明所提供的服務、產品、資訊等內容，不一定符合每個人對課程的期望與其他特定要求， 因此是否購買使用本服務，應由個人自行考慮與自負風險。
							</div>
						</li>
						<li>
							<h3>學習本課程時，需要預備服裝人台嗎？</h3>
							<div className="del">
								本課程以立裁打版為核心重點，因此大部分授課還是會在人台上操作教學，使用人台的優點是可以直接拿布在上面折疊，造型，裁剪製作縫合。因此是否需要預備人台，講師也給學員以下貼心建議，學員可評估自身情況與需求決定是否購買。<br />

								1.如果您對立裁製作非常有興趣，而且想進入服裝相關產業，建議您可預備 9 號尺寸人台。<br />
								2.如果您希望透過課程幫自己做衣服，也有時間專研打版，可以買接近自己身型尺寸的人台<br />
								3.如果您還是對課程非常有興趣，但苦惱家裡沒有空間擺放，購買會造成困擾，那麼不用人台也可以，您只要認真抓住課堂重點，把每個款式原型修飾完美，也能把立裁打版學好。
							</div>
						</li>
						<li>
							<h3>上課前，請先確認設備是否符合標準？</h3>
							<div className="del">
								為提供您優質的線上課程體驗，建議先確認您的上課設備(電腦、ipad、手機)，是否符合下表所列基本規格要求，若您的設備暫無法符合以下建議，可能會有無法使用本服務或影響本服務之品質等情況，請審慎評估是否購買。如您在上課中遇到其他設備問題也可以與我們聯繫，我們會盡快回應與協助。<br />
								<img src={require("assets/FashionDraping/images/qm40.png").default} alt="" />
							</div>
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default hPage10;
import { updateItem } from "./DynamodbMethods.js";

// {
//   "PK": "M#coreytu",
//   "SK": "VPID#miffy-1632461817",
//   "D_Flag": false,
//   "Expire": 1637225099079,
//   "MemberID": "coreytu",
//   "PlayCount": 0,
//   "Start": 1636620299079,
//   "TTL": 604800000,
//   "TYPE": "M_Shelf",
//   "UpdateTime": 1636620299079,
//   "ProductType": "VPID",
//   "ProductSKU": "miffy-1632461817",
//   "W_Flag": false
// }

async function updateMemberVideoExpire(memberVideoExpireEntity) {
    
    let result;

    if (memberVideoExpireEntity) {

        memberVideoExpireEntity.Type = "M_Shelf";

        if (memberVideoExpireEntity.PK) {
            if (memberVideoExpireEntity.PK.length > 0) {
                // do nothing
            } else {
                if (memberVideoExpireEntity.MemberID){
                    if (memberVideoExpireEntity.MemberID.length > 0) {
                        memberVideoExpireEntity.PK = "M#"+memberVideoExpireEntity.MemberID
                    }
                }
            }
        } else {
            if (memberVideoExpireEntity.MemberID){
                if (memberVideoExpireEntity.MemberID.length > 0) {
                    memberVideoExpireEntity.PK = "M#"+memberVideoExpireEntity.MemberID
                }
            }
        }

        if (memberVideoExpireEntity.SK) {
            if (memberVideoExpireEntity.SK.length > 0) {
                // do nothing
            } else {
                if (memberVideoExpireEntity.ProductType && memberVideoExpireEntity.ProductSKU){
                    if (memberVideoExpireEntity.ProductType.length > 0 && memberVideoExpireEntity.ProductSKU.length > 0) {
                        memberVideoExpireEntity.SK = memberVideoExpireEntity.ProductType+"#"+memberVideoExpireEntity.ProductSKU;
                    }
                }
            }
        } else {
            if (memberVideoExpireEntity.ProductType && memberVideoExpireEntity.ProductSKU){
                if (memberVideoExpireEntity.ProductType.length > 0 && memberVideoExpireEntity.ProductSKU.length > 0) {
                    memberVideoExpireEntity.SK = memberVideoExpireEntity.ProductType+"#"+memberVideoExpireEntity.ProductSKU;
                }
            }
        }

        if (memberVideoExpireEntity.PK && memberVideoExpireEntity.SK) {
            if (memberVideoExpireEntity.PK.length > 0 && memberVideoExpireEntity.SK.length > 0) {
                result = await updateItem(memberVideoExpireEntity);
            }
        }
    }
    return result;
}
  
export default updateMemberVideoExpire;
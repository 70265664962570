class MemberVideoExpireEntity {
    
    constructor(MemberID, ProductType, ProductSKU) {
        this.PK = MemberID ? "M#"+MemberID:"";
        this.SK = (ProductType && ProductSKU) ? ProductType+"#"+ProductSKU:"";
        this.Type = "M_Shelf";
        this.MemberID = MemberID;
        this.ProductType = ProductType;
        this.ProductSKU = ProductSKU;
        // this.Start = "";
        // this.Expire = "";
        // this.TTL = 0;
        // this.UpdateTime = "";
        // this.PlayCount = 0;
        // this.D_Flag = false;
        // this.W_Flag = false;
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK
        };
        if (this.TYPE) {
            obj["TYPE"] = this.TYPE;
        }
        if (this.MemberID) {
            obj["MemberID"] = this.MemberID;
        }
        if (this.ProductType) {
            obj["ProductType"] = this.ProductType;
        }
        if (this.ProductSKU) {
            obj["ProductSKU"] = this.ProductSKU;
        }
        if (this.Start) {
            obj["Start"] = this.Start;
        }
        if (this.Expire) {
            obj["Expire"] = this.Expire;
        }
        if (this.TTL !== undefined && this.TTL !== null) {
            obj["TTL"] = Math.floor(this.TTL);
            if (isNaN(obj["TTL"])) {
                obj["TTL"] = 0;
            }
        }
        if (this.UpdateTime) {
            obj["UpdateTime"] = this.UpdateTime;
        }
        if (this.PlayCount !== undefined && this.PlayCount !== null) {
            obj["PlayCount"] = Math.floor(this.PlayCount);
            if (isNaN(obj["PlayCount"])) {
                obj["PlayCount"] = 0;
            }
        }
        if (this.D_Flag !== undefined && this.D_Flag !== null) {
            if (this.D_Flag) {
                obj["D_Flag"] = true;
            } else {
                obj["D_Flag"] = false;
            }
        }
        if (this.W_Flag !== undefined && this.W_Flag !== null) {
            if (this.W_Flag) {
                obj["W_Flag"] = true;
            } else {
                obj["W_Flag"] = false;
            }
        }
        return obj;
    }
}

const memberVideoExpireEntityFromObject = (item) => {
    let entity = new MemberVideoExpireEntity("", "", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    if (item.Type) {
        entity.Type = item.Type;
    }
    if (item.MemberID) {
        entity.MemberID = item.MemberID;
    }
    if (item.ProductType) {
        entity.ProductType = item.ProductType;
    }
    if (item.ProductSKU) {
        entity.ProductSKU = item.ProductSKU;
    }
    if (item.Start) {
        entity.Start = item.Start;
    }
    if (item.Expire) {
        entity.Expire = item.Expire;
    }
    if (item.TTL !== undefined && item.TTL !== null) {
        entity.TTL = Math.floor(item.TTL);
        if (isNaN(entity.TTL)) {
            entity.TTL = 0;
        }
    }
    if (item.UpdateTime) {
        entity.UpdateTime = item.UpdateTime;
    }
    if (item.PlayCount !== undefined && item.PlayCount !== null) {
        entity.PlayCount = Math.floor(item.PlayCount);
        if (isNaN(entity.PlayCount)) {
            entity.PlayCount = 0;
        }
    }
    if (item.D_Flag !== undefined && item.D_Flag !== null) {
        if (item.D_Flag) {
            entity.D_Flag = true;
        } else {
            entity.D_Flag = false;
        }
    }
    if (item.W_Flag !== undefined && item.W_Flag !== null) {
        if (item.W_Flag) {
            entity.W_Flag = true;
        } else {
            entity.W_Flag = false;
        }
    }
    return entity;
}

export { MemberVideoExpireEntity, memberVideoExpireEntityFromObject };

// {
//   "PK": "M#coreytu",
//   "SK": "VPID#miffy-1632461817",
//   "D_Flag": false,
//   "Expire": "20210101125959",
//   "MemberID": "coreytu",
//   "PlayCount": 0,
//   "Start": "20210101125959",
//   "TTL": 604800000,
//   "TYPE": "M_Shelf",
//   "UpdateTime": "20210101125959",
//   "ProductType": "VPID",
//   "ProductSKU": "miffy-1632461817",
//   "W_Flag": false
// }
import { getFirstItem, putItem, updateItem } from "./DynamodbMethods.js";

// {
//     "PK": "CDN",
//     "SK": "Secret",
//     "Secret": ""
//   }

async function putCDNSecret(cdnSecretEntity) {

    let result;
    let oldEntity = cdnSecretEntity;
    if (cdnSecretEntity) {
        cdnSecretEntity.PK = "CDN";
        cdnSecretEntity.SK = "Secret";
        if (cdnSecretEntity.Secret) {
            if (cdnSecretEntity.Secret.length > 0) {
                result = await putItem(cdnSecretEntity);
            }
        }
    }
    if (result) {
        return cdnSecretEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
    
}
  
export default putCDNSecret;
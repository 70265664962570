import React from "react";

function hPage4(props) {
	return (
		<>
			<div className="hpage4">
				<div className="wrap">
					<div className="tit wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1s">
						<h2><span>精細教學解析</span>專業技法讓您學到會</h2>
						<p>頂尖打版工藝 立裁大師親自授課</p>
					</div>
					<div className="hd clearfix wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
						<div className="pic">
							<img src={require("assets/FashionDraping/images/qm13.jpg").default} alt="" />
						</div>
						<div className="txt">
							<h3>課程規劃</h3>
							<div className="del">
								本課程透過人台的選擇、測量方式、標示線的基礎學習，以及上身、裙子原型的立裁操作學習、褶子轉移概念到原型打版等技法重點與應用練習的豐富內容，幫助學員精準掌握正確的服裝構成知識與立體剪裁技術，而立裁就是需要拋開數據公式的限制，讓服裝能創造出更多的創新火花，是高訂服裝的必備技能。
							</div>
						</div>
					</div>
					<div className="bd">
						<ul>
							<li className="wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="1s" id="di1">
								<div className="txt">
									<h2>第0章 人台標示教學</h2>
									<p>本堂課重點要介紹在立裁打版中，最基礎也最重要的人台工具與其正確用法，教您學會如何選擇適合的人台以及如何正確操作標示線，包括：領圍、胸圍、腰圍、臀圍以及前中心、後中心、脇邊線等標示、釘針使用方式以及如何將手臂釘上人台等說明，幫助打版過程中能準確定型，避免服裝水平線偏離的錯誤操作。</p>
									<div className="ovs">
										<h4>課程明細</h4>
										<div className="ds">
											<table border="0" cellSpacing="0" cellPadding="0">
											<tbody>
												<tr>
													<td style={{width:12+"%"}}>第1節</td>
													<td style={{width:30+"%"}}>人臺的選擇</td>
													<td style={{width:52+"%"}}>人臺選擇方法及工具介紹</td>
													<td style={{width:6+"%"}}>00:04:30</td>
												</tr>
												<tr>
													<td>第2節</td>
													<td>橫向標示</td>
													<td>腰圍標示 胸圍標示 臀圍標示 領圍標示</td>
													<td>00:25:04</td>
												</tr>
												<tr>
													<td>第3節</td>
													<td>直向標示</td>
													<td>前中心線標示 後中心線標示</td>
													<td>00:32:44</td>
												</tr>
												<tr>
													<td>第4節</td>
													<td>側邊線 </td>
													<td>側邊線釘的製作方式說明</td>
													<td>00:36:34</td>
												</tr>
												<tr>
													<td>第5節</td>
													<td>袖圈標示</td>
													<td>袖圈如何標示說明</td>
													<td>00:37:47</td>
												</tr>
												<tr>
													<td>第6節</td>
													<td>上手臂</td>
													<td>如何將手臂釘上人台說明</td>
													<td>00:41:27</td>
												</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="swipban">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm15.jpg").default} alt="" />
										</div>
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm155.jpg").default} alt=""/>
										</div>	
									</div>
									<div className="spot"></div>
								</div>
							</li>
							<li className="wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1s" id="di2">
								<div className="txt">
									<h2>第1章 立裁概念與無袖上衣原型</h2>
									<p>本堂課會透過『無袖上衣的原型』的製作過程，同步講解立裁的基礎概念與要點。我們從胚布的整燙、畫線，到胚布直接在人台上操作依人體立體剪裁，詳細講解每個步驟、順序，和絲針正確別法、手勢撫平技巧。操作後的胚布版於平面修正線條和別胚針法，並再次置於人台上檢視外型，進行二次修正胚布的平整與鬆量，最後才能紙型複製打版。</p>
									<div className="ovs">
										<h4>課程明細</h4>
										<div className="ds">
											<table border="0" cellSpacing="0" cellPadding="0">
												<tbody>
												<tr>
													<td style={{width:12+"%"}}>第1節</td>
													<td style={{width:30+"%"}}>PPT講解何謂立裁</td>
													<td style={{width:52+"%"}}>源起與發展 作品與講解</td>
													<td style={{width:6+"%"}}>00:09:30</td>
												</tr>
												<tr>
													<td>第2節</td>
													<td>胚布整燙畫線</td>
													<td>將所需要上人臺的線條處理整燙</td>
													<td>00:14:15</td>
												</tr>
												<tr>
													<td>第3節</td>
													<td>前片</td>
													<td>前片胸褶/腰褶操作技法</td>
													<td>00:30:08</td>
												</tr>
												<tr>
													<td>第4節</td>
													<td>後片 </td>
													<td>後片肩褶/腰褶操作技法</td>
													<td>00:43:30</td>
												</tr>
												<tr>
													<td>第5節</td>
													<td>整理胚布畫線</td>
													<td>立裁操作完成平面整理</td>
													<td>00:55:24</td>
												</tr>
												<tr>
													<td>第6節</td>
													<td>穿上人台展示</td>
													<td>在桌面別好後才能穿上人台檢視</td>
													<td>00:59:57</td>
												</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="swipban">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm16.jpg").default} alt=""/>
										</div>
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm166.jpg").default} alt=""/>
										</div>										
									</div>
									<div className="spot"></div>
								</div>
							</li>
							<li className="wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s" id="di3">
								<div className="txt">
									<h2>第2章 直裙原型</h2>
									<p>以最基礎的直裙原型講解裙子的結構重點。從直裙所需的基準線標示在胚布上，並針對裙子的穿著所需預留衣服的彈性鬆份，以及脇邊線的褶子利用技法，來操練裙子的褶子分配與褶長的取捨與概念，依序製作直裙前片、後片、取下後桌面整理胚布後別胚的技法展示。</p>
									<div className="ovs">
										<h4>課程明細</h4>
										<div className="ds">
											<table border="0" cellSpacing="0" cellPadding="0">
											<tbody>
												<tr>
													<td style={{width:12+"%"}}>第1節</td>
													<td style={{width:30+"%"}}>胚布整燙畫線</td>
													<td style={{width:52+"%"}}>將所需要上人臺的線條處理整燙</td>
													<td style={{width:6+"%"}}>00:00:03</td>
												</tr>
												<tr>
													<td>第2節</td>
													<td>橫前片直裙</td>
													<td>前片腰褶操作技法</td>
													<td>00:02:37</td>
												</tr>
												<tr>
													<td>第3節</td>
													<td>後片直裙</td>
													<td>後片腰褶操作技法</td>
													<td>00:11:08</td>
												</tr>
												<tr>
													<td>第4節</td>
													<td>胚布整理別胚 </td>
													<td>立裁操作完成後平面整理</td>
													<td>00:22:44</td>
												</tr>
												<tr>
													<td>第5節</td>
													<td>展示直裙</td>
													<td>將前後2片裙子穿上人台後檢視成品</td>
													<td>00:31:12</td>
												</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="swipban">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm17.jpg").default} alt="" />
										</div>
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm177.jpg").default} alt=""/>
										</div>										
									</div>
									<div className="spot"></div>
								</div>
							</li>
							<li className="wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="1s" id="di4">
								<div className="txt">
									<h2>第3章 褶子轉移概念與實操</h2>
									<p>時尚女裝能有變化萬千的設計，其中褶子的技法功不可沒，這也是最重要的打版技法之一。透過胸腰褶子以平轉、剪接線加上自展方式的操作，詳細解構設計圖立體結構。學員可跟著老師的步驟操作，由淺至深常做練習，輕鬆學會褶子轉移的各樣變化。</p>
									<div className="ovs">
										<h4>課程明細</h4>
										<div className="ds">
											<table border="0" cellSpacing="0" cellPadding="0">
											<tbody>
												<tr>
													<td style={{width:12+"%"}}>第1節</td>
													<td style={{width:30+"%"}}>平轉示範2款變化</td>
													<td style={{width:52+"%"}}>老師示範平轉款示２個變化的上半身褶子轉移</td>
													<td style={{width:6+"%"}}>00:00:00</td>
												</tr>
												<tr>
													<td>第2節</td>
													<td>公主線示範</td>
													<td>老師示範公主線如何轉移褶子</td>
													<td>00:08:57</td>
												</tr>
												<tr>
													<td>第3節</td>
													<td>切割線示範S型</td>
													<td>老師示範款示2切割線款示的上半身褶子轉移</td>
													<td>00:12:46</td>
												</tr>
												<tr>
													<td>第4節</td>
													<td>切割線示範Z型 </td>
													<td>老師示範款示切割線款示的上半身褶子轉移</td>
													<td>00:20:17</td>
												</tr>												
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="swipban">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm18.jpg").default} alt="" />
										</div>
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm188.jpg").default} alt=""/>
										</div>										
									</div>
									<div className="spot"></div>
								</div>
							</li>
							<li className="wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1s" id="di5">
								<div className="txt">
									<h2>第4章 褶子轉移&無領無袖版型如何一次到位</h2>
									<p>本堂課重點取至美國知名服裝設計教授—HelenJoseph-Armstrong所出版的服裝立體剪裁中，提及的七個無領無袖版型立裁技法，進階練習無領無袖的設計版型要如何服貼於人體，無論是深V領、削肩、平口設計或是露背等款式，透過課程詳細講述版型的重要性與操作方式，練習減少修改的次數，提高打版效率，讓打版技能一次到位。</p>
									<div className="ovs">
										<h4>課程明細</h4>
										<div className="ds">
											<table border="0" cellSpacing="0" cellPadding="0">
											<tbody>
												<tr>
													<td style={{width:`12%`}}>第1節</td>
													<td style={{width:30+"%"}}>高腰深V領上衣</td>
													<td style={{width:52+"%"}}>老師示範款示1的上半身摺子如何轉移一次到位</td>
													<td style={{width:6+"%"}}>00:10:00</td>
												</tr>
												<tr>
													<td>第2節</td>
													<td>削肩活褶高腰上衣</td>
													<td>老師示範款示2的上半身摺子如何轉移一次到位</td>
													<td>00:15:00</td>
												</tr>
												<tr>
													<td>第3節</td>
													<td>Z型小可愛上衣</td>
													<td>老師示範款示3的上半身摺子如何轉移一次到位</td>
													<td>00:30:00</td>
												</tr>												
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="swipban">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm19.jpg").default} alt="" />
										</div>
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm199.jpg").default} alt="" />
										</div>										
									</div>
									<div className="spot"></div>
								</div>
							</li>
							<li className="wow fadeInUp" data-wow-delay="0.8s" data-wow-duration="1s" id="di6">
								<div className="txt">
									<h2>第5章 裙子變化概念</h2>
									<p>延伸裙子的基礎技法，示範多款裙子的立裁概念，例如將褶子轉移延伸到裙子變化上，並結合上衣的褶子展開技法，以及利用平轉款式、低腰展波浪及橫條活褶等三種示範，讓學員瞭解市場上常見的裙子設計與應用。</p>
									<div className="ovs">
										<h4>課程明細</h4>
										<div className="ds">
											<table border="0" cellSpacing="0" cellPadding="0">
											<tbody>
												<tr>
													<td style={{width:12+"%"}}>第1節</td>
													<td style={{width:30+"%"}}>平轉示範</td>
													<td style={{width:52+"%"}}>裙子如何做平轉褶子轉移</td>
													<td style={{width:6+"%"}}>00:10:00</td>
												</tr>
												<tr>
													<td>第2節</td>
													<td>低腰展波浪示範</td>
													<td>老師示範低腰A字裙褶子轉移方法</td>
													<td>00:15:00</td>
												</tr>
												<tr>
													<td>第3節</td>
													<td>橫條活褶示範</td>
													<td>老師利用切展方式示範橫條布紋裙如何平行</td>
													<td>00:20:00</td>
												</tr>												
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="swipban">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm20.jpg").default} alt="" />
										</div>
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm200.jpg").default} alt=""/>
										</div>										
									</div>
									<div className="spot"></div>
								</div>
							</li>
							<li className="wow fadeInUp" data-wow-delay="0.9s" data-wow-duration="1s" id="di7">
								<div className="txt">
									<h2>第6章 立裁馬甲原型</h2>
									<p>本堂課將市面的馬甲上衣，分解成平織胚布的立裁馬甲，依分片的分解分段講解說明，讓學員練習操作並完成一件完整的馬甲原型。本課程完成後，學員們就能與裙子的課程合併技法，替自己做一件版型完美的小禮服了</p>
									<div className="ovs">
										<h4>課程明細</h4>
										<div className="ds">
											<table border="0" cellSpacing="0" cellPadding="0">
											<tbody>
												<tr>
													<td style={{width:12+"%"}}>第1節</td>
													<td style={{width:30+"%"}}>設計馬甲</td>
													<td style={{width:52+"%"}}>先在人台上用標示線貼上馬甲的設計線</td>
													<td style={{width:6+"%"}}>00:10:00</td>
												</tr>
												<tr>
													<td>第2節</td>
													<td>上胚布</td>
													<td>將整燙好的胚布一片一片上到人台身上</td>
													<td>00:25:00</td>
												</tr>
												<tr>
													<td>第3節</td>
													<td>展示馬甲</td>
													<td>展示成品</td>
													<td>00:02:00</td>
												</tr>												
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="swipban">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm21.jpg").default} alt=""/>
										</div>
										<div className="swiper-slide">
											<img src={require("assets/FashionDraping/images/qm211.jpg").default} alt="" />
										</div>										
									</div>
									<div className="spot"></div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default hPage4;
class VideoPriceEntity {
    
    constructor(ProductType, ProductSKU, unitPrice) {
        this.PK = (ProductSKU ? ((ProductSKU.length > 0) ? ("VP#"+ProductSKU):""):"");

        if (unitPrice !== undefined && unitPrice !== null) {
            this.unitPrice = Math.floor(unitPrice);
            if (isNaN(this.unitPrice)) {
                this.unitPrice = 0;
            }
        }

        this.SK = "unitPrice#"+this.unitPrice;
        this.Type = "VP_Price";
        this.ProductType = ProductType ? ProductType:"";
        this.ProductSKU = ProductSKU ? ProductSKU:"";
        
        

        // this.cType = "NTD";
        // this.viewDays = 0;
        // this.timeStamp = "";
        // this.limitFlag = false;
    }

    changeProductTypeSKU(ProductType, ProductSKU) {
        this.PK = (ProductSKU ? ((ProductSKU.length > 0) ? ("VP#"+ProductSKU):""):"");
        this.ProductType = ProductType ? ProductType:"";
        this.ProductSKU = ProductSKU ? ProductSKU:"";
    }

    toObject() {

        let unitPrice;
        if (this.unitPrice !== undefined && this.unitPrice !== null) {
            unitPrice = Math.floor(this.unitPrice);
            if (isNaN(unitPrice)) {
                unitPrice = 0;
            }
        }

        let viewDays;
        if (this.viewDays !== undefined && this.viewDays !== null) {
            viewDays = Math.floor(this.viewDays);
            if (isNaN(viewDays)) {
                viewDays = 0;
            }
        }

        let obj = {
            "PK": this.PK,
            "SK": this.SK,
            "Type": "VP_Price",
            "ProductType": this.ProductType,
            "ProductSKU": this.ProductSKU,
            "unitPrice": unitPrice,
            "viewDays": viewDays
        };
        if (this.cType) {
            obj["cType"] = this.cType;
        }
        if (this.timeStamp) {
            obj["timeStamp"] = this.timeStamp;
        }
        if (this.limitFlag !== undefined && this.limitFlag !== null) {
            if (this.limitFlag) {
                obj["limitFlag"] = true;
            } else {
                obj["limitFlag"] = false;
            }
        }
        return obj;
    }
}

const videoPriceEntityFromObject = (item) => {
    let entity = new VideoPriceEntity("", "", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    entity.Type = item.Type;
    entity.ProductType = item.ProductType;
    entity.ProductSKU = item.ProductSKU;

    if (item.unitPrice !== undefined && item.unitPrice !== null) {
        entity.unitPrice = Math.floor(item.unitPrice);
        if (isNaN(entity.unitPrice)) {
            entity.unitPrice = 0;
        }
    }

    if (item.viewDays !== undefined && item.viewDays !== null) {
        entity.viewDays = Math.floor(item.viewDays);
        if (isNaN(entity.viewDays)) {
            entity.viewDays = 0;
        }
    }

    if (item.cType) {
        entity.cType = item.cType;
    }
    if (item.timeStamp) {
        entity.timeStamp = item.timeStamp;
    }
    if (item.limitFlag !== undefined && item.limitFlag !== null) {
        if (item.limitFlag) {
            entity.limitFlag = true;
        } else {
            entity.limitFlag = false;
        }
    }
    return entity;
}

export { VideoPriceEntity, videoPriceEntityFromObject };

// {
//     "cType": "NTD",
//     "limitFlag": false,
//     "PK": "VP#coreytu01",
//     "SK": "unitPrice#100",
//     "timeStamp": "20210915",
//     "Type": "VP_Price",
//     "unitPrice": 100,
//     "ProductType": "VPID",
//     "ProductSKU": "coreytu01",
//     "viewDays": 1
//   }
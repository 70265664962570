import React from "react";

function VPList(props) {
    return (
        <>
            <div className="hpage2" style={{padding:`52px 0`}}>
                <div className="wrap">
                    <div className="tit wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1s">
                        <h2><span>精細教學解析</span>專業技法讓您學到會</h2>
                        <p>頂尖打版工藝 立裁大師親自授課</p>
                    </div>
                </div>
            </div>
            <div className="hpage1" style={{padding:0}}>
                <div className="ft wow fadeInUp ftt" data-wow-delay="0.7s" data-wow-duration="1s" style={{marginTop:`0`}}>
                    <h4>立裁洋裝課程系列</h4>
                    <ul className="clearfix">
                        <li><a href="#di1">
                            <h5><i>第0章</i>人臺標示教學</h5>
                            <h6>43分鐘</h6>
                        </a></li>
                        <li><a href="#di2">
                            <h5><i>第1章</i>立裁概念與無袖上衣原型</h5>
                            <h6>63分鐘</h6>
                        </a></li>
                        <li><a href="#di3">
                            <h5><i>第2章</i>直裙原型</h5>
                            <h6>41分鐘</h6>
                        </a></li>
                        <li><a href="#di4">
                            <h5><i>第3章</i>褶子轉移概念與實操</h5>
                            <h6>33分鐘</h6>
                        </a></li>
                        <li><a href="#di5">
                            <h5><i>第4章</i>褶子轉移&無領無袖版型如何一次到位</h5>
                            <h6>64分鐘</h6>
                        </a></li>
                        <li><a href="#di6">
                            <h5><i>第5章</i>裙子變化概念</h5>
                            <h6>54分鐘</h6>
                        </a></li>
                        <li><a href="#di7">
                            <h5><i>第6章</i>立裁馬甲</h5>
                            <h6>40分鐘</h6>
                        </a></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default VPList;

class Configs {
    constructor() {
        this.apiRoot = "tapi.twkupay.com";
        this.Version = "1.0";
        this.MerchantId = "127270625000001";
        this.TerminalId = "06250001";
    }
}

const KuPayConfig = new Configs();
export default KuPayConfig;
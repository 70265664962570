import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

// {
//     "PK": "Producers",
//     "SK": "P#coreytu",
//     "TYPE": "P_Main",
//     "ProducerID": "coreytu"
//   }

async function putProducerInfo(producerInfoEntity) {
    
    let result;
    let oldEntity = producerInfoEntity;
    if (producerInfoEntity) {

        producerInfoEntity.PK = "Producers";
        producerInfoEntity.TYPE = "P_Main";

        if (producerInfoEntity.SK) {
            if (producerInfoEntity.SK.length > 0) {
                // do nothing
            } else {
                if (producerInfoEntity.ProducerID){
                    if (producerInfoEntity.ProducerID.length > 0) {
                        producerInfoEntity.SK = "P#"+producerInfoEntity.ProducerID
                    }
                }
            }
        } else {
            if (producerInfoEntity.ProducerID){
                if (producerInfoEntity.ProducerID.length > 0) {
                    producerInfoEntity.SK = "P#"+producerInfoEntity.ProducerID
                }
            }
        }


        if (producerInfoEntity.PK && producerInfoEntity.SK && producerInfoEntity.ProducerID) {
            if (producerInfoEntity.PK.length > 0 && producerInfoEntity.SK.length > 0 && producerInfoEntity.ProducerID.length > 0) {
                
                result = await putItem(producerInfoEntity);
            }
        }
    }
    if (result) {
        return producerInfoEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putProducerInfo;
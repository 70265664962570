import { updateItem } from "./DynamodbMethods.js";

async function updateVideoPAP(videoPAPEntity) {
    
    let result;

    if (videoPAPEntity) {

        videoPAPEntity.PK = "VPAP";

        if (videoPAPEntity.SK) {
            if (videoPAPEntity.SK.length > 0) {
                // do nothing
            } else {
                if (videoPAPEntity.ProductType && videoPAPEntity.ProductSKU && videoPAPEntity.submitDateTime){
                    if (videoPAPEntity.ProductType.length > 0 && videoPAPEntity.ProductSKU.length > 0 && videoPAPEntity.submitDateTime.length > 0) {
                        videoPAPEntity.SK = videoPAPEntity.ProductType+"#"+videoPAPEntity.ProductSKU+"-"+videoPAPEntity.submitDateTime;
                    }
                }
            }
        } else {
            if (videoPAPEntity.ProductType && videoPAPEntity.ProductSKU && videoPAPEntity.submitDateTime){
                if (videoPAPEntity.ProductType.length > 0 && videoPAPEntity.ProductSKU.length > 0 && videoPAPEntity.submitDateTime.length > 0) {
                    videoPAPEntity.SK = videoPAPEntity.ProductType+"#"+videoPAPEntity.ProductSKU+"-"+videoPAPEntity.submitDateTime;
                }
            }
        }

        if (videoPAPEntity.PK && videoPAPEntity.SK) {
            if (videoPAPEntity.PK.length > 0 && videoPAPEntity.SK.length > 0) {
                result = await updateItem(videoPAPEntity);
            }
        }
    }
    return result;
}
  
export default updateVideoPAP;
import { queryItemsDesc } from "./DynamodbMethods.js";

async function getPhotosByProducerID(ProducerIDString) {
    let res;
    if (ProducerIDString) {
        if (ProducerIDString.length > 0) {
            res = await queryItemsDesc("Photos", ProducerIDString+"-");
        }
    }
    return res;
}
  
export default getPhotosByProducerID;
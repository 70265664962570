import kvnConfig from "../views/KeyValueNameConfig.js";
import { docClientQuery } from "./AWSConfig.js";
import { dateToUTCString, dateToLocalString, utcStringToDate, localStringToDate } from "./DatetimeFormatter.js";

async function getPayments(filterOnlyPaid, ProductType, startDate, endDate, ProducerID) {

    let paramsQuery = {
        TableName : kvnConfig.dbMainTableName,
        ExpressionAttributeNames: {
            "#keyAlias1": "PK",
            "#keyAlias2": "SK"
        },
        ExpressionAttributeValues: {
            ":valueAlias1": "Pay",
            ":valueAlias2": "Pay#"
        },
        KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)"
        ,ScanIndexForward: false // 預設true表示升序
    };

    if ((ProductType ? ProductType:"").length > 0) {
        if (filterOnlyPaid) {
            const startUtcString = dateToUTCString(startDate, "yyyyMMddHHmmss");
            if (startUtcString) {
                const endUtcString = (endDate, "yyyyMMddHHmmss");
                if (endUtcString) {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias4": true,
                                ":valueAlias5": startUtcString,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias0 = :valueAlias0 and #keyAlias3 = :valueAlias3 and #keyAlias4 = :valueAlias4 and #keyAlias5 >= :valueAlias5 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias4": true,
                                ":valueAlias5": startUtcString,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias4 = :valueAlias4 and #keyAlias5 >= :valueAlias5 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                } else {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias4": true,
                                ":valueAlias5": startUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias3 = :valueAlias3 and #keyAlias4 = :valueAlias4 and #keyAlias5 >= :valueAlias5"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias4": true,
                                ":valueAlias5": startUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias4 = :valueAlias4 and #keyAlias5 >= :valueAlias5"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                }
            } else {
                const endUtcString = dateToUTCString(endDate, "yyyyMMddHHmmss");
                if (endUtcString) {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias4": true,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias3 = :valueAlias3 and #keyAlias4 = :valueAlias4 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias4": true,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias4 = :valueAlias4 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                } else {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias4": "PayResult"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias4": true
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias3 = :valueAlias3 and #keyAlias4 = :valueAlias4"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias4": "PayResult"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias4": true
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias4 = :valueAlias4"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                }
            }
        } else {
            const startUtcString = dateToUTCString(startDate, "yyyyMMddHHmmss");
            if (startUtcString) {
                const endUtcString = dateToUTCString(endDate, "yyyyMMddHHmmss");
                if (endUtcString) {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias5": startUtcString,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias3 = :valueAlias3 and #keyAlias5 >= :valueAlias5 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias5": startUtcString,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias5 >= :valueAlias5 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                } else {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias5": startUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias3 = :valueAlias3 and #keyAlias5 >= :valueAlias5"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias5": startUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias5 >= :valueAlias5"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                }
            } else {
                const endUtcString = dateToUTCString(endDate, "yyyyMMddHHmmss");
                if (endUtcString) {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias3 = :valueAlias3 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                } else {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0 and #keyAlias3 = :valueAlias3"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias0": "ProductType",
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias0": ProductType,
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#"
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias0 = :valueAlias0"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                }
            }
        }
    } else {
        if (filterOnlyPaid) {
            const startUtcString = dateToUTCString(startDate, "yyyyMMddHHmmss");
            if (startUtcString) {
                const endUtcString = dateToUTCString(endDate, "yyyyMMddHHmmss");
                if (endUtcString) {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias4": true,
                                ":valueAlias5": startUtcString,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias3 = :valueAlias3 and #keyAlias4 = :valueAlias4 and #keyAlias5 >= :valueAlias5 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias4": true,
                                ":valueAlias5": startUtcString,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias4 = :valueAlias4 and #keyAlias5 >= :valueAlias5 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                } else {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias4": true,
                                ":valueAlias5": startUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias3 = :valueAlias3 and #keyAlias4 = :valueAlias4 and #keyAlias5 >= :valueAlias5"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias4": true,
                                ":valueAlias5": startUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias4 = :valueAlias4 and #keyAlias5 >= :valueAlias5"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                }
            } else {
                const endUtcString = dateToUTCString(endDate, "yyyyMMddHHmmss");
                if (endUtcString) {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias4": true,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias3 = :valueAlias3 and #keyAlias4 = :valueAlias4 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias4": "PayResult",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias4": true,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias4 = :valueAlias4 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                } else {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias4": "PayResult"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias4": true
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias3 = :valueAlias3 and #keyAlias4 = :valueAlias4"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias4": "PayResult"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias4": true
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias4 = :valueAlias4"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                }
            }
        } else {
            const startUtcString = dateToUTCString(startDate, "yyyyMMddHHmmss");
            if (startUtcString) {
                const endUtcString = dateToUTCString(endDate, "yyyyMMddHHmmss");
                if (endUtcString) {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias5": startUtcString,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias3 = :valueAlias3 and #keyAlias5 >= :valueAlias5 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias5": startUtcString,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias5 >= :valueAlias5 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                } else {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias5": startUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias3 = :valueAlias3 and #keyAlias5 >= :valueAlias5"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias5": startUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias5 >= :valueAlias5"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                }
            } else {
                const endUtcString = dateToUTCString(endDate, "yyyyMMddHHmmss");
                if (endUtcString) {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID,
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias3 = :valueAlias3 and #keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias5": "PayTimestamp"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias6": endUtcString
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias5 <= :valueAlias6"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                } else {
                    if ((ProducerID ? ProducerID:"").length > 0) {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK",
                                "#keyAlias3": "ProducerID"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#",
                                ":valueAlias3": ProducerID
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                            FilterExpression: "#keyAlias3 = :valueAlias3"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    } else {
                        paramsQuery = {
                            TableName : kvnConfig.dbMainTableName,
                            ExpressionAttributeNames: {
                                "#keyAlias1": "PK",
                                "#keyAlias2": "SK"
                            },
                            ExpressionAttributeValues: {
                                ":valueAlias1": "Pay",
                                ":valueAlias2": "Pay#"
                            },
                            KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)"
                            ,ScanIndexForward: false // 預設true表示升序
                        };
                    }
                }
            }
        }

    }


    const res = await docClientQuery(paramsQuery);
    
    return res;
}
  
export default getPayments;
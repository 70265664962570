import { queryItems } from "./DynamodbMethods.js";

async function getVideoFilesByPid(ProducerIDString) {
    let res;
    if (ProducerIDString) {
        if (ProducerIDString.length > 0) {
            res = await queryItems("VP#"+ProducerIDString, "VFile#");
        }
    }
    return res;
}
  
export default getVideoFilesByPid;
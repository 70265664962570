import { deleteItem } from "./DynamodbMethods.js";

async function deleteVideoPriceByVidAndPrice(ProductSKUString, priceInt) {
    let deleteRes;
    if (priceInt && ProductSKUString) {
        if (priceInt >= 0 && ProductSKUString.length > 0) {
            deleteRes = await deleteItem("VP#"+ProductSKUString, "unitPrice#"+priceInt);
        }
    }
    return deleteRes;
}
  
export default deleteVideoPriceByVidAndPrice;
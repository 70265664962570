import { getItem } from "./DynamodbMethods.js";

async function getVideoInfoByPidAndPtypeAndPsku(ProducerIDString, ProductTypeString, ProductSKUString) {
    let res;
    if (ProducerIDString && ProductTypeString && ProductSKUString) {
        if (ProducerIDString.length > 0 && ProductTypeString.length > 0 && ProductSKUString.length > 0) {
            res = await getItem("VP#"+ProducerIDString, ProductTypeString+"#"+ProductSKUString);
        }
    }
    return res;
}
  
export default getVideoInfoByPidAndPtypeAndPsku;
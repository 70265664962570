import { getItem } from "./DynamodbMethods.js";

async function getProducerInfoByProducerID(ProducerIDString) {
    let res;
    if (ProducerIDString) {
        if (ProducerIDString.length > 0) {
            res = await getItem("Producers", "P#"+ProducerIDString);
        }
    }
    return res;
}
  
export default getProducerInfoByProducerID;
import React, { useEffect, useMemo, useRef, useState, useValue } from 'react'
// reactstrap components
import {
    Input,
    Form,
  FormGroup,
} from "reactstrap";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import DataMethods from "../../data/DataMethods"
import { putMemberProfile, putFashionProfile, queryMember, queryLineMember } from '../../data/LineMethods'
import "assets/css/register.css"

function Register(props) {
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [Fullname, setFullname] = useState("");
    const [NickName, setNickName] = useState("");
    const [emailInit, setEmail] = useState(undefined);
    const [Birthday, setBirthday] = useState("");
    const [checkBox, setCheckBox] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const [isLoadQuery,setQuery] = useState(false)
    const [isShow,setShow] = useState(false);
    const [identities,setIdentities] = useState("")
    const [Carrier,setCarrier] = useState("")
    const [Phone,setPhone] = useState("")
    const [sex,setSex] = useState("1")

    const filterStr = () =>{
        const str = '0123456789';
        let res = "";for(let i = 0; i < 4;i++){
            var n = parseInt(Math.random()* str.length)
            res +=str[n]
        }
        return res
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const info = props.info
        const LineId = info.signInUserSession.idToken.payload.identities[0].userId;
        const MemberId = DataMethods.dateToUTCString(new Date(), "yyyyMMddHHmmss");
        const Jwttoken = info.signInUserSession.idToken.jwtToken
        // const verify = verifyId(identities);
        // if(!verify){
        //     setLoading(false);
        //     setShow(true)
        //     return;
        // }
        const itemEntity = {
            PK: "Members",
            SK: `Line#${LineId}`,
            Type: `M_Main`,
            MemberID: `${MemberId + filterStr()}`,
            LineID: `${LineId}`,
            LineAccessToken: `${Jwttoken}`,
            Fullname: Fullname
        }

        const LineEntity2 = {
            PK: `Line#${LineId}`,
            SK: `Line#${LineId}`,
            Type: "Member_Basic",
            MemberID: `${itemEntity.MemberID}`,
            // LineAccessToken: `${Jwttoken}`,
            LineID: `${LineId}`,
            Fullname: Fullname,
            // Gender: sex,
            Phone: Phone,
            // Carrier:Carrier,
            // Id:identities.trim(),
            Akaname: `${NickName}`,
            Birthday:Birthday,
            Country:``,
            Email:`${emailInit}`,
            Language: "ZH-TW",
            Agreement: `Agreement`,
            AgreementDate: DataMethods.dateToUTCString(new Date(), "yyyyMMddHHmmss"),
            BucketName:"",
            PathAndKey: "",
            CreateAt: DataMethods.dateToUTCString(new Date(), "yyyyMMddHHmmss"),
            UpdateAt: DataMethods.dateToUTCString(new Date(), "yyyyMMddHHmmss"),
        }

        await putMemberProfile(itemEntity);
        await DataMethods.putItem_V2(LineEntity2);
        // await putFashionProfile(FashionEntity);
        window.location.href = "/fashion-draping/basics"
        setLoading(false);
    }

    function verifyId(id) {
        let verification,checkSum,c,w;
        id = id.trim();
        verification = id.match("^[A-Z][1289]\\d{8}$")
        if(!verification){
            return false
        }
    
        let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
        let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]
    
        id = String(conver.indexOf(id[0]) + 10) + id.slice(1);
    
        checkSum = 0
        for (let i = 0; i < id.length; i++) {
            c = parseInt(id[i])
            w = weights[i]
            checkSum += c * w
        }
        
        return checkSum % 10 === 0
    }
    
    const showAlert = () =>{
        if(isShow){
            return <div className="alert alert-danger" role="alert">
                身分字號格式錯誤
            </div>
        }
    }

    useEffect(() => {
        if(isShow){
            const timer = setTimeout(() =>{
                setShow(false);
                clearTimeout(timer)
            },3000)
            return;
        }
        if (isLoaded === false) {
            setTimeout(() => {
                setLoaded(true);
                return;
            }, 300);
        }
        if(isLoaded && !isLoadQuery ){
            if(props.info){
                const email = props.info.signInUserSession.idToken.payload.email
                setEmail(email)
                setQuery(true)
                const info = props.info
                const payLoad = info.signInUserSession.idToken.payload.identities[0].userId
                // if(!emailInit){
                    queryMember(payLoad).then((res) =>{
                    if(res){
                        queryLineMember(payLoad).then((res) =>{
                            if(res){
                                window.location.href = "/fashion-draping/basics/vp"
                            }
                        })
                    }else{
                        //    window.location.href = "/Register"
                    }
                })
            }else{
                window.location.href = "/fashion-draping/basics/"
            }
            // }
        }
        if (isLoaded && props.nickname) {
            setNickName(props.nickname) 
        }
    }, [isLoaded, props.info,isLoadQuery,isShow]);

    return (
        <>
            {showAlert()}
            <header className="register">
                <div className='container'>

                    <div className="left">
                        <h3><span>建立您的</span> <img src={require("assets/FashionDraping/images/videoStore.png").default} alt="" style={{ height: `100%`, minWidth: `117px` }} />帳戶</h3>
                        <div className="g-form">
                            <form onSubmit={onSubmit}>
                                <div className='d-flex sp'>
                                    <Input
                                        defaultValue={Fullname}
                                        onChange={(e) => { setFullname(e.target.value) }}
                                        className='input'
                                        placeholder="姓名"
                                        type="text"
                                        onFocus={() => setFirstFocus(true)}
                                        onBlur={() => setFirstFocus(false)}
                                        required
                                    ></Input>
                                    <Input
                                        defaultValue={NickName}
                                        onChange={(e) => { setNickName(e.target.value) }}
                                        className='input'
                                        placeholder="暱稱"
                                        name="nickname"
                                        type="text"
                                        onFocus={() => setFirstFocus(true)}
                                        onBlur={() => setFirstFocus(false)}
                                        required
                                    ></Input>
                                    </div>                             
                                    {/* <div className='d-flex sp'>
                                    <FormGroup style={{width:`49%`}}>
                                        <Input  defaultValue={sex}  onChange={(e) => { 
                                                setSex(e.target.value)
                                            }}
                                            style={{borderRadius:`5px`,border:`1px solid #b2bec3`}}
                                            type="select" placeholder="性別">
                                            <option value="0">女</option>
                                            <option value="1">男</option>
                                        </Input>
                                     </FormGroup>   */}
                                    {/* <Input
                                        defaultValue={identities}
                                        onChange={(e) => { 
                                            setIdentities(e.target.value)
                                        }}
                                        className='input'
                                        placeholder="身分證字號"
                                        name="身分證字號"
                                        type="text"
                                        onFocus={() => setFirstFocus(true)}
                                        onBlur={() => setFirstFocus(false)}
                                        required
                                    ></Input> */}
                                   
                                    {/* <div onChange={onChangeValue}>
                                        <input type="radio" value="0" name="gender" /> 女
                                        <input type="radio" value="1" name="gender" /> 男
                                    </div> */}
                            
                                {/* </div> */}
                                <div className='d-flex sp'>
                                    <Input
                                        style={{width:`100%`}}
                                        defaultValue={Phone}
                                        onChange={(e) => { 
                                            setPhone(e.target.value)
                                        }}
                                        className='input'
                                        placeholder="手機號碼"
                                        name="手機號碼"
                                        type="number"
                                        onFocus={() => setFirstFocus(true)}
                                        onBlur={() => setFirstFocus(false)}
                                        required
                                    ></Input>
                                    {/* <Input
                                    defaultValue={Carrier}
                                    onChange={(e) => { 
                                        setCarrier(e.target.value)
                                    }}
                                    className='input'
                                    placeholder="載具號碼"
                                    name="載具號碼"
                                    type="text"
                                    onFocus={() => setFirstFocus(true)}
                                    onBlur={() => setFirstFocus(false)}
                                    required
                                ></Input> */}
                                </div>
                                <Input
                                    defaultValue={emailInit}
                                    onChange={(e) => { 
                                        setEmail(e.target.value)
                                     }}
                                    className='input'
                                    placeholder="Email"
                                    name="Email"
                                    type="email"
                                    onFocus={() => setFirstFocus(true)}
                                    onBlur={() => setFirstFocus(false)}
                                    required
                                ></Input>
                                <ReactDatetime
                                    defaultValue={Birthday}
                                    onChange={(e) => {
                                        setBirthday(e._d)
                                    }
                                    }
                                    inputProps={{
                                        className: "input",
                                        placeholder: "Birthday",
                                    }}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                ></ReactDatetime>
                               
                                <div className="span">
                                    <a href="/privacy/policy#info-share" target="_blank">VideoStores 翔偉資安科技(股)有限公司</a>可以透過<a href="/privacy/policy#mktg-email
" target="_blank">個人化</a>電子郵件告知我產品與服務的相關訊息。 請參閱我們的<a href="/privacy/policy" target="_blank">隱私權政策</a>以了解詳情或隨時退出。
                                </div>
                                <div style={{ marginTop: `4px`, marginBottom: `4px` }}>
                                    <input type="checkbox" name="" className="checkbox" required></input>
                                    <span> 請透過 Line 和 電子郵件與我聯絡。</span>
                                </div>
                                <div className="span">
                                    如果按一下「建立帳戶」，即表示我已閱讀並接受<a href="/legal/terms" target="_blank">使用條款</a>和<a href="/privacy/policy" target="_blank">隱私權政策</a>。
                                </div>
                                <div className='submitDiv'>

                                    <input
                                        className='input'
                                        placeholder="生日"
                                        value="建立帳戶"
                                        type="submit"
                                        disabled={loading}
                                        onFocus={() => setFirstFocus(true)}
                                        onBlur={() => setFirstFocus(false)}
                                    ></input>
                                </div>
                                {/* <input type="submit" id="next" value="完成"></input> */}

                            </form>

                        </div>
                    </div>
                    <div className="right"></div>
                </div>
            </header>
        </>)
}

export default Register
class ProducerProfileIDVEntity {
    
    constructor(ProducerID) {
        this.PK = ProducerID ? ("P#"+ProducerID):"";
        this.SK = ProducerID ? ("IDV#"+ProducerID):"";
        this.TYPE = "P_PrivacyIDV";
        // this.birthday = "";
        // this.email = "";
        // this.fullname = "";
        // this.gender = "";
        // this.phone = "";
        // this.SID = "";
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK
        };
        if (this.TYPE) {
            obj["TYPE"] = this.TYPE;
        }
        if (this.birthday) {
            obj["birthday"] = this.birthday;
        }
        if (this.email) {
            obj["email"] = this.email;
        }
        if (this.fullname) {
            obj["fullname"] = this.fullname;
        }
        if (this.gender) {
            obj["gender"] = this.gender;
        }
        if (this.phone) {
            obj["phone"] = this.phone;
        }
        if (this.SID) {
            obj["SID"] = this.SID;
        }
        return obj;
    }
}

const producerProfileIDVEntityFromObject = (item) => {
    let entity = new ProducerProfileIDVEntity("");
    entity.PK = item.PK;
    entity.SK = item.SK;
    if (item.TYPE) {
        entity.TYPE = item.TYPE;
    }
    if (item.birthday) {
        entity.birthday = item.birthday;
    }
    if (item.email) {
        entity.email = item.email;
    }
    if (item.fullname) {
        entity.fullname = item.fullname;
    }
    if (item.gender) {
        entity.gender = item.gender;
    }
    if (item.phone) {
        entity.phone = item.phone;
    }
    if (item.SID) {
        entity.SID = item.SID;
    }
    return entity;
}

export { ProducerProfileIDVEntity, producerProfileIDVEntityFromObject };

// {
//     "PK": "P#coreytu",
//     "SK": "IDV#coreytu",
//     "TYPE": "P_PrivacyIDV",
//     "birthday": "1978/8/12",
//     "email": "coreytu@gmail.com",
//     "fullname": "杜世鵬",
//     "gender": "M",
//     "phone": "+886935161290",
//     "SID": "F124445678"
//   }
class KVNConfig {

  constructor() {
    
    // VideoStores
    this.dbMainTableName = "vsfront";
    this.adminAccessKeyId = "AKIASQIGVV33LN27DOBL";
    this.adminSecretAccessKey = "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";
    this.grantFullControlString = "id=9d592c72f85868755845cc8561a05d5703e09a8392a3fbc6151a6cdc35c2aadf";
    this.s3MainBucketName = "video-stores-1";

    // both same
    this.stateSend = "frontend"; // admin, frontend
    this.nonceSend = "";
    this.awsRegion = "ap-northeast-1";
    this.grantWriteString = "uri=http://acs.amazonaws.com/groups/global/AuthenticatedUsers"; // 已驗證的使用者群組 (擁有 AWS 帳戶的任何人)
    this.grantReadString = "uri=http://acs.amazonaws.com/groups/global/AllUsers";

    this.lineChannelID ="1657725044"
    this.lineChannelSecret = "5b7945d5c15090cedcda7d5cd0db8d95";

    this.lambda1177QueryOrder = "QueryOrderPayment"
    this.lambdaRegularPayment = "ProcessRegularPayment"
    this.lambdaPayment = "ProccessPayment"
    this.lambdaCreateOrder = "CreateOrder"
    this.merchantID ="121237"
    // this.urlVideoStoresM = "http://main.d30h3igj2fr2va.amplifyapp.com";
    // this.urlVideoStoresPP = "http://localhost:3001";
    // this.redirectUri = "http://main.d30h3igj2fr2va.amplifyapp.com/LoginRedirect";
    // // Line Login Callback URL http://localhost:3000/LoginRedirect


    // // // for test line
    // this.urlVideoStoresM = "http://localhost:3000";
    // this.urlVideoStoresPP = "http://localhost:3001";
    this.redirectUri = "https://www.videostores.io/fashion-draping/basics/vp";
    // // Line Login Callback URL http://localhost:3000/LoginRedirect
    
    
    // this.urlVideoStoresM = "https://www.videostores.io";
    // this.urlVideoStoresPP = "https://pp.videostores.io";
    // this.redirectUri = "https://www.videostores.io/LoginRedirect";
    // Line Login Callback URL https://www.videostores.io/LoginRedirect


  }
}

const KeyValueNameConfig = new KVNConfig();

export default KeyValueNameConfig;
import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

// {
// 	PK: "Pay",
// 	SK: "Pay#PayNumber",
// 	Type: "Payment",
// 	PayNumber: "MemberID20211231095959",
// 	PayResult: false,
// 	PayMethod: "ATM", // CC/ATM/ISP
// 	PayID: "PayID", // 三方交易號碼 或是 點數扣款編碼(未來)
// 	PayTimestamp: "20211231095959",
// 	GatewayName: "黑潮支付",
// 	PayAmount: 0,
// 	PayFee: "0",
// 	PayMemo: "",
// 	OrderNumber: "",
// 	MemberID: "",
// 	ProducerID: ""
// }

async function putPayment(paymentEntity) {
    
    let result;
    let oldEntity = paymentEntity;
    if (paymentEntity) {

        paymentEntity.PK = "Pay";
        paymentEntity.Type = "Payment";

        if (paymentEntity.SK) {
            if (paymentEntity.SK.length > 0) {
                // do nothing
            } else {
                if (paymentEntity.PayNumber){
                    if (paymentEntity.PayNumber.length > 0) {
                        paymentEntity.SK = "Pay#"+paymentEntity.PayNumber
                    }
                }
            }
        } else {
            if (paymentEntity.PayNumber){
                if (paymentEntity.PayNumber.length > 0) {
                    paymentEntity.SK = "Pay#"+paymentEntity.PayNumber
                }
            }
        }

        if (paymentEntity.PK && paymentEntity.SK && paymentEntity.PayNumber) {
            if (paymentEntity.PK.length > 0 && paymentEntity.SK.length > 0 && paymentEntity.PayNumber.length > 0) {
                
                result = await putItem(paymentEntity);
            }
        }
    }
    if (result) {
        return paymentEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putPayment;
import { updateItem } from "./DynamodbMethods.js";

async function updateProducerProfileCOMP(producerProfileCOMPEntity) {
    
    let result;

    if (producerProfileCOMPEntity) {

        producerProfileCOMPEntity.TYPE = "P_PrivacyCOMP";

        if (producerProfileCOMPEntity.PK && producerProfileCOMPEntity.SK) {
            if (producerProfileCOMPEntity.PK.length > 0 && producerProfileCOMPEntity.SK.length > 0) {
                result = await updateItem(producerProfileCOMPEntity);
            }
        }
    }
    return result;
}
  
export default updateProducerProfileCOMP;
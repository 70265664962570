import { getItem } from "./DynamodbMethods.js";

async function getOrderByOrderNumber(OrderNumber) {
    let res;
    if (OrderNumber) {
        if (OrderNumber.length > 0) {
            res = await getItem("Orders", "O#"+OrderNumber);
        }
    }
    return res;
}
  
export default getOrderByOrderNumber;
import { queryItemsDesc } from "./DynamodbMethods.js";

async function getFilmsByProducerID(ProducerIDString) {
    let res;
    if (ProducerIDString) {
        if (ProducerIDString.length > 0) {
            res = await queryItemsDesc("Films", ProducerIDString+"-");
        }
    }
    return res;
}
  
export default getFilmsByProducerID;
import { Button } from "reactstrap";
import React, { useEffect, useState } from 'react'
import { paymentRequest, createOrder} from "../../data/1177pay"
import { Auth } from "aws-amplify";
import { queryMember, queryLineMember, queryFashionMember} from "data/LineMethods";
import { dateToLocalString} from "data/DatetimeFormatter.js";
import DataMethods from "data/DataMethods";
// import KeyValueNameConfig from "views/KeyValueNameConfig";
function HPage1(props) {
    const [isShow,setShow] = useState(false);
    const [isPay,setPay] = useState(false);
    const [isInitPlayer,setInitPlayer] = useState(false)
    const [MemberProfile, setMemberProfile] = useState(undefined);
    const [LineProfile, setLineProfile] = useState(undefined);
    const [FashionProfile,setFashionProfile] = useState(undefined);
    const [chapterList,setChapterList] = useState([]);
    const [selectedChapterAll,setSelectedChapterAll] = useState({})
    const [isLoadChapterFinish ,setIsLoadChapterFinish] = useState(false);
    const loadSession = async () => {
        try {
          const currentSession = await Auth.currentSession();
          const sessionToken = {
            idToken: currentSession.getIdToken().getJwtToken(),
            refreshToken: currentSession.getRefreshToken().getToken(),
            accessToken: currentSession.getAccessToken().getJwtToken(),
          };
          return sessionToken;
        } catch {
          return null;
        }
      };

    const getChapterInfo = async () => {
        const sessionToken = await loadSession();
        if (sessionToken) {
          const myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Authorization", sessionToken.accessToken);
          const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };
          const res = await fetch(
            "https://nnfeel0fu3.execute-api.ap-northeast-1.amazonaws.com/dev/products?pid=FDDB-ALL",
            requestOptions
          );
          const data = await res.json();
          setChapterList(data)
          findChapterInfoALL()
          setIsLoadChapterFinish(true)
        }
      }

      const findChapterInfoALL = async () =>{
        const data = chapterList.find(x => x.P_ID === "FDDB-ALL")
        if(data){
          setSelectedChapterAll(data)
          
        }
      }


    const getMemberProfile = async (payload) => {
        await queryMember(payload).then(async (res) => {
            if (res) {
                setMemberProfile(res);
                await getLineProfile(payload)
            }
        });
    };

    const getLineProfile = async (payload) => {
        await queryLineMember(payload).then(async (res) => {
            if (res) {
                setLineProfile(res);
                await getFashionProfile(payload)
            } 

        });
    };

    const getFashionProfile = async (payload) => {
        await queryFashionMember(payload).then((res) => {
            if (res) {
                setFashionProfile(res);
                if(res.ExpireDate){
                    const ExpireDate = res.ExpireDate;
                    const newDate = new Date();
                    const localDate = DataMethods.utcStringToDate(ExpireDate, "yyyyMMddHHmmss")
                    if(localDate > newDate){
                        setPay(true);
                    }              
                }
            }
        });
    };
    
    const showAlert = () =>{
        if(isShow){
            return <div className="alert alert-danger" role="alert">
                交易支付出現異常！
            </div>
        }
    }
    const showLink = () =>{
        if(isPay){
            return <Button type="" className="nav-link" onClick={() => window.location.href ="/fashion-draping/basics/vp"}><span>前往課程</span></Button>
        }else{ 
            return <a href="#hpage9" className="btn"><span>立即購買</span></a>
            // <Button type="" className="nav-link" onClick={() => payOpen()}><span>立即購買</span></Button>
        }
    }

    useEffect(() => {
        // youtubePlayerInit();
        getChapterInfo();
        // if (props.info) {
        //     const info = props.info;
        //     if (info && info.signInUserSession) {
        //         const payLoad = info.signInUserSession.idToken.payload.identities[0].userId
        //         getMemberProfile(payLoad);
        //     } 
        // } 
        // if(isShow){
        //     const timer = setTimeout(() =>{
        //         clearTimeout(timer)
        //     },3000)
        // }
    }, [props,isShow,isPay,isLoadChapterFinish]);

    return (
        <>
            <div className="hpage1">
                <div className="wrap">
                    <h1 className="wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">課程系列-洋裝篇</h1>
                    <h1 className="sj">服裝立裁打版<span>課程系列-洋裝篇</span></h1>
                    <div className="bd clearfix wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="1s">
                        <div className="vide">     
                           <div id="fashionhpage1" style={{paddingTop:`66%`,position:`relative`}}>
                                <iframe    title="1" 
                                    style={{border:`0`,maxWidth:`100%`,position:`absolute`,top:`0`,left:`0`,height:`100%`,width:`100%`}}
                                    allowFullScreen
                                    allow="encrypted-media"
                                    src="https://www.youtube.com/embed/xswrHqMLXuQ" 
                                ></iframe>
                            </div>
                        </div>
                        <div className="txt">
                            <h3>服裝立裁打版課程系列</h3>
                            <h2><span>洋裝篇</span></h2>
                            <p>主講老師：何佩珊</p>
                            <h6>課程總章：7章<span></span>總時數：337分鐘</h6>
                            <div className="fm">
                                <h4>NT$14,000</h4>
                                <h4 className="on">早鳥價 NT${selectedChapterAll ? selectedChapterAll.P_Price : null}</h4>
                            </div>
                            {showLink()}
                        </div>
                    </div>
                    <div className="ft wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1s">
                        <h4>立裁洋裝課程系列</h4>
                        <ul className="clearfix">
                            <li><a href="#di1">
                                <h5><i>第0章</i>人臺標示教學</h5>
                                <h6>43分鐘</h6>
                            </a></li>
                            <li><a href="#di2">
                                <h5><i>第1章</i>立裁概念與無袖上衣原型</h5>
                                <h6>63分鐘</h6>
                            </a></li>
                            <li><a href="#di3">
                                <h5><i>第2章</i>直裙原型</h5>
                                <h6>41分鐘</h6>
                            </a></li>
                            <li><a href="#di4">
                                <h5><i>第3章</i>褶子轉移概念與實操</h5>
                                <h6>33分鐘</h6>
                            </a></li>
                            <li><a href="#di5">
                                <h5><i>第4章</i>褶子轉移&無領無袖版型如何一次到位</h5>
                                <h6>64分鐘</h6>
                            </a></li>
                            <li><a href="#di6">
                                <h5><i>第5章</i>裙子變化概念</h5>
                                <h6>54分鐘</h6>
                            </a></li>
                            <li><a href="#di7">
                                <h5><i>第6章</i>立裁馬甲</h5>
                                <h6>40分鐘</h6>
                            </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HPage1;
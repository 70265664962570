import { FileTempEntity, fileTempEntityFromObject } from "./FileTempEntity.js";
import { FilmEntity, filmEntityFromObject } from "./FilmEntity.js";
import { PhotoEntity, photoEntityFromObject } from "./PhotoEntity.js";
import { ProducerInfoEntity, producerInfoEntityFromObject } from "./ProducerInfoEntity.js";
import { ProducerProfileCOMPEntity, producerProfileCOMPEntityFromObject } from "./ProducerProfileCOMPEntity.js";
import { ProducerProfileIDVEntity, producerProfileIDVEntityFromObject } from "./ProducerProfileIDVEntity.js";
import { VideoFileEntity, videoFileEntityFromObject } from "./VideoFileEntity.js";
import { VideoFileChapterEntity, videoFileChapterEntityFromObject } from "./VideoFileChapterEntity.js";
import { VideoFileSectionEntity, videoFileSectionEntityFromObject } from "./VideoFileSectionEntity";
import { VideoFileUnitEntity, videoFileUnitEntityFromObject } from "./VideoFileUnitEntity.js";
import { VideoFileSubEntity, videoFileSubEntityFromObject } from "./VideoFileSubEntity.js";
import { VideoPAPEntity, videoPAPEntityFromObject } from "./VideoPAPEntity.js";
import { VideoPAPResultEntity, videoPAPResultEntityFromObject } from "./VideoPAPResultEntity.js";
import { VideoInfoEntity, videoInfoEntityFromObject } from "./VideoInfoEntity.js";
import { VideoPriceEntity, videoPriceEntityFromObject } from "./VideoPriceEntity.js";
import { VideoProductionEntity, videoProductionEntityFromObject } from "./VideoProductionEntity.js";
import { VideoProfileEntity, videoProfileEntityFromObject } from "./VideoProfileEntity.js";

import { MemberProfileEntity, memberProfileEntityFromObject } from "./MemberProfileEntity.js";
import { MemberVideoExpireEntity, memberVideoExpireEntityFromObject } from "./MemberVideoExpireEntity.js";

import { OrderEntity, orderEntityFromObject } from "./OrderEntity.js";
import { PaymentEntity, paymentEntityFromObject } from "./PaymentEntity.js";
import { InvoiceEntity, invoiceEntityFromObject } from "./InvoiceEntity.js";

// export {
//     MemberProfileEntity, memberProfileEntityFromObject,
//     MemberVideoExpireEntity, memberVideoExpireEntityFromObject,
//     FileTempEntity, fileTempEntityFromObject, FilmEntity, filmEntityFromObject,
//     PhotoEntity, photoEntityFromObject,
//     ProducerInfoEntity, producerInfoEntityFromObject,
//     ProducerProfileCOMPEntity, producerProfileCOMPEntityFromObject,
//     ProducerProfileIDVEntity, producerProfileIDVEntityFromObject,
//     VideoFileEntity, videoFileEntityFromObject,
//     VideoFileChapterEntity, videoFileChapterEntityFromObject,
//     VideoFileSectionEntity, videoFileSectionEntityFromObject,
//     VideoFileUnitEntity, videoFileUnitEntityFromObject,
//     VideoFileSubEntity, videoFileSubEntityFromObject,
//     VideoPAPEntity, videoPAPEntityFromObject,
//     VideoPAPResultEntity, videoPAPResultEntityFromObject,
//     VideoInfoEntity, videoInfoEntityFromObject,
//     VideoPriceEntity, videoPriceEntityFromObject,
//     VideoProductionEntity, videoProductionEntityFromObject,
//     VideoProfileEntity, videoProfileEntityFromObject,
//     OrderEntity, orderEntityFromObject,
//     PaymentEntity, paymentEntityFromObject,
//     InvoiceEntity, invoiceEntityFromObject
// };

class EntityTools {
    constructor() {
        this.MemberProfileEntity = MemberProfileEntity;
        this.memberProfileEntityFromObject = memberProfileEntityFromObject;

        this.MemberVideoExpireEntity = MemberVideoExpireEntity;
        this.memberVideoExpireEntityFromObject = memberVideoExpireEntityFromObject;

        this.FileTempEntity = FileTempEntity;
        this.fileTempEntityFromObject = fileTempEntityFromObject;

        this.FilmEntity = FilmEntity;
        this.filmEntityFromObject = filmEntityFromObject;

        this.PhotoEntity = PhotoEntity;
        this.photoEntityFromObject = photoEntityFromObject;

        this.ProducerInfoEntity = ProducerInfoEntity;
        this.producerInfoEntityFromObject = producerInfoEntityFromObject;

        this.ProducerProfileCOMPEntity = ProducerProfileCOMPEntity;
        this.producerProfileCOMPEntityFromObject = producerProfileCOMPEntityFromObject;
        
        this.ProducerProfileIDVEntity = ProducerProfileIDVEntity;
        this.producerProfileIDVEntityFromObject = producerProfileIDVEntityFromObject;
        
        this.VideoFileEntity = VideoFileEntity;
        this.videoFileEntityFromObject = videoFileEntityFromObject;
        
        this.VideoFileChapterEntity = VideoFileChapterEntity;
        this.videoFileChapterEntityFromObject = videoFileChapterEntityFromObject;

        this.VideoFileSectionEntity = VideoFileSectionEntity;
        this.videoFileSectionEntityFromObject = videoFileSectionEntityFromObject;

        this.VideoFileUnitEntity = VideoFileUnitEntity;
        this.videoFileUnitEntityFromObject = videoFileUnitEntityFromObject;

        this.VideoFileSubEntity = VideoFileSubEntity;
        this.videoFileSubEntityFromObject = videoFileSubEntityFromObject;
        
        this.VideoPAPEntity = VideoPAPEntity;
        this.videoPAPEntityFromObject = videoPAPEntityFromObject;

        this.VideoPAPResultEntity = VideoPAPResultEntity;
        this.videoPAPResultEntityFromObject = videoPAPResultEntityFromObject;

        this.VideoInfoEntity = VideoInfoEntity;
        this.videoInfoEntityFromObject = videoInfoEntityFromObject;

        this.VideoPriceEntity = VideoPriceEntity;
        this.videoPriceEntityFromObject = videoPriceEntityFromObject;

        this.VideoProductionEntity = VideoProductionEntity;
        this.videoProductionEntityFromObject = videoProductionEntityFromObject;

        this.VideoProfileEntity = VideoProfileEntity;
        this.videoProfileEntityFromObject = videoProfileEntityFromObject;

        this.OrderEntity = OrderEntity;
        this.orderEntityFromObject = orderEntityFromObject;

        this.PaymentEntity = PaymentEntity;
        this.paymentEntityFromObject = paymentEntityFromObject;

        this.InvoiceEntity = InvoiceEntity;
        this.invoiceEntityFromObject = invoiceEntityFromObject;
        
    }
}

const DataEntity = new EntityTools();

export default DataEntity;
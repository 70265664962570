class MemberProfileEntity {
    
    constructor(MemberID) {
        this.PK = "Members";
        this.SK = MemberID ? "M#"+MemberID:"";
        this.Type = "M_Main";
        this.MemberID = MemberID;
        // this.LineID = "";
        // this.LineAccessToken = "";
        // this.BucketName = "";
        // this.PathAndKey = "";
        // this.agreement = "";
        // this.akaname = "";
        // this.birthday = "";
        // this.country = "";
        // this.createAt = "";
        // this.email = "";
        // this.fullname = "";
        // this.gender = "";
        // this.language = "";
        // this.phone = "";
        // this.updateAt = "";
    }

    toObject() {
        let obj = {
            "PK": "Members",
            "SK": this.SK,
            "Type": "M_Main"
        };
        if (this.MemberID) {
            obj["MemberID"] = this.MemberID;
        }
        if (this.LineID) {
            obj["LineID"] = this.LineID;
        }
        if (this.LineAccessToken) {
            obj["LineAccessToken"] = this.LineAccessToken;
        }
        if (this.BucketName) {
            obj["BucketName"] = this.BucketName;
        }
        if (this.PathAndKey) {
            obj["PathAndKey"] = this.PathAndKey;
        }
        if (this.agreement) {
            obj["agreement"] = this.agreement;
        }
        if (this.akaname) {
            obj["akaname"] = this.akaname;
        }
        if (this.birthday) {
            obj["birthday"] = this.birthday;
        }
        if (this.country) {
            obj["country"] = this.country;
        }
        if (this.createAt) {
            obj["createAt"] = this.createAt;
        }
        if (this.email) {
            obj["email"] = this.email;
        }
        if (this.fullname) {
            obj["fullname"] = this.fullname;
        }
        if (this.gender) {
            obj["gender"] = this.gender;
        }
        if (this.language) {
            obj["language"] = this.language;
        }
        if (this.phone) {
            obj["phone"] = this.phone;
        }
        if (this.updateAt) {
            obj["updateAt"] = this.updateAt;
        }
        return obj;
    }
}

const memberProfileEntityFromObject = (item) => {
    let entity = new MemberProfileEntity("");
    entity.PK = "Members";
    entity.SK = item.SK;
    entity.Type = "M_Main";
    if (item.MemberID) {
        entity.MemberID = item.MemberID;
    }
    if (item.LineID) {
        entity.LineID = item.LineID;
    }
    if (item.LineAccessToken) {
        entity.LineAccessToken = item.LineAccessToken;
    }
    if (item.BucketName) {
        entity.BucketName = item.BucketName;
    }
    if (item.PathAndKey) {
        entity.PathAndKey = item.PathAndKey;
    }
    if (item.agreement) {
        entity.agreement = item.agreement;
    }
    if (item.akaname) {
        entity.akaname = item.akaname;
    }
    if (item.birthday) {
        entity.birthday = item.birthday;
    }
    if (item.country) {
        entity.country = item.country;
    }
    if (item.createAt) {
        entity.createAt = item.createAt;
    }
    if (item.email) {
        entity.email = item.email;
    }
    if (item.fullname) {
        entity.fullname = item.fullname;
    }
    if (item.gender) {
        entity.gender = item.gender;
    }
    if (item.language) {
        entity.language = item.language;
    }
    if (item.phone) {
        entity.phone = item.phone;
    }
    if (item.updateAt) {
        entity.updateAt = item.updateAt;
    }
    return entity;
}

export { MemberProfileEntity, memberProfileEntityFromObject };

// {
//     "PK": "Members",
//     "SK": "M#coreytu",
//     "Type": "M_Main",
//     "MemberID": "coreytu",
//     "LineID": "",
//     "LineAccessToken": "",
//     "agreement": "HASH Strings",
//     "akaname": "Corey----",
//     "birthday": "",
//     "BucketName": "video-stores-1",
//     "country": "Taiwan",
//     "createAt": "",
//     "email": "coreytu@gmail.com",
//     "fullname": "杜世鵬",
//     "gender": "M",
//     "language": "ZH-TW",
//     "PathAndKey": "Members/coreytu-logo-1633401571296.jpg",
//     "phone": "+886935161290",
//     "updateAt": 1633401573835
//   }
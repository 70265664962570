function dateToUTCString(date, format) {
  if (date instanceof Date) {
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();
    let hour = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    let zone = date.getTimezoneOffset();
    min = min + zone;
    while(min >= 60) {
      min = min - 60;
      hour = hour + 1;
    }
    while(hour >= 24) {
      hour = hour - 24;
      day = day + 1;
    }
    while(min < 0) {
      min = min + 60;
      hour = hour - 1;
    }
    while(hour < 0) {
      hour = hour + 24;
      day = day - 1;
    }
    const tempTime = (new Date(year+"-"+month+"-"+day)).setDate(day);
    const tempDate = new Date(tempTime);
    year = tempDate.getFullYear();
    month = tempDate.getMonth()+1;
    day = tempDate.getDate();

    let resultString = "";
    if (format) {
      if (format.length > 0) {
        resultString = format;
        
        let index = resultString.indexOf("yyyy");
        while(index >= 0) {
            resultString = resultString.replace("yyyy", ""+year);
            index = resultString.indexOf("yyyy");
        }
        
        index = resultString.indexOf("MM");
        while(index >= 0) {
            resultString = resultString.replace("MM", (""+month).padStart(2,"0"));
            index = resultString.indexOf("MM");
        }
        
        index = resultString.indexOf("M");
        while(index >= 0) {
            resultString = resultString.replace("M", ""+month);
            index = resultString.indexOf("M");
        }
        
        index = resultString.indexOf("dd");
        while(index >= 0) {
            resultString = resultString.replace("dd", (""+day).padStart(2,"0"));
            index = resultString.indexOf("dd");
        }
        
        index = resultString.indexOf("d");
        while(index >= 0) {
            resultString = resultString.replace("d", ""+day);
            index = resultString.indexOf("d");
        }
        
        index = resultString.indexOf("HH");
        while(index >= 0) {
            resultString = resultString.replace("HH", (""+hour).padStart(2,"0"));
            index = resultString.indexOf("HH");
        }
        
        index = resultString.indexOf("H");
        while(index >= 0) {
            resultString = resultString.replace("H", ""+hour);
            index = resultString.indexOf("H");
        }
        
        index = resultString.indexOf("mm");
        while(index >= 0) {
            resultString = resultString.replace("mm", (""+min).padStart(2,"0"));
            index = resultString.indexOf("mm");
        }
        
        index = resultString.indexOf("m");
        while(index >= 0) {
            resultString = resultString.replace("m", ""+min);
            index = resultString.indexOf("m");
        }
        
        index = resultString.indexOf("ss");
        while(index >= 0) {
            resultString = resultString.replace("ss", (""+sec).padStart(2,"0"));
            index = resultString.indexOf("ss");
        }
        
        index = resultString.indexOf("s");
        while(index >= 0) {
            resultString = resultString.replace("s", ""+sec);
            index = resultString.indexOf("s");
        }
        
      } else {
        const resDate = new Date(year, month-1, day, hour, min, sec);
        resultString = resDate.toISOString();
      }
    } else {
      const resDate = new Date(year, month-1, day, hour, min, sec);
      resultString = resDate.toISOString();
    }
    return resultString;
  } else {
    return null;
  }
}

function dateToLocalString(date, format) {
  if (date instanceof Date) {
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();
    let hour = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    while(min >= 60) {
      min = min - 60;
      hour = hour + 1;
    }
    while(hour >= 24) {
      hour = hour - 24;
      day = day + 1;
    }
    while(min < 0) {
      min = min + 60;
      hour = hour - 1;
    }
    while(hour < 0) {
      hour = hour + 24;
      day = day - 1;
    }
    const tempTime = (new Date(year+"-"+month+"-"+day)).setDate(day);
    const tempDate = new Date(tempTime);
    year = tempDate.getFullYear();
    month = tempDate.getMonth()+1;
    day = tempDate.getDate();
  
    let resultString = "";
    if (format) {
      if (format.length > 0) {
        resultString = format;
        
        let index = resultString.indexOf("yyyy");
        while(index >= 0) {
            resultString = resultString.replace("yyyy", ""+year);
            index = resultString.indexOf("yyyy");
        }
        
        index = resultString.indexOf("MM");
        while(index >= 0) {
            resultString = resultString.replace("MM", (""+month).padStart(2,"0"));
            index = resultString.indexOf("MM");
        }
        
        index = resultString.indexOf("M");
        while(index >= 0) {
            resultString = resultString.replace("M", ""+month);
            index = resultString.indexOf("M");
        }
        
        index = resultString.indexOf("dd");
        while(index >= 0) {
            resultString = resultString.replace("dd", (""+day).padStart(2,"0"));
            index = resultString.indexOf("dd");
        }
        
        index = resultString.indexOf("d");
        while(index >= 0) {
            resultString = resultString.replace("d", ""+day);
            index = resultString.indexOf("d");
        }
        
        index = resultString.indexOf("HH");
        while(index >= 0) {
            resultString = resultString.replace("HH", (""+hour).padStart(2,"0"));
            index = resultString.indexOf("HH");
        }
        
        index = resultString.indexOf("H");
        while(index >= 0) {
            resultString = resultString.replace("H", ""+hour);
            index = resultString.indexOf("H");
        }
        
        index = resultString.indexOf("mm");
        while(index >= 0) {
            resultString = resultString.replace("mm", (""+min).padStart(2,"0"));
            index = resultString.indexOf("mm");
        }
        
        index = resultString.indexOf("m");
        while(index >= 0) {
            resultString = resultString.replace("m", ""+min);
            index = resultString.indexOf("m");
        }
        
        index = resultString.indexOf("ss");
        while(index >= 0) {
            resultString = resultString.replace("ss", (""+sec).padStart(2,"0"));
            index = resultString.indexOf("ss");
        }
        
        index = resultString.indexOf("s");
        while(index >= 0) {
            resultString = resultString.replace("s", ""+sec);
            index = resultString.indexOf("s");
        }
        
      } else {
        const resDate = new Date(year, month-1, day, hour, min, sec);
        resultString = resDate.toISOString();
      }
    } else {
      const resDate = new Date(year, month-1, day, hour, min, sec);
      resultString = resDate.toISOString();
    }
    return resultString;
  } else {
    return null;
  }
}

function utcStringToDate(utcString, format) {
  if (utcString) {
    if (format) {
      if (format.length > 0) {
        if (format.length === utcString.length) {
          let yearStr = "";
          const yearIndex = format.indexOf("yyyy");
          if (yearIndex >= 0) {
            yearStr = utcString.substr(yearIndex, 4);
          }
          let monthStr = "";
          let monthIndex = format.indexOf("MM");
          if (monthIndex >= 0) {
            monthStr = utcString.substr(monthIndex, 2);
          } else {
            monthIndex = format.indexOf("M");
            if (monthIndex >= 0) {
              monthStr = utcString.substr(monthIndex, 1);
            }
          }
          let dayStr = "";
          let dayIndex = format.indexOf("dd");
          if (dayIndex >= 0) {
            dayStr = utcString.substr(dayIndex, 2);
          } else {
            dayIndex = format.indexOf("d");
            if (dayIndex >= 0) {
              dayStr = utcString.substr(dayIndex, 1);
            }
          }
          let hourStr = "";
          let hourIndex = format.indexOf("HH");
          if (hourIndex >= 0) {
            hourStr = utcString.substr(hourIndex, 2);
          } else {
            hourIndex = format.indexOf("H");
            if (hourIndex >= 0) {
              hourStr = utcString.substr(hourIndex, 1);
            }
          }
          let minStr = "";
          let minIndex = format.indexOf("mm");
          if (minIndex >= 0) {
            minStr = utcString.substr(minIndex, 2);
          } else {
            minIndex = format.indexOf("m");
            if (minIndex >= 0) {
              minStr = utcString.substr(minIndex, 1);
            }
          }
          let secStr = "";
          let secIndex = format.indexOf("ss");
          if (secIndex >= 0) {
            secStr = utcString.substr(secIndex, 2);
          } else {
            secIndex = format.indexOf("s");
            if (secIndex >= 0) {
              secStr = utcString.substr(secIndex, 1);
            }
          }
          const zoneOffsetMin = (new Date()).getTimezoneOffset();
          let resultDate = new Date();
          if (yearStr.length > 0) {
            const year = Math.floor(yearStr);
            if (!isNaN(year)) {
              resultDate.setFullYear(year);
            }
          }
          if (monthStr.length > 0) {
            const month = Math.floor(monthStr);
            if (!isNaN(month)) {
              resultDate.setMonth(month-1);
            }
          }
          if (dayStr.length > 0) {
            const day = Math.floor(dayStr);
            if (!isNaN(day)) {
              resultDate.setDate(day);
            }
          }
          if (hourStr.length > 0) {
            const hour = Math.floor(hourStr);
            if (!isNaN(hour)) {
              resultDate.setHours(hour);
            }
          }
          if (minStr.length > 0) {
            const min = Math.floor(minStr);
            if (!isNaN(min)) {
              resultDate.setMinutes(min);
            }
          }
          if (secStr.length > 0) {
            const sec = Math.floor(secStr);
            if (!isNaN(sec)) {
              resultDate.setSeconds(sec);
            }
          }
          resultDate = new Date(resultDate.getTime() - (zoneOffsetMin * 60 * 1000));
          return resultDate;
        } else {
          const zoneOffsetMin = (new Date()).getTimezoneOffset;
          const resultDatetime = new Date(utcString).getTime();
          const resultDate = new Date(resultDatetime - (zoneOffsetMin * 60 * 1000));
          return resultDate;
        }
      } else {
        const zoneOffsetMin = (new Date()).getTimezoneOffset;
        const resultDatetime = new Date(utcString).getTime();
        const resultDate = new Date(resultDatetime - (zoneOffsetMin * 60 * 1000));
        return resultDate;
      }
    } else {
      const zoneOffsetMin = (new Date()).getTimezoneOffset;
      const resultDatetime = new Date(utcString).getTime();
      const resultDate = new Date(resultDatetime - (zoneOffsetMin * 60 * 1000));
      return resultDate;
    }
  } else {
    return null;
  }
}

function localStringToDate(utcString, format) {
  if (utcString) {
    if (format) {
      if (format.length > 0) {
        if (format.length === utcString.length) {
          let yearStr = "";
          const yearIndex = format.indexOf("yyyy");
          if (yearIndex > 0) {
            yearStr = utcString.substr(yearIndex, 4);
          }
          let monthStr = "";
          let monthIndex = format.indexOf("MM");
          if (monthIndex > 0) {
            monthStr = utcString.substr(monthIndex, 2);
          } else {
            monthIndex = format.indexOf("M");
            if (monthIndex > 0) {
              monthStr = utcString.substr(monthIndex, 1);
            }
          }
          let dayStr = "";
          let dayIndex = format.indexOf("dd");
          if (dayIndex > 0) {
            dayStr = utcString.substr(dayIndex, 2);
          } else {
            dayIndex = format.indexOf("d");
            if (dayIndex > 0) {
              dayStr = utcString.substr(dayIndex, 1);
            }
          }
          let hourStr = "";
          let hourIndex = format.indexOf("HH");
          if (hourIndex > 0) {
            hourStr = utcString.substr(hourIndex, 2);
          } else {
            hourIndex = format.indexOf("H");
            if (hourIndex > 0) {
              hourStr = utcString.substr(hourIndex, 1);
            }
          }
          let minStr = "";
          let minIndex = format.indexOf("mm");
          if (minIndex > 0) {
            minStr = utcString.substr(minIndex, 2);
          } else {
            minIndex = format.indexOf("m");
            if (minIndex > 0) {
              minStr = utcString.substr(minIndex, 1);
            }
          }
          let secStr = "";
          let secIndex = format.indexOf("ss");
          if (secIndex > 0) {
            secStr = utcString.substr(secIndex, 2);
          } else {
            secIndex = format.indexOf("s");
            if (secIndex > 0) {
              secStr = utcString.substr(secIndex, 1);
            }
          }
          let resultDate = new Date();
          if (yearStr.length > 0) {
            const year = Math.floor(yearStr);
            if (!isNaN(year)) {
              resultDate.setFullYear(yearStr);
            }
          }
          if (monthStr.length > 0) {
            const month = Math.floor(monthStr);
            if (!isNaN(month)) {
              resultDate.setMonth(month-1);
            }
          }
          if (dayStr.length > 0) {
            const day = Math.floor(dayStr);
            if (!isNaN(day)) {
              resultDate.setDate(day);
            }
          }
          if (hourStr.length > 0) {
            const hour = Math.floor(hourStr);
            if (!isNaN(hour)) {
              resultDate.setHours(hour);
            }
          }
          if (minStr.length > 0) {
            const min = Math.floor(minStr);
            if (!isNaN(min)) {
              resultDate.setMinutes(min);
            }
          }
          if (secStr.length > 0) {
            const sec = Math.floor(secStr);
            if (!isNaN(sec)) {
              resultDate.setSeconds(sec);
            }
          }
          return resultDate;
        } else {
          const resultDatetime = new Date(utcString).getTime();
          const resultDate = new Date(resultDatetime);
          return resultDate;
        }
      } else {
        const resultDatetime = new Date(utcString).getTime();
        const resultDate = new Date(resultDatetime);
        return resultDate;
      }
    } else {
      const resultDatetime = new Date(utcString).getTime();
      const resultDate = new Date(resultDatetime);
      return resultDate;
    }
  } else {
    return null;
  }
}

export { dateToUTCString, dateToLocalString, utcStringToDate, localStringToDate };
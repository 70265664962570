import { deleteItem } from "./DynamodbMethods.js";

async function deleteVideoFileByPkAndVid(PK, ProductSKUString) {
    let deleteRes;
    if (PK && ProductSKUString) {
        if (PK.length > 0 && ProductSKUString.length > 0) {
            deleteRes = await deleteItem(PK, "VFile#"+ProductSKUString);
        }
    }
    return deleteRes;
}

export default deleteVideoFileByPkAndVid;
import { lambdaInvoke } from "./../data/AWSConfig";
import DataMethods from "../data/DataMethods"
import KVNConfig from "../views/KeyValueNameConfig";



async function paymentRequest(jsonData){
    const params = {
        FunctionName: KVNConfig.lambdaPayment,
        Payload:JSON.stringify(jsonData)
    };
    const data = await lambdaInvoke(params);
    if (data && data.Payload) {
        const jsonData = JSON.parse(data.Payload);
        if (jsonData && jsonData.statusCode === 200) {
            return {success:true,result:jsonData.response}
        }else{
            return {success:false,result:null}
        }
    }
    return {success:false,result:null}
} 

async function paymentRegularRequest(jsonData){
    const params = {
        FunctionName: KVNConfig.lambdaRegularPayment,
        Payload:JSON.stringify(jsonData)
    };
    const data = await lambdaInvoke(params);
    if (data && data.Payload) {
        const jsonData = JSON.parse(data.Payload);
        if (jsonData && jsonData.statusCode === 200) {
            return {success:true,result:jsonData.response}
        }else{
            return {success:false,result:null}
        }
    }
    return {success:false,result:null}
} 

async function createLambdaOrder(jsonData){
    const params = {
        FunctionName: KVNConfig.lambdaCreateOrder,
        Payload:JSON.stringify(jsonData)
    };
    const data = await lambdaInvoke(params);
    if (data && data.Payload) {
        const jsonData = JSON.parse(data.Payload);
        if (jsonData && jsonData.statusCode === 200) {
            return {success:true,result:jsonData.response}
        }else{
            return {success:false,result:null}
        }
    }
    return {success:false,result:null}
} 

async function createOrder(order){
    let result;
    let oldEntity = order;
    if (oldEntity) {
        if (order.PK && order.SK) {
            if (order.PK.length > 0 && order.SK.length > 0) {
                result = await DataMethods.putItem_V2(order);
            }
        }
    }
    if (result) {
        return order;
    } else {
        return oldEntity;
    }
}
async function createPayment(payment){
    let result;
    let oldEntity = payment;
    if (oldEntity) {
        if (payment.PK && payment.SK) {
            if (payment.PK.length > 0 && payment.SK.length > 0) {
                result = await DataMethods.putItem_V2(payment);
            }
        }
    }
    if (result) {
        return payment;
    } else {
        return oldEntity;
    }
}


async function payQuery(ordernumber,periods) {
    let Payload
    if(periods){
        Payload = JSON.stringify({
            ordernumber: ordernumber,
            periods:periods
        })
    }else{
        Payload= JSON.stringify({
            ordernumber : ordernumber
        })
    }
    const params = {
        FunctionName: KVNConfig.lambda1177QueryOrder,
        Payload: Payload
    };
    const data = await lambdaInvoke(params);
    return data;
}
export {payQuery,createOrder,createLambdaOrder,createPayment,paymentRequest,paymentRegularRequest}
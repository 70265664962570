class VideoProfileEntity {
    
    constructor(ProducerID, ProductSKU) {
        this.PK = (ProducerID ? ((ProducerID.length > 0) ? ("VP#"+ProducerID):""):"");
        this.SK = (ProductSKU ? ((ProductSKU.length > 0) ? ("VProfile#"+ProductSKU):""):"");
        this.ProductSKU = ProductSKU ? ProductSKU:"";
        this.Type = "ContentInfo";
        
        // this.Caption = "ZH-TW";
        // this.Grade = 0;
        // this.BRIEF = "";
        // this.Category = "";
        // this.language = "";
        // this.Metadata = "";
        // this.Summary = "";
        // this.CoverBucketName = "";
        // this.CoverPathAndKey = "";
        // this.BannerBucketName = "";
        // this.BannerPathAndKey = "";
        // this.ProductType = "";
        // this.VPName = "";
    }

    changeProductSKU(ProductSKU) {
        this.SK = (ProductSKU ? ((ProductSKU.length > 0) ? ("VProfile#"+ProductSKU):""):"");
        this.ProductSKU = ProductSKU ? ProductSKU:"";
    }
    
    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK,
            "ProductSKU": this.ProductSKU
        };
        if (this.ProductType) {
            obj["ProductType"] = this.ProductType;
        }
        if (this.Type) {
            obj["Type"] = this.Type;
        }
        if (this.Caption) {
            obj["Caption"] = this.Caption;
        }
        if (this.Grade !== undefined && this.Grade !== null) {
            obj["Grade"] = Math.floor(this.Grade);
            if (isNaN(obj["Grade"])) {
                obj["Grade"] = 0;
            }
        }
        if (this.BRIEF) {
            obj["BRIEF"] = this.BRIEF;
        }
        if (this.Category) {
            obj["Category"] = this.Category;
        }
        if (this.language) {
            obj["language"] = this.language;
        }
        if (this.Metadata) {
            obj["Metadata"] = this.Metadata;
        }
        if (this.Summary) {
            obj["Summary"] = this.Summary;
        }
        if (this.CoverBucketName) {
            obj["CoverBucketName"] = this.CoverBucketName;
        }
        if (this.CoverPathAndKey) {
            obj["CoverPathAndKey"] = this.CoverPathAndKey;
        }
        if (this.BannerBucketName) {
            obj["BannerBucketName"] = this.BannerBucketName;
        }
        if (this.BannerPathAndKey) {
            obj["BannerPathAndKey"] = this.BannerPathAndKey;
        }
        if (this.VPName) {
            obj["VPName"] = this.VPName;
        }
        return obj;
    }
}

const videoProfileEntityFromObject = (item) => {
    let entity = new VideoProfileEntity("", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    entity.ProductSKU = item.ProductSKU;
    if (item.ProductType) {
        entity.ProductType = item.ProductType;
    }
    if (item.Type) {
        entity.Type = item.Type;
    }
    if (item.Caption) {
        entity.Caption = item.Caption;
    }
    if (item.Grade !== undefined && item.Grade !== null) {
        entity.Grade = Math.floor(item.Grade);
        if (isNaN(entity.Grade)) {
            entity.Grade = 0;
        }
    }
    if (item.BRIEF) {
        entity.BRIEF = item.BRIEF;
    }
    if (item.Category) {
        entity.Category = item.Category;
    }
    if (item.language) {
        entity.language = item.language;
    }
    if (item.Metadata) {
        entity.Metadata = item.Metadata;
    }
    if (item.Summary) {
        entity.Summary = item.Summary;
    }
    if (item.CoverBucketName) {
        entity.CoverBucketName = item.CoverBucketName;
    }
    if (item.CoverPathAndKey) {
        entity.CoverPathAndKey = item.CoverPathAndKey;
    }
    if (item.BannerBucketName) {
        entity.BannerBucketName = item.BannerBucketName;
    }
    if (item.BannerPathAndKey) {
        entity.BannerPathAndKey = item.BannerPathAndKey;
    }
    if (item.VPName) {
        entity.VPName = item.VPName;
    }
    return entity;
}

export { VideoProfileEntity, videoProfileEntityFromObject };

// {
//     "BRIEF": "在蝴蝶屋結束脩業之後，炭治郎等人抵達了下一任務的地方無限列車。",
//     "Caption": "ZH-TW",
//     "Category": "動作，冒險，動畫",
//     "Grade": "12",
//     "language": "普通話",
//     "Metadata": "鬼滅之刃,瀰豆子",
//     "PK": "VP#coreytu",
//     "SK": "VProfile#coreytu01",
//     "Summary": "在蝴蝶屋結束脩業之後，炭治郎等人抵達了下一任務的地方無限列車。",
//     "Type": "ContentInfo",
//     "CoverBucketName": "kingvideoadmin5c1ac373cd004a24869147aafb0a82fd81745-staging",
//     "CoverPathAndKey": "VP/img/coreytu01-ImgMain-1631599702.jpeg",
//     "ProductType": "VPID",
//     "ProductSKU": "coreytu01",
//     "BannerBucketName": "kingvideoadmin5c1ac373cd004a24869147aafb0a82fd81745-staging",
//     "BannerPathAndKey": "VP/img/coreytu01-ImgBanner-1631599712.jpeg",
//     "VPName": "鬼滅之刃劇場版"
//   }
import { deleteItem } from "./DynamodbMethods.js";
import { s3DeleteFile } from "./S3Methods.js";
import getFilmByPkAndSk from "./getFilmByPkAndSk.js";

async function deleteFilmByPkAndSk(PkString, SkString) {
    let res;
    if (PkString && SkString) {
        if (PkString.length > 0 && SkString.length > 0) {
            res = await getFilmByPkAndSk(PkString, SkString);
            if (res) {
                if (res.BucketName && res.PathAndKey) {
                    if (res.BucketName.length > 0 && res.PathAndKey.length > 0) {
                        await s3DeleteFile(res.BucketName, res.PathAndKey);
                    }
                }
            }
            await deleteItem(PkString, SkString);
        }
    }
    return res;
}
  
export default deleteFilmByPkAndSk;
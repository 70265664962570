import { updateItem } from "./DynamodbMethods.js";

async function updateProducerInfo(producerInfoEntity) {
    
    let result;

    if (producerInfoEntity) {

        producerInfoEntity.PK = "Producers";
        producerInfoEntity.TYPE = "P_Main";

        if (producerInfoEntity.SK) {
            if (producerInfoEntity.SK.length > 0) {
                // do nothing
            } else {
                if (producerInfoEntity.ProducerID){
                    if (producerInfoEntity.ProducerID.length > 0) {
                        producerInfoEntity.SK = "P#"+producerInfoEntity.ProducerID
                    }
                }
            }
        } else {
            if (producerInfoEntity.ProducerID){
                if (producerInfoEntity.ProducerID.length > 0) {
                    producerInfoEntity.SK = "P#"+producerInfoEntity.ProducerID
                }
            }
        }

        if (producerInfoEntity.PK && producerInfoEntity.SK) {
            if (producerInfoEntity.PK.length > 0 && producerInfoEntity.SK.length > 0) {
                result = await updateItem(producerInfoEntity);
            }
        }
    }
    return result;
}
  
export default updateProducerInfo;
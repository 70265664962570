import { getItem } from "./DynamodbMethods.js";

async function getPhotoByPkAndSk(PkString, SkString) {
    let res;
    if (PkString && SkString) {
        if (PkString.length > 0 && SkString.length > 0) {
            res = await getItem(PkString, SkString);
        }
    }
    return res;
}
  
export default getPhotoByPkAndSk;
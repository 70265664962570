/*!

=========================================================
* Now UI Kit PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* src/App.js */
import TagManager from 'react-gtm-module'
import { Amplify, Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
// styles
import "assets/css/bootstrap.min.css";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/scss/now-ui-kit.scss?v=1.5.0";

// publish pages

// develop pages
import LegalSections from "views/Legal-Sections/index.js";
import PayResult from "views/PayResult";
import VideoStoreHeaders from "./components/Headers/VideoStoreHeaders";
import FashionDraping from "./views/Fashion-Sections/index.js";
import FashionVideo from "./views/Fashion-Video-Sections";
import Landing from "./views/Landing-Sections";
import LineRedirect from "./views/LineRedirect";
import PolicySections from "./views/Policy-Sections";
import Register from "./views/Register";
import { HelmetProvider } from 'react-helmet-async';
const helmetContext = {};
Amplify.configure({
  // ...awsExports,
  region: "ap-northeast-1",
  aws_user_pools_id: "ap-northeast-1_eABV1iWbZ",
  aws_user_pools_web_client_id: "7sqbnoq4bmbq11bepptd0c7vgi",
  oauth: {
    domain: "videostores.auth.ap-northeast-1.amazoncognito.com",
    scope: [
      "aws.cognito.signin.user.admin",
      "email",
      "openid",
      "phone",
      "profile",
    ],
    redirectSignIn: "https://www.videostores.io/Redirect",
    redirectSignOut: "https://www.videostores.io/fashion-draping/basics",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
});

const tagManagerArgs = {
  gtmId: 'GTM-P5CT47T'
}

TagManager.initialize(tagManagerArgs)

window.localStorage.setItem("hinetCDN", "off");

function LandingRoute(props) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
      pagePath: '/',
      pageTitle: 'LandingPage',
    },
  });
  return (
    <>
      <VideoStoreHeaders info={props.info} nickname={props.nickname} />
      <Landing />
    </>
  );
}

function RedirectRoute(props) {
  const [userInfo, setInfo] = useState(undefined);
  const [userName, setName] = useState("");
  const [isLoaded, setLoaded] = useState(false);

  const initUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setInfo(user);
        const nickname = user.signInUserSession.idToken.payload.nickname;
        setName(nickname);
      } else {
        setInfo(undefined);
        window.location.href = "/fashion-draping/basics/";
      }
    } catch (err) {
      if (
        navigator.userAgent.match(/Mobi/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i)
      ) {
        onClickHandler();
      } else {
        window.location.href = "/fashion-draping/basics/";
      }
    }
  };

  const onClickHandler = async () => {
    await Auth.federatedSignIn({
      customProvider: "Line",
      autoSignIn: {
        enabled: true,
      },
    })
      .then((cred) => {
        return Auth.currentAuthenticatedUser();
      })
      .then(async (user) => {
        setInfo(user);
        const nickname = user.signInUserSession.idToken.payload.nickname;
        setName(nickname);
      })
      .catch((e) => {
        // window.location.href = "/fashion-draping/basics/"
      });
  };
  useEffect(() => {
    if (isLoaded === false) {
      setTimeout(() => {
        setLoaded(true);
        return;
      }, 1000);
    }
    if (isLoaded) {
      initUser();
    }
  }, [isLoaded]);
  return (
    <>
      <LineRedirect info={userInfo} nickname={userName} />
    </>
  );
}

function FashionRoute(props) {
  const [userInfo, setInfo] = useState(undefined);
  const [userName, setName] = useState("");
  const [isLoaded, setLoaded] = useState(false);
  let match = useRouteMatch();
  
  const headers = () => {
    return (
      <VideoStoreHeaders
        info={userInfo}
        nickname={userName}
      ></VideoStoreHeaders>
    );
  };

  useEffect(() => {
    if (isLoaded === false) {
      setTimeout(() => {
        setLoaded(true);
        return;
      }, 300);
    }
    if (isLoaded && props.info) {
      const info = props.info;
      setInfo(info);
      if (info && info.signInUserSession) {
        setName(info.signInUserSession.idToken.payload.nickname);
        
      }
    }
    if (props.nickname) {
      const nickname = props.nickname;
      setName(nickname);
    }
  }, [isLoaded, props.info]);

  return (
    <>
      {/* The Topics page has its own <Switch> with more routes
          that build on the /topics URL path. You can think of the
          2nd <Route> here as an "index" page for all topics, or
          the page that is shown when no topic is selected */}
      <Switch>
        <Route path={`${match.path}/vp`}>
          {headers()}
          <FashionVideo
            info={props.info}
            nickname={props.nickname}
          ></FashionVideo>
        </Route>
        <Route path={match.path}>
          {headers()}
          <FashionDraping {...props} />
        </Route>
      </Switch>
    </>
  );
}

function PolicyRoute(props) {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.path}/policy`}>
          <PolicySections />
        </Route>
      </Switch>
    </>
  );
}

function LegalRoute(props) {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.path}/terms`}>
          <LegalSections />
        </Route>
      </Switch>
    </>
  );
}

function WebRoute(props) {
  const [userInfo, setUserInfo] = useState(undefined);
  const [userNickName, setNickName] = useState(undefined);
  const initUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setUserInfo(user);
        const nickname = user.signInUserSession.idToken.payload.nickname;
        setNickName(nickname);
      } else {
        setUserInfo(undefined);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    initUser();
    Hub.listen(/.*/, (data) => {
      if (data.channel === "auth") {
        switch (data.payload.event) {
          case "signIn":
            initUser();
            const nickname =
              data.payload.data.signInUserSession.idToken.payload.nickname;
            setNickName(nickname);
            break;
          case "signOut":
            setUserInfo(undefined);
            setNickName(undefined);
            window.location.reload();
            break;
          default:
            break;
          //
        }
      }
    });
  }, []);

  return (
    <>
      <Switch>
        <Route
          path="/fashion-draping/basics"
          render={(props) => {
            return (
              <>
                <FashionRoute
                  props={props}
                  info={userInfo}
                  nickname={userNickName}
                />
              </>
            );
          }}
        />
        <Route
          path="/Redirect"
          render={(props) => {
            return (
              <RedirectRoute
                props={props}
                info={userInfo}
                nickname={userNickName}
              />
            );
          }}
        />
        <Route
          path="/Register"
          render={(props) => {
            return (
              <>
                <Register info={userInfo} nickname={userNickName} />
              </>
            );
          }}
        />
        <Route
          path="/privacy"
          render={() => {
            return (
              <>
                <PolicyRoute />
              </>
            );
          }}
        />
        <Route
          path="/legal"
          render={() => {
            return (
              <>
                <LegalRoute />
              </>
            );
          }}
        />
        <Route
          path="/1177payResult"
          render={(props) => {
            return (
              <PayResult
                props={props}
                info={userInfo}
                nickname={userNickName}
              />
            );
          }}
        />
        <Route
          path="/"
          render={(props) => {
            return (
              <>
                <LandingRoute info={userInfo} nickname={userNickName} />
              </>
            );
          }}
        />

        <Redirect to="/" />
        {/* <Redirect from="/" to="/fashion-draping/basics" /> */}
        {/* <Redirect
        from="/"
        to={{
          pathname: '/Producers',
          state: { lineUserId: lineUserId }
        }}
      /> */}

        {/* <Redirect from="/VP" to="/Producers" />
      <Redirect from="/VP/:videoType" to="/Producers" /> */}
      </Switch>
    </>
  );
}

ReactDOM.render(
  <HelmetProvider context={helmetContext}>
  <BrowserRouter>
    <Switch>
      <WebRoute />
    </Switch>
  </BrowserRouter>
  </HelmetProvider>,

  document.getElementById("root")
);

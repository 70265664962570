import { getItem } from "./DynamodbMethods.js";

async function getMemberProfileByMid(MemberIDString) {
    let res;
    if (MemberIDString) {
        if (MemberIDString.length > 0) {
            res = await getItem("Members", "M#"+MemberIDString);
        }
    }
    return res;
}

export default getMemberProfileByMid;
import { getLastItem } from "./DynamodbMethods.js";

async function getVideoPAPByPtypeAndPsku(ProductTypeString, ProductSKUString) {
    let res;
    if (ProductTypeString && ProductSKUString) {
        if (ProductTypeString.length > 0 && ProductSKUString.length > 0) {
            res = await getLastItem("VPAP", ProductTypeString+"#"+ProductSKUString);
        }
    }
    return res;
}
  
export default getVideoPAPByPtypeAndPsku;
import getKuPaySha256HashKey from "./getKuPaySha256HashKey.js";
import KuPayConfig from "./KuPayConfig.js";
import { lambdaInvoke } from "./../data/AWSConfig.js";

async function kuPayCancel(OrderNo) {

  let result;
  const res1 = await getKuPaySha256HashKey();
  if (res1) {
    if (res1.HashKey && res1.HashIV) {

      const params = {
          FunctionName: 'KuPayCancel',
          Payload: JSON.stringify({
            HashIV: res1.HashIV,
            HashKey: res1.HashKey,
            MerchantId: KuPayConfig.MerchantId,
            OrderNo: OrderNo
          })
      };

      const data = await lambdaInvoke(params);
      if (data) {
        if (data.Payload) {
          const jsonData = JSON.parse(data.Payload);
          if (jsonData) {
            const jsonSec = JSON.parse(jsonData);
            if (jsonSec) {
              // res
              const code = jsonSec.ResultCode; // 000000:交易成功, 200000:商店訂單編號重複
              const message = jsonSec.Message;
              result = jsonSec;
            } else {
              const code = jsonData.ResultCode; // 000000:交易成功, 200000:商店訂單編號重複
              const message = jsonData.Message;
              result = jsonData;
            }
          }
        }
      }
    }
  }

  return result;
}

export default kuPayCancel;
class OrderEntity {
    
    constructor(orderNumber) {
        this.PK = "Orders";
        this.SK = orderNumber ? "O#"+orderNumber:"";
        this.Type = "Orders";
        this.OrderNumber = orderNumber;
        this.OrderStatusFlag = false;
        this.PayResult = false;
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK
        };
        if (this.Type) {
            obj["Type"] = this.Type;
        }
        if (this.OrderNumber) {
            obj["OrderNumber"] = this.OrderNumber;
        }
        if (this.OrderTimestamp) {
            obj["OrderTimestamp"] = this.OrderTimestamp;
        }
        if (this.MemberID) {
            obj["MemberID"] = this.MemberID;
        }
        if (this.ProductType) {
            obj["ProductType"] = this.ProductType;
        }
        if (this.ProductSKU) {
            obj["ProductSKU"] = this.ProductSKU;
        }
        if (this.ProducerID) {
            obj["ProducerID"] = this.ProducerID;
        }
        if (this.VPName) {
            obj["VPName"] = this.VPName;
        }
        if (this.unitPrice !== undefined && this.unitPrice !== null) {
            obj["unitPrice"] = Math.floor(this.unitPrice);
            if (isNaN(obj["unitPrice"])) {
                obj["unitPrice"] = 0;
            }
        }
        if (this.Currency) {
            obj["Currency"] = this.Currency;
        }
        if (this.viewDays !== undefined && this.viewDays !== null) {
            obj["viewDays"] = Math.floor(this.viewDays);
            if (isNaN(obj["viewDays"])) {
                obj["viewDays"] = 0;
            }
        }
        if (this.discountList) {
            obj["discountList"] = this.discountList;
        }
        if (this.discountTotal !== undefined && this.discountTotal !== null) {
            obj["discountTotal"] = Math.floor(this.discountTotal);
            if (isNaN(obj["discountTotal"])) {
                obj["discountTotal"] = 0;
            }
        }
        if (this.TotalPrice !== undefined && this.TotalPrice !== null) {
            obj["TotalPrice"] = Math.floor(this.TotalPrice);
            if (isNaN(obj["TotalPrice"])) {
                obj["TotalPrice"] = 0;
            }
        }
        if (this.OrderStatusFlag !== undefined && this.OrderStatusFlag !== null) {
            if (this.OrderStatusFlag) {
                obj["OrderStatusFlag"] = true;
            } else {
                obj["OrderStatusFlag"] = false;
            }
        }
        if (this.PayResult !== undefined && this.PayResult !== null) {
            if (this.PayResult) {
                obj["PayResult"] = true;
            } else {
                obj["PayResult"] = false;
            }
        }
        return obj;
    }
}

const orderEntityFromObject = (item) => {
    let entity = new OrderEntity("");
    entity.PK = item.PK;
    entity.SK = item.SK;
    if (item.Type) {
        entity.Type = item.Type;
    }
    if (item.OrderNumber) {
        entity.OrderNumber = item.OrderNumber;
    }
    if (item.OrderTimestamp) {
        entity.OrderTimestamp = item.OrderTimestamp;
    }
    if (item.MemberID) {
        entity.MemberID = item.MemberID;
    }
    if (item.ProductType) {
        entity.ProductType = item.ProductType;
    }
    if (item.ProductSKU) {
        entity.ProductSKU = item.ProductSKU;
    }
    if (item.ProducerID) {
        entity.ProducerID = item.ProducerID;
    }
    if (item.VPName) {
        entity.VPName = item.VPName;
    }
    if (item.unitPrice !== undefined && item.unitPrice !== null) {
        entity.unitPrice = Math.floor(item.unitPrice);
        if (isNaN(entity.unitPrice)) {
            entity.unitPrice = 0;
        }
    }
    if (item.Currency) {
        entity.Currency = item.Currency;
    }
    if (item.viewDays !== undefined && item.viewDays !== null) {
        entity.viewDays = Math.floor(item.viewDays);
        if (isNaN(entity.viewDays)) {
            entity.viewDays = 0;
        }
    }
    if (item.discountList) {
        entity.discountList = item.discountList;
    }
    if (item.discountTotal !== undefined && item.discountTotal !== null) {
        entity.discountTotal = Math.floor(item.discountTotal);
        if (isNaN(entity.discountTotal)) {
            entity.discountTotal = 0;
        }
    }
    if (item.TotalPrice !== undefined && item.TotalPrice !== null) {
        entity.TotalPrice = Math.floor(item.TotalPrice);
        if (isNaN(entity.TotalPrice)) {
            entity.TotalPrice = 0;
        }
    }
    if (item.OrderStatusFlag !== undefined && item.OrderStatusFlag !== null) {
        if (item.OrderStatusFlag) {
            entity.OrderStatusFlag = true;
        } else {
            entity.OrderStatusFlag = false;
        }
    }
    if (item.PayResult !== undefined && item.PayResult !== null) {
        if (item.PayResult) {
            entity.PayResult = true;
        } else {
            entity.PayResult = false;
        }
    }
    return entity;
}

export { OrderEntity, orderEntityFromObject };

// {
// 	PK: "Orders",
// 	SK: "O#OrderNumber",
// 	Type: "Orders",
// 	OrderNumber: "MemberID20211231095959",
// 	OrderTimestamp: "20211231095959",
// 	MemberID: "memberID",
// 	ProductType: "VPID",  // VPID影品, VPSID訂閱, VSID影集, VCID影課
// 	ProductSKU: "miffy-20211231095959",
// 	ProducerID: "producerID",
//  VPName: "鬼滅之刃"
// 	unitPrice: 1000,
// 	Currency: "NTD",
//  viewDays: 1,
// 	discountList: [],
// 	discountTotal: 0,
// 	TotalPrice: 1000,
// 	OrderStatusFlag: false, // 訂單交易是否完成
//  PayResult: false // 交易完成是否付款
// }

import { deleteItem } from "./DynamodbMethods.js";

async function deleteVideoProfileByPkAndVid(PK, ProductSKUString) {
    let deleteRes;
    if (PK && ProductSKUString) {
        if (PK.length > 0 && ProductSKUString.length > 0) {
            deleteRes = await deleteItem(PK, "VProfile#"+ProductSKUString);
        }
    }
    return deleteRes;
}
export default deleteVideoProfileByPkAndVid;
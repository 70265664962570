import { docClientDelete, docClientQuery, s3 } from "./AWSConfig.js"
import kvnConfig from "./../views/KeyValueNameConfig.js";

function byteArrayEncodeToBase64(byteArray) {
  const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  let output = "";
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  let i = 0;
 
  while (i < byteArray.length) {
    chr1 = byteArray[i++];
    chr2 = i < byteArray.length ? byteArray[i++] : Number.NaN;
    chr3 = i < byteArray.length ? byteArray[i++] : Number.NaN;
 
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
 
    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + 
              keyStr.charAt(enc3) + keyStr.charAt(enc4);
  }
  return output;
}

function chunkBuffer(buf, length) {
  const arr = [];
  let startIndex = 0;
  let endIndex = length;
  if (endIndex > buf.length) {
      endIndex = buf.length;
  }
  while(startIndex < buf.length) {
    arr.push(buf.slice(startIndex, endIndex));
    startIndex = endIndex;
    endIndex = endIndex + length;
    if (endIndex > buf.length) {
      endIndex = buf.length;
    }
  }
  return arr;
};

const s3SetBucketAllUsersWritable = async (BucketName) => {

    return new Promise((resolve, reject) => {

      const params = {
          Bucket: BucketName ? BucketName:"",
          GrantFullControl: kvnConfig.grantFullControlString,
          GrantWrite: kvnConfig.grantWriteString
      };
      
      s3.putBucketAcl(params, (putErr, putData) => {
          if (putErr) {
              reject(putErr);
          } else {
              resolve(putData);
          }
      });
    });
};

const s3SetFilePublic = async (BucketName, PathAndKey) => {

    return new Promise((resolve, reject) => {
      const params3 = {
          Bucket: BucketName ? BucketName:"",
          Key: PathAndKey ? PathAndKey:"",
          GrantFullControl: kvnConfig.grantFullControlString,
          GrantRead: kvnConfig.grantReadString
      }
    
      s3.putObjectAcl(params3, (putErr, putData) => {
          if (putErr) {
              reject(putErr);
          } else {
              resolve(putData);
          }
      });
    });
};

const s3PutFile = async (BucketName, PathAndKey, file, progressCallback) => {

    return new Promise((resolve, reject) => {

      const params = {
          Bucket: BucketName ? BucketName:"",
          Key: PathAndKey ? PathAndKey:"",
          Body: file
      }
    
      s3.putObject(params, (putErr, putData) => {
          if (putErr) {
              reject(putErr);
          } else {
              resolve(putData);
          }
      })
      .on('httpUploadProgress', (progress) => {
          // 必須在promise()之前宣告
          if (progressCallback) {
            progressCallback(progress)
          }
      });
    });
};

const s3PutPublicFile = async (BucketName, PathAndKey, file, progressCallback) => {

  return new Promise((resolve, reject) => {

    const params = {
        Bucket: BucketName ? BucketName:"",
        Key: PathAndKey ? PathAndKey:"",
        GrantFullControl: kvnConfig.grantFullControlString,
        GrantRead: kvnConfig.grantReadString,
        Body: file
    }
    
    s3.putObject(params, (putErr, putData) => {
        if (putErr) {
            reject(putErr);
        } else {
            resolve(putData);
        }
    })
    .on('httpUploadProgress', (progress) => {
        // 必須在promise()之前宣告
        if (progressCallback) {
          progressCallback(progress)
        }
    });
  });
};

const s3GetFile = async (BucketName, PathAndKey) => {

    return new Promise((resolve, reject) => {

      const params = {
          Bucket: BucketName ? BucketName:"",
          Key: PathAndKey ? PathAndKey:""
      }
      
      s3.getObject(params, (getErr, getData) => {
          if (getErr) {
              reject(getErr);
          } else {
              resolve(getData);
          }
      });
    });
};

const s3GetFileAsPng = async (BucketName, PathAndKey) => {

    return new Promise((resolve, reject) => {
      s3GetFile(BucketName, PathAndKey)
      .then((res) => {
          let base64 = "";
          if (res) {
              const resultBody = res.Body;
              if (resultBody) {
                  const bufferArray = JSON.parse(JSON.stringify(resultBody)).data;
                  if (bufferArray) {
                    // data:audio/ogg;base64,
                    // data:image/png;base64,
                    // data:video/mp4;base64,
                    base64 = "data:image/png;base64," + byteArrayEncodeToBase64(bufferArray);
                    // base64 = "data:image/png;base64," + btoa(bufferArray.reduce((data, byte) => data + String.fromCharCode(byte), ''));
                  }
              }
          }
          resolve(base64);
      })
      .catch((err) => {
          resolve("");
      });
    });
};

const s3GetFileAsMp4 = async (BucketName, PathAndKey) => {

  return new Promise((resolve, reject) => {
    s3GetFile(BucketName, PathAndKey)
    .then((res) => {
        let base64 = "";
        if (res) {
            const resultBody = res.Body;
            if (resultBody) {
                const bufferArray = JSON.parse(JSON.stringify(resultBody)).data;
                if (bufferArray) {
                  // data:audio/ogg;base64,
                  // data:image/png;base64,
                  // data:video/mp4;base64,
                  base64 = "data:video/mp4;base64," + byteArrayEncodeToBase64(bufferArray);
                }
            }
        }
        resolve(base64);
    })
    .catch((err) => {
        resolve("");
    });
  });
};

const s3DeleteFile = async (BucketName, PathAndKey) => {

    return new Promise((resolve, reject) => {
      
      const params = {
          Bucket: BucketName ? BucketName:"",
          Key: PathAndKey ? PathAndKey:""
      }
      
      s3.deleteObject(params, (deleteErr, deleteData) => {
          if (deleteErr) {
              reject(deleteErr);
          } else {
              resolve(deleteData);
          }
      });
    });
};

const s3UploadPart = async (partParams) => {
  return new Promise((resolve, reject) => {
    s3.uploadPart(partParams, (err, res) => {
        if (err) {
            reject(err);
        } else {
            resolve(res);
        }
    });
  });
};

const s3CreateMultipartUpload = async (BucketName, PathAndKey, ContentEncoding) => {

  let params = {
    Bucket: BucketName ? BucketName:"",
    Key: PathAndKey ? PathAndKey:""
  };
  if (ContentEncoding) {
    params = {
      Bucket: BucketName ? BucketName:"",
      Key: PathAndKey ? PathAndKey:"",
      ContentEncoding: ContentEncoding
    };
  }

  return new Promise((resolve, reject) => {
    s3.createMultipartUpload(params, (err, res) => {
        if (err) {
            reject(err);
        } else {
            resolve(res);
        }
    });
  });
};

const s3CompleteMultipartUpload = async (BucketName, PathAndKey, PartsArray, UploadId) => {

  const params = {
    Bucket: BucketName ? BucketName:"",
    Key: PathAndKey ? PathAndKey:"",
    MultipartUpload: PartsArray,
    UploadId: UploadId
  };

  return new Promise((resolve, reject) => {
    s3.completeMultipartUpload(params, (err, res) => {
        if (err) {
            reject(err);
        } else {
            resolve(res);
        }
    });
  });
};

const s3AbortMultipartUpload = async (BucketName, PathAndKey, UploadId) => {

  const params = {
    Bucket: BucketName ? BucketName:"",
    Key: PathAndKey ? PathAndKey:"",
    UploadId: UploadId
  };

  return new Promise((resolve, reject) => {
    s3.abortMultipartUpload(params, (err, res) => {
        if (err) {
            reject(err);
        } else {
            resolve(res);
        }
    });
  });
};

const s3PartUploadBufferAsync = async (BucketName, PathAndKey, uploadId, bufferArray) => {

    let PartsArray = {"Parts":[]};
    for(let i=0;i<bufferArray.length;i++) {
      
      let buf = bufferArray[i];

      // const buffBinaryString = buf.toString('binary');
      // buf = Buffer.from(buffBinaryString, 'binary');

      const part1Params = {
          Body: buf,
          Bucket: BucketName,
          Key: PathAndKey, 
          PartNumber: (i+1), 
          UploadId: uploadId
      };

      const res2 = await s3UploadPart(part1Params);
      if (res2) {
        PartsArray.Parts.push({"PartNumber": (i+1), "ETag": res2.ETag ? res2.ETag:""});
      }
    }
    return PartsArray;
};

const s3MultiUploadAsync = (BucketName, PathAndKey, file, callback) => {

    let reader = new FileReader();
    reader.onloadend = (e) => {

      let base64Data = '';
      const dataArray = e.target.result.split(',');
      if (dataArray) {
        if (dataArray.length > 1) {
          base64Data = dataArray[1];
        }
      }
      
      s3CreateMultipartUpload(BucketName, PathAndKey, 'base64')
      .then((res01) => {
        if (res01) {
          if (res01.UploadId) {
            if (res01.UploadId.length > 0) {

              const buf = Buffer.from(base64Data,'base64');
              const bufferArray = chunkBuffer(buf, 5242880);
              s3PartUploadBufferAsync(BucketName, PathAndKey, res01.UploadId, bufferArray)
              .then((PartsArray) => {
                const completeParams = {
                    Bucket: BucketName,
                    Key: PathAndKey,
                    MultipartUpload: PartsArray,
                    UploadId: res01.UploadId
                };
                
                s3CompleteMultipartUpload(BucketName, PathAndKey, PartsArray, res01.UploadId)
                .then((res04) => {
                  callback(true, res04);
                })
                .catch((err04) => {
                  callback(false, err04);
                });
              })
              .catch(() => {
                
                s3AbortMultipartUpload(BucketName, PathAndKey, res01.UploadId)
                .then((res05) => {
                  callback(false, res05);
                })
                .catch((err05) => {
                  callback(false, err05);
                });
              });
            } else {
              callback(false, {"message": "res01.UploadId: length 0"});
            }
          } else {
            callback(false, {"message": "res01.UploadId: null"});
          }
        } else {
          callback(false, {"message": "res01: null"});
        }
      })
      .catch((err01) => {
        callback(false, err01);
      });

    };
    reader.readAsDataURL(file);
};

const s3CopyFile = (srcBucketName, srcPathAndKey, dstBucketName, dstPathAndKey) => {
    const params = {
      CopySource: "/"+srcBucketName+"/"+srcPathAndKey, 
      Bucket: dstBucketName,
      Key: dstPathAndKey
    };

    return new Promise((resolve, reject) => {
      s3.copyObject(params, (err, res) => {
          if (err) {
              reject(err);
          } else {
              resolve(res);
          }
      });
    });
};

const s3CopyPublicFile = (srcBucketName, srcPathAndKey, dstBucketName, dstPathAndKey) => {
  const params = {
    CopySource: "/"+srcBucketName+"/"+srcPathAndKey, 
    Bucket: dstBucketName,
    Key: dstPathAndKey,
    GrantFullControl: kvnConfig.grantFullControlString,
    GrantRead: kvnConfig.grantReadString
  };

  return new Promise((resolve, reject) => {
    s3.copyObject(params, (err, res) => {
        if (err) {
            reject(err);
        } else {
            resolve(res);
        }
    });
  });
};

const s3DeleteTempFiles = async (BucketName, uid) => {

  let deleteCounter = 0;

  let paramsQuery = {
    TableName : kvnConfig.dbMainTableName,
    ExpressionAttributeNames: {
      "#keyAlias1": "PK"
    },
    ExpressionAttributeValues: {
      ":valueAlias1": "Temp#"+BucketName
    },
    KeyConditionExpression: "#keyAlias1 = :valueAlias1"
  };
  if (uid) {
    if (uid.length > 0) {
      paramsQuery = {
        TableName : kvnConfig.dbMainTableName,
        ExpressionAttributeNames: {
          "#keyAlias1": "PK",
          "#keyAlias2": "SK"
        },
        ExpressionAttributeValues: {
          ":valueAlias1": "Temp#"+BucketName,
          ":valueAlias2": uid+"#"
        },
        KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)"
      };
    }
  }

  const res = await docClientQuery(paramsQuery);
  if (res) {
    if (res.Items) {
      if (res.Items.length > 0) {
        for (let i=0;i<res.Items.length;i++) {
          
          if (res.Items[i].PK && res.Items[i].SK) {
            let pksps = res.Items[i].PK.split("#");
            let sksps = res.Items[i].SK.split("#");
            if (pksps && sksps) {
              if (pksps.length > 1 && sksps.length > 1) {
                if (pksps[pksps.length - 1].length > 0 && sksps[sksps.length - 1].length > 0) {
                  
                  const s3Params = {
                      Bucket: pksps[pksps.length - 1],
                      Key: sksps[sksps.length - 1]
                  };
                  const res1 = await s3DeleteFile(s3Params);
                  const tempParams = {
                    TableName : kvnConfig.dbMainTableName,
                    Key: {
                      "PK":res.Items[i].PK,
                      "SK":res.Items[i].SK
                    }
                  };
                  const res2 = await docClientDelete(tempParams);
                  deleteCounter = deleteCounter + 1;
                }
              }
            }
          }
        }
      }
    }
  }
  return { count: deleteCounter }
}

export {
    s3SetBucketAllUsersWritable, s3SetFilePublic, s3PutFile, s3PutPublicFile, s3GetFile,
    s3DeleteFile, s3GetFileAsPng, s3GetFileAsMp4, s3PartUploadBufferAsync, s3MultiUploadAsync,
    s3CopyFile, s3CopyPublicFile, s3DeleteTempFiles
};
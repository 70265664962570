class ProducerInfoEntity {
    
    constructor(ProducerID) {
        this.PK = "Producers";
        this.SK = (ProducerID ? ((ProducerID.length > 0) ? ("P#"+ProducerID):""):"");
        this.ProducerID = ProducerID ? ProducerID:"";
        this.TYPE = "P_Main";
        // this.Acct_Type = "";
        // this.Active = false;
        // this.LineID = "";
        // this.LineAccessToken = "";
        // this.akaname = "";
        // this.brief = "";
        // this.language = "";
        // this.BucketName = "";
        // this.PathAndKey = "";
        // this.Buys = 0;
        // this.VP_Count = 0;
        // this.createAt = "";
        // this.updateAt = "";
    }

    toObject() {
        let obj = {
            "PK": "Producers",
            "SK": this.SK,
            "TYPE": "P_Main"
        };
        if (this.ProducerID) {
            obj["ProducerID"] = this.ProducerID;
        }
        if (this.Acct_Type) {
            obj["Acct_Type"] = this.Acct_Type;
        }
        if (this.Active !== undefined && this.Active !== null) {
            if (this.Active) {
                obj["Active"] = true;
            } else {
                obj["Active"] = false;
            }
        }
        if (this.LineID) {
            obj["LineID"] = this.LineID;
        }
        if (this.LineAccessToken) {
            obj["LineAccessToken"] = this.LineAccessToken;
        }
        if (this.akaname) {
            obj["akaname"] = this.akaname;
        }
        if (this.brief) {
            obj["brief"] = this.brief;
        }
        if (this.language) {
            obj["language"] = this.language;
        }
        if (this.BucketName) {
            obj["BucketName"] = this.BucketName;
        }
        if (this.PathAndKey) {
            obj["PathAndKey"] = this.PathAndKey;
        }
        if (this.Buys !== undefined && this.Buys !== null) {
            obj["Buys"] = Math.floor(this.Buys);
            if (isNaN(obj["Buys"])) {
                obj["Buys"] = 0;
            }
        }
        if (this.VP_Count !== undefined && this.VP_Count !== null) {
            obj["VP_Count"] = Math.floor(this.VP_Count);
            if (isNaN(obj["VP_Count"])) {
                obj["VP_Count"] = 0;
            }
        }
        if (this.createAt) {
            obj["createAt"] = this.createAt;
        }
        if (this.updateAt) {
            obj["updateAt"] = this.updateAt;
        }
        return obj;
    }
}

const producerInfoEntityFromObject = (item) => {
    let entity = new ProducerInfoEntity("");
    entity.PK = "Producers";
    entity.SK = item.SK;
    entity.TYPE = "P_Main";
    if (item.ProducerID) {
        entity.ProducerID = item.ProducerID;
    }
    if (item.Acct_Type) {
        entity.Acct_Type = item.Acct_Type;
    }
    if (item.Active !== undefined && item.Active !== null) {
        if (item.Active) {
            entity.Active = true;
        } else {
            entity.Active = false;
        }
    }
    if (item.LineID) {
        entity.LineID = item.LineID;
    }
    if (item.LineAccessToken) {
        entity.LineAccessToken = item.LineAccessToken;
    }
    if (item.akaname) {
        entity.akaname = item.akaname;
    }
    if (item.brief) {
        entity.brief = item.brief;
    }
    if (item.language) {
        entity.language = item.language;
    }
    if (item.BucketName) {
        entity.BucketName = item.BucketName;
    }
    if (item.PathAndKey) {
        entity.PathAndKey = item.PathAndKey;
    }
    if (item.Buys !== undefined && item.Buys !== null) {
        entity.Buys = Math.floor(item.Buys);
        if (isNaN(entity.Buys)) {
            entity.Buys = 0;
        }
    }
    if (item.VP_Count !== undefined && item.VP_Count !== null) {
        entity.VP_Count = Math.floor(item.VP_Count);
        if (isNaN(entity.VP_Count)) {
            entity.VP_Count = 0;
        }
    }
    if (item.createAt) {
        entity.createAt = item.createAt;
    }
    if (item.updateAt) {
        entity.updateAt = item.updateAt;
    }
    return entity;
}

export { ProducerInfoEntity, producerInfoEntityFromObject };

// {
//     "PK": "Producers",
//     "SK": "P#coreytu",
//     "Acct_Type": "COMP",  // IDV, COMP, ADMIN
//     "Active": true,
//     "akaname": "CT Prouction",
//     "brief": "由測試大師團隊擔任創意大師，陸續製作史上最大賣的 短視頻娛樂，很多人關注",
//     "BucketName": "kingvideoadmin5c1ac373cd004a24869147aafb0a82fd81745-staging",
//     "Buys": "",
//     "createAt": 1631003287127,
//     "language": "ZH-TW",
//     "LineID": "Ueefcced91908cbced1944adcb3aef435",
//     "LineAccessToken": "Ueefcced91908cbced1944adcb3aef435",
//     "PathAndKey": "Producers/coreytu-logo.jpeg",
//     "ProducerID": "coreytu",
//     "TYPE": "P_Main",
//     "updateAt": "",
//     "VP_Count": ""
//   }
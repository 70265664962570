import { updateItem } from "./DynamodbMethods.js";

async function updatePhoto(photoEntity) {
    
    let result;

    if (photoEntity) {

        photoEntity.PK = "Photos";

        if (photoEntity.PK && photoEntity.SK) {
            if (photoEntity.PK.length > 0 && photoEntity.SK.length > 0) {
                result = await updateItem(photoEntity);
            }
        }
    }
    return result;
}
  
export default updatePhoto;
import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

// {
// 	PK: "Orders",
// 	SK: "O#OrderNumber",
// 	Type: "Orders",
// 	OrderNumber: "20211231095959MemberID",
// 	OrderTimestamp: "20211231095959",
// 	MemberID: "memberID",
// 	ProductType: "VPID",  // VPID影品, VPSID訂閱, VSID影集, VCID影課
// 	ProductSKU: "miffy-20211231095959",
// 	ProducerID: "producerID",
// 	unitPrice: 1000,
// 	Currency: "NTD",
// 	discountList: [],
// 	discountTotal: 0,
// 	TotalPrice: 1000,
// 	OrderStatusFlag: false
// }

async function putOrder(orderEntity) {
    
    let result;
    let oldEntity = orderEntity;
    if (orderEntity) {

        orderEntity.PK = "Orders";
        orderEntity.Type = "Orders";

        if (orderEntity.SK) {
            if (orderEntity.SK.length > 0) {
                // do nothing
            } else {
                if (orderEntity.OrderNumber){
                    if (orderEntity.OrderNumber.length > 0) {
                        orderEntity.SK = "O#"+orderEntity.OrderNumber
                    }
                }
            }
        } else {
            if (orderEntity.OrderNumber){
                if (orderEntity.OrderNumber.length > 0) {
                    orderEntity.SK = "O#"+orderEntity.OrderNumber
                }
            }
        }

        if (orderEntity.PK && orderEntity.SK && orderEntity.OrderNumber) {
            if (orderEntity.PK.length > 0 && orderEntity.SK.length > 0 && orderEntity.OrderNumber.length > 0) {
                
                result = await putItem(orderEntity);
            }
        }
    }
    if (result) {
        return orderEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putOrder;
import kvnConfig from "./../views/KeyValueNameConfig.js";
class FilmEntity {
    constructor(ProducerID, fileMainName, fileSubName) {
        const timestamp = (new Date()).getTime();
        this.PK = "Films";
        if (ProducerID) {
            if (ProducerID.length > 0) {
                this.SK = ProducerID+"-"+timestamp;
            }
        }
        if (fileMainName) {
            if (fileMainName.length > 0) {
                this.DisplayName = fileMainName;
            }
        }
        if (ProducerID) {
            this.ProducerID = ProducerID;
        }
        this.BucketName = kvnConfig.s3MainBucketName;
        if (ProducerID && fileSubName) {
            if (ProducerID.length > 0 && fileSubName.length > 0) {
                this.PathAndKey = "Film/"+ProducerID+"-"+timestamp+"."+fileSubName;
            } else {
                if (ProducerID) {
                    if (ProducerID.length > 0) {
                        this.PathAndKey = "Film/"+ProducerID+"-"+timestamp;
                    }
                }
            }
        } else {
            if (ProducerID) {
                if (ProducerID.length > 0) {
                    this.PathAndKey = "Film/"+ProducerID+"-"+timestamp;
                }
            }
        }
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK
        };
        if (this.ProducerID) {
            obj["ProducerID"] = this.ProducerID;
        }
        if (this.ExtensionName) {
            obj["ExtensionName"] = this.ExtensionName;
        }
        if (this.BucketName) {
            obj["BucketName"] = this.BucketName;
        }
        if (this.PathAndKey) {
            obj["PathAndKey"] = this.PathAndKey;
        }
        if (this.MimeType) {
            obj["MimeType"] = this.MimeType;
        }
        if (this.SizeBytes !== undefined && this.SizeBytes !== null) {
            obj["SizeBytes"] = Math.floor(this.SizeBytes);
            if (isNaN(obj["SizeBytes"])) {
                obj["SizeBytes"] = 0;
            }
        }
        if (this.LengthSec !== undefined && this.LengthSec !== null) {
            obj["LengthSec"] = Math.floor(this.LengthSec);
            if (isNaN(obj["LengthSec"])) {
                obj["LengthSec"] = 0;
            }
        }
        if (this.DisplayName) {
            obj["DisplayName"] = this.DisplayName;
        }
        return obj;
    }
}

const filmEntityFromObject = (item) => {
    let entity = new FilmEntity("", "", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    if (item.ProducerID) {
        entity.ProducerID = item.ProducerID;
    }
    if (item.ExtensionName) {
        entity.ExtensionName = item.ExtensionName;
    }
    if (item.BucketName) {
        entity.BucketName = item.BucketName;
    }
    if (item.PathAndKey) {
        entity.PathAndKey = item.PathAndKey;
    }
    if (item.MimeType) {
        entity.MimeType = item.MimeType;
    }
    if (item.SizeBytes !== undefined && item.SizeBytes !== null) {
        entity.SizeBytes = Math.floor(item.SizeBytes);
        if (isNaN(entity.SizeBytes)) {
            entity.SizeBytes = 0;
        }
    }
    if (item.LengthSec !== undefined && item.LengthSec !== null) {
        entity.LengthSec = Math.floor(item.LengthSec);
        if (isNaN(entity.LengthSec)) {
            entity.LengthSec = 0;
        }
    }
    if (item.DisplayName) {
        entity.DisplayName = item.DisplayName;
    }
    return entity;
}

export { FilmEntity, filmEntityFromObject };

// {
//     PK: "Films",
//     SK: ProducerID+"-"+timestamp,
//     ProducerID: ProducerID,
//     ExtensionName: fileSubName,
//     BucketName: BucketName,
//     PathAndKey: PathAndKey,
//     MimeType: fileMimeType,
//     SizeBytes: fileByteSize,
//     LengthSec: fileSecLength,
//     DisplayName: fileMainName
//   }
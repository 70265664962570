import { queryItems } from "./DynamodbMethods.js";

async function getVideoInfosByPid(ProducerIDString) {
    let res;
    if (ProducerIDString) {
        if (ProducerIDString.length > 0) {
            res = await queryItems("VP#"+ProducerIDString, "");
        }
    }
    return res;
}
  
export default getVideoInfosByPid;
import { updateItem } from "./DynamodbMethods.js";

async function updateVideoInfo(videoInfoEntity) {
    
    let result;

    if (videoInfoEntity) {

        videoInfoEntity.Type = "V_Main";

        if (videoInfoEntity.PK) {
            if (videoInfoEntity.PK.length > 0) {
                // do nothing
            } else {
                if (videoInfoEntity.ProducerID){
                    if (videoInfoEntity.ProducerID.length > 0) {
                        videoInfoEntity.PK = "VP#"+videoInfoEntity.ProducerID
                    }
                }
            }
        } else {
            if (videoInfoEntity.ProducerID){
                if (videoInfoEntity.ProducerID.length > 0) {
                    videoInfoEntity.PK = "VP#"+videoInfoEntity.ProducerID
                }
            }
        }

        if (videoInfoEntity.SK) {
            if (videoInfoEntity.SK.length > 0) {
                // do nothing
            } else {
                if (videoInfoEntity.ProductType && videoInfoEntity.ProductSKU){
                    if (videoInfoEntity.ProductType.length > 0 && videoInfoEntity.ProductSKU.length > 0) {
                        videoInfoEntity.SK = videoInfoEntity.ProductType+"#"+videoInfoEntity.ProductSKU;
                    }
                }
            }
        } else {
            if (videoInfoEntity.ProductType && videoInfoEntity.ProductSKU){
                if (videoInfoEntity.ProductType.length > 0 && videoInfoEntity.ProductSKU.length > 0) {
                    videoInfoEntity.SK = videoInfoEntity.ProductType+"#"+videoInfoEntity.ProductSKU;
                }
            }
        }

        if (videoInfoEntity.PK && videoInfoEntity.SK) {
            if (videoInfoEntity.PK.length > 0 && videoInfoEntity.SK.length > 0) {
                result = await updateItem(videoInfoEntity);
            }
        }
    }
    return result;
}
  
export default updateVideoInfo;
import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

// {
//     "PK":"Temp#"+BucketName,
//     "SK":uid+"#"+PathAndKey
//   }

async function putFileTemp(fileTempEntity) {
    
    let result;
    if (fileTempEntity) {
        if (fileTempEntity.PK && fileTempEntity.SK) {
            if (fileTempEntity.PK.length > 0 && fileTempEntity.SK.length > 0) {
                result = await putItem(fileTempEntity);
            }
        }
    }
    return fileTempEntity.toObject();
}
  
export default putFileTemp;
import getMemberProfileByLineTokenID from "./getMemberProfileByLineTokenID.js";
import getMemberProfileByLineID from "./getMemberProfileByLineID.js";
import getMemberProfileByMid from "./getMemberProfileByMid.js";
import putMemberProfile from "./putMemberProfile.js";
import updateMemberProfile from "./updateMemberProfile.js";
import updateMemberLogo from "./updateMemberLogo.js";
import updateMemberProfileWithoutLogo from "./updateMemberProfileWithoutLogo.js";

import getMemberVideoExpire from "./getMemberVideoExpire.js";
import putMemberVideoExpire from "./putMemberVideoExpire.js";
import updateMemberVideoExpire from "./updateMemberVideoExpire.js";

import getProducerInfoByLineTokenID from "./getProducerInfoByLineTokenID.js";
import getProducerInfoByProducerID from "./getProducerInfoByProducerID.js";
import getProducerInfoByLineID from "./getProducerInfoByLineID.js";
import getProducerInfoItems from "./getProducerInfoItems.js";
import putProducerInfo from "./putProducerInfo.js";
import updateProducerInfo from "./updateProducerInfo.js";

import getProducerProfileCOMPByPid from "./getProducerProfileCOMPByPid.js";
import putProducerProfileCOMP from "./putProducerProfileCOMP.js";
import updateProducerProfileCOMP from "./updateProducerProfileCOMP.js";
import getProducerProfileIDVByPid from "./getProducerProfileIDVByPid.js";
import putProducerProfileIDV from "./putProducerProfileIDV.js";
import updateProducerProfileIDV from "./updateProducerProfileIDV.js";

import getVideoFileByPkAndVid from "./getVideoFileByPkAndVid.js";
import getVideoFileByPidAndVid from "./getVideoFileByPidAndVid.js";
import getVideoFilesByPid from "./getVideoFilesByPid.js";
import putVideoFile from "./putVideoFile.js";
import updateVideoFile from "./updateVideoFile.js";
import deleteVideoFileByPidAndVid from "./deleteVideoFileByPidAndVid.js";
import deleteVideoFileByPkAndVid from "./deleteVideoFileByPkAndVid.js";

import getVideoInfoByPidAndPtypeAndPsku from "./getVideoInfoByPidAndPtypeAndPsku.js";
import getVideoInfosByPid from "./getVideoInfosByPid.js";
import putVideoInfo from "./putVideoInfo.js";
import updateVideoInfo from "./updateVideoInfo.js";
import deleteVideoInfoByPkAndPtypeAndPsku from "./deleteVideoInfoByPkAndPtypeAndPsku.js";

import getVideoPriceByVidAndPrice from "./getVideoPriceByVidAndPrice.js";
import getVideoPricesByVid from "./getVideoPricesByVid.js";
import putVideoPrice from "./putVideoPrice.js";
import deleteVideoPriceByVidAndPrice from "./deleteVideoPriceByVidAndPrice.js";

import getVideoProductionByPidAndVid from "./getVideoProductionByPidAndVid.js";
import putVideoProduction from "./putVideoProduction.js";
import updateVideoProduction from "./updateVideoProduction.js";
import deleteVideoProductionByPkAndVid from "./deleteVideoProductionByPkAndVid.js";

import getVideoProfileByPidAndVid from "./getVideoProfileByPidAndVid.js";
import getVideoProfilesByPid from "./getVideoProfilesByPid.js";
import putVideoProfile from "./putVideoProfile.js";
import updateVideoProfile from "./updateVideoProfile.js";
import deleteVideoProfileByPkAndVid from "./deleteVideoProfileByPkAndVid.js";

import getVideoPAPByPtypeAndPsku from "./getVideoPAPByPtypeAndPsku.js";
import putVideoPAP from "./putVideoPAP.js";
import updateVideoPAP from "./updateVideoPAP.js";

import putVideoPAPResult from "./putVideoPAPResult.js";

import putFileTemp from "./putFileTemp.js";
import deleteFileTemps from "./deleteFileTemps.js";

import getFilmByPkAndSk from "./getFilmByPkAndSk.js";
import getFilmsByProducerID from "./getFilmsByProducerID.js";
import deleteFilmByPkAndSk from "./deleteFilmByPkAndSk.js";
import deleteFilmsByProducerID from "./deleteFilmsByProducerID.js";
import uploadFilms from "./uploadFilms.js";
import updateFilm from "./updateFilm.js";

import getPhotoByPkAndSk from "./getPhotoByPkAndSk.js";
import getPhotosByProducerID from "./getPhotosByProducerID.js";
import deletePhotoByPkAndSk from "./deletePhotoByPkAndSk.js";
import deletePhotosByProducerID from "./deletePhotosByProducerID.js";
import uploadPhotos from "./uploadPhotos.js";
import updatePhoto from "./updatePhoto.js";

import getCDNSecret from "./getCDNSecret.js";
import putCDNSecret from "./putCDNSecret.js";
import updateCDNSecret from "./updateCDNSecret.js";

import getS3FileDataUrl from "./getS3FileDataUrl.js";

import getOrderByOrderNumber from "./getOrderByOrderNumber.js";
import getOrdersUnfinishedByMidAndPtypeAndPsku from "./getOrdersUnfinishedByMidAndPtypeAndPsku.js";
import getOrdersPaidByMid from "./getOrdersPaidByMid.js";
import putOrder from "./putOrder.js";
import updateOrder from "./updateOrder.js";
import deleteOrderByOid from "./deleteOrderByOid.js";

import getPayments from "./getPayments.js";
import putPayment from "./putPayment.js";
import updatePayment from "./updatePayment.js";

import putInvoice from "./putInvoice.js";
import updateInvoice from "./updateInvoice.js";

import getKuPaySha256HashKey from "./getKuPaySha256HashKey.js";
import kuPayOrder from "./kuPayOrder.js";
import kuPayQuery from "./kuPayQuery.js";
import kuPayCancel from "./kuPayCancel.js";

import {
    s3SetBucketAllUsersWritable, s3SetFilePublic, s3PutFile, s3PutPublicFile, s3GetFile,
    s3DeleteFile, s3GetFileAsPng, s3GetFileAsMp4, s3PartUploadBufferAsync, s3MultiUploadAsync,
    s3CopyFile, s3CopyPublicFile, s3DeleteTempFiles
} from "./S3Methods.js";

import {
    transactWriteItems,getFirstItem, getLastItem,
    putItem,putItem_V2,updateItem, deleteItem, queryItems
} from "./DynamodbMethods.js";

import {
    dateToUTCString, dateToLocalString, utcStringToDate, localStringToDate
} from "./DatetimeFormatter.js";

import {
    getUrlWithToken, createTokenWithURI, createTokenWithPath
} from "./HinetCDN.js";

// export {

//     getMemberProfileByLineTokenID, getMemberProfileByLineID, getMemberProfileByMid, putMemberProfile, updateMemberProfile, updateMemberLogo, updateMemberProfileWithoutLogo,

//     getMemberVideoExpire, putMemberVideoExpire, updateMemberVideoExpire,

//     getProducerInfoByLineTokenID, getProducerInfoByProducerID, getProducerInfoByLineID, getProducerInfoItems, putProducerInfo, updateProducerInfo,

//     getProducerProfileCOMPByPid, putProducerProfileCOMP, updateProducerProfileCOMP,
//     getProducerProfileIDVByPid, putProducerProfileIDV, updateProducerProfileIDV,

//     getVideoProductionByPidAndVid, putVideoProduction,
//     updateVideoProduction, deleteVideoProductionByPkAndVid,

//     getVideoFileByPkAndVid, getVideoFileByPidAndVid, getVideoFilesByPid, putVideoFile,
//     updateVideoFile, deleteVideoFileByPidAndVid, deleteVideoFileByPkAndVid,

//     getVideoInfoByPidAndPtypeAndPsku, getVideoInfosByPid, putVideoInfo,
//     updateVideoInfo, deleteVideoInfoByPkAndPtypeAndPsku,

//     getVideoPriceByVidAndPrice, getVideoPricesByVid,
//     putVideoPrice, deleteVideoPriceByVidAndPrice,

//     getVideoProfileByPidAndVid, getVideoProfilesByPid, putVideoProfile,
//     updateVideoProfile, deleteVideoProfileByPkAndVid,

//     getVideoPAPByPtypeAndPsku, putVideoPAP, updateVideoPAP,

//     putVideoPAPResult,
    
//     putFileTemp, deleteFileTemps,

//     getFilmByPkAndSk, getFilmsByProducerID, deleteFilmByPkAndSk, deleteFilmsByProducerID, uploadFilms, updateFilm

//     getPhotoByPkAndSk, getPhotosByProducerID, deletePhotoByPkAndSk, deletePhotosByProducerID, uploadPhotos, updatePhoto

//     getCDNSecret, putCDNSecret, updateCDNSecret,

//     getS3FileDataUrl,

//     getOrderByOrderNumber, getOrdersUnfinishedByMidAndPtypeAndPsku, getOrdersPaidByMid, putOrder, updateOrder, deleteOrderByOid,

//     getPayments, putPayment, updatePayment,

//     putInvoice, updateInvoice,

//     getKuPaySha256HashKey, kuPayOrder, kuPayQuery, kuPayCancel,

//     s3SetBucketAllUsersWritable, s3SetFilePublic, s3PutFile, s3PutPublicFile, s3GetFile,
//     s3DeleteFile, s3GetFileAsPng, s3GetFileAsMp4, s3PartUploadBufferAsync, s3MultiUploadAsync,
//     s3CopyFile, s3CopyPublicFile, s3DeleteTempFiles,
    
//     transactWriteItems,getFirstItem, getLastItem,
//     putItem, updateItem, deleteItem, queryItems,

//     dateToUTCString, dateToLocalString, utcStringToDate, localStringToDate,

//     getUrlWithToken, createTokenWithURI, createTokenWithPath,

// };

class DataTools {
    constructor() {

        this.getMemberProfileByLineTokenID = getMemberProfileByLineTokenID;
        this.getMemberProfileByLineID = getMemberProfileByLineID;
        this.getMemberProfileByMid = getMemberProfileByMid;
        this.putMemberProfile = putMemberProfile;
        this.updateMemberProfile = updateMemberProfile;
        this.updateMemberLogo = updateMemberLogo;
        this.updateMemberProfileWithoutLogo = updateMemberProfileWithoutLogo;

        this.getMemberVideoExpire = getMemberVideoExpire;
        this.putMemberVideoExpire = putMemberVideoExpire;
        this.updateMemberVideoExpire = updateMemberVideoExpire;

        this.getProducerInfoByLineTokenID = getProducerInfoByLineTokenID;
        this.getProducerInfoByProducerID = getProducerInfoByProducerID;
        this.getProducerInfoByLineID = getProducerInfoByLineID;
        this.getProducerInfoItems = getProducerInfoItems;
        this.putProducerInfo = putProducerInfo;
        this.updateProducerInfo = updateProducerInfo;
        
        this.getProducerProfileCOMPByPid = getProducerProfileCOMPByPid;
        this.putProducerProfileCOMP = putProducerProfileCOMP;
        this.updateProducerProfileCOMP = updateProducerProfileCOMP;
        this.getProducerProfileIDVByPid = getProducerProfileIDVByPid;
        this.putProducerProfileIDV = putProducerProfileIDV;
        this.updateProducerProfileIDV = updateProducerProfileIDV;
        
        this.getVideoProductionByPidAndVid = getVideoProductionByPidAndVid;
        this.putVideoProduction = putVideoProduction;
        this.updateVideoProduction = updateVideoProduction;
        this.deleteVideoProductionByPkAndVid = deleteVideoProductionByPkAndVid;
        
        this.getVideoFileByPkAndVid = getVideoFileByPkAndVid;
        this.getVideoFileByPidAndVid = getVideoFileByPidAndVid;
        this.getVideoFilesByPid = getVideoFilesByPid;
        this.putVideoFile = putVideoFile;
        this.updateVideoFile = updateVideoFile;
        this.deleteVideoFileByPidAndVid = deleteVideoFileByPidAndVid;
        this.deleteVideoFileByPkAndVid = deleteVideoFileByPkAndVid;
        
        this.getVideoInfoByPidAndPtypeAndPsku = getVideoInfoByPidAndPtypeAndPsku;
        this.getVideoInfosByPid = getVideoInfosByPid;
        this.putVideoInfo = putVideoInfo;
        this.updateVideoInfo = updateVideoInfo;
        this.deleteVideoInfoByPkAndPtypeAndPsku = deleteVideoInfoByPkAndPtypeAndPsku;
        
        this.getVideoPriceByVidAndPrice = getVideoPriceByVidAndPrice;
        this.getVideoPricesByVid = getVideoPricesByVid;
        this.putVideoPrice = putVideoPrice;
        this.deleteVideoPriceByVidAndPrice = deleteVideoPriceByVidAndPrice;
        
        this.getVideoProfileByPidAndVid = getVideoProfileByPidAndVid;
        this.getVideoProfilesByPid = getVideoProfilesByPid;
        this.putVideoProfile = putVideoProfile;
        this.updateVideoProfile = updateVideoProfile;
        this.deleteVideoProfileByPkAndVid = deleteVideoProfileByPkAndVid;
        
        this.getVideoPAPByPtypeAndPsku = getVideoPAPByPtypeAndPsku;
        this.putVideoPAP = putVideoPAP;
        this.updateVideoPAP = updateVideoPAP;

        this.putVideoPAPResult = putVideoPAPResult;

        this.putFileTemp = putFileTemp;
        this.deleteFileTemps = deleteFileTemps;

        this.getFilmByPkAndSk = getFilmByPkAndSk;
        this.getFilmsByProducerID = getFilmsByProducerID;
        this.deleteFilmByPkAndSk = deleteFilmByPkAndSk;
        this.deleteFilmsByProducerID = deleteFilmsByProducerID;
        this.uploadFilms = uploadFilms;
        this.updateFilm = updateFilm;

        this.getPhotoByPkAndSk = getPhotoByPkAndSk;
        this.getPhotosByProducerID = getPhotosByProducerID;
        this.deletePhotoByPkAndSk = deletePhotoByPkAndSk;
        this.deletePhotosByProducerID = deletePhotosByProducerID;
        this.uploadPhotos = uploadPhotos;
        this.updatePhoto = updatePhoto;

        this.getCDNSecret = getCDNSecret;
        this.putCDNSecret = putCDNSecret;
        this.updateCDNSecret = updateCDNSecret;

        this.getS3FileDataUrl = getS3FileDataUrl;

        this.getOrderByOrderNumber = getOrderByOrderNumber;
        this.getOrdersUnfinishedByMidAndPtypeAndPsku = getOrdersUnfinishedByMidAndPtypeAndPsku;
        this.getOrdersPaidByMid = getOrdersPaidByMid;
        this.putOrder = putOrder;
        this.updateOrder = updateOrder;
        this.deleteOrderByOid = deleteOrderByOid;

        this.getPayments = getPayments;
        this.putPayment = putPayment;
        this.updatePayment = updatePayment;

        this.putInvoice = putInvoice;
        this.updateInvoice = updateInvoice;

        this.getKuPaySha256HashKey = getKuPaySha256HashKey;
        this.kuPayOrder = kuPayOrder;
        this.kuPayQuery = kuPayQuery;
        this.kuPayCancel = kuPayCancel;
        

        this.s3SetBucketAllUsersWritable = s3SetBucketAllUsersWritable;
        this.s3SetFilePublic = s3SetFilePublic;
        this.s3PutFile = s3PutFile;
        this.s3PutPublicFile = s3PutPublicFile;
        this.s3GetFile = s3GetFile;
        this.s3DeleteFile = s3DeleteFile;
        this.s3GetFileAsPng = s3GetFileAsPng;
        this.s3GetFileAsMp4 = s3GetFileAsMp4;
        this.s3PartUploadBufferAsync = s3PartUploadBufferAsync;
        this.s3MultiUploadAsync = s3MultiUploadAsync;
        this.s3CopyFile = s3CopyFile;
        this.s3CopyPublicFile = s3CopyPublicFile;
        this.s3DeleteTempFiles = s3DeleteTempFiles;


        this.transactWriteItems = transactWriteItems;
        this.getFirstItem = getFirstItem;
        this.getLastItem = getLastItem;
        this.putItem = putItem;
        this.putItem_V2 = putItem_V2;
        this.updateItem = updateItem;
        this.deleteItem = deleteItem;
        this.queryItems = queryItems;


        this.dateToUTCString = dateToUTCString;
        this.dateToLocalString = dateToLocalString;
        this.utcStringToDate = utcStringToDate;
        this.localStringToDate = localStringToDate;

        this.getUrlWithToken = getUrlWithToken;
        this.createTokenWithURI = createTokenWithURI;
        this.createTokenWithPath = createTokenWithPath;





    }
}

const DataMethods = new DataTools();

export default DataMethods;
import React from 'react'

function fthetypes(props) {
	return (
		<>
			<div className="fthetypes">
				<div className="wrap">
					<div className="ftitle wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1s">
						<h2>適合這個平台的作家型態如下</h2>
						<h6>The types of writers suitable for this platform are as follows</h6>
						<img src={require("assets/LandingPage/images/fico3.png").default} alt=""/>
					</div>
					<div className="fthepesw wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
						<ul className="swiper-wrapper">
							<li className="swiper-slide"
							 	style={{backgroundImage: `url(${require("assets/LandingPage/images/01_Cards.jpg").default})`,backgroundRepeat:`no-repeat`,backgroundOrigin:`center`,backgroundSize:`cover`}}>
							</li>
							<li className="swiper-slide"
							 	style={{backgroundImage: `url(${require("assets/LandingPage/images/02_Cards.jpg").default})`,backgroundRepeat:`no-repeat`,backgroundOrigin:`center`,backgroundSize:`cover`}}>
							</li>
							<li className="swiper-slide"
							 	style={{backgroundImage: `url(${require("assets/LandingPage/images/03_Cards.jpg").default})`,backgroundRepeat:`no-repeat`,backgroundOrigin:`center`,backgroundSize:`cover`}}>
							</li>
							<li className="swiper-slide"
							 	style={{backgroundImage: `url(${require("assets/LandingPage/images/04_Cards.jpg").default})`,backgroundRepeat:`no-repeat`,backgroundOrigin:`center`,backgroundSize:`cover`}}>
							</li>
							<li className="swiper-slide"
							 	style={{backgroundImage: `url(${require("assets/LandingPage/images/05_Cards.jpg").default})`,backgroundRepeat:`no-repeat`,backgroundOrigin:`center`,backgroundSize:`cover`}}>
							</li>
							<li className="swiper-slide"
							 	style={{backgroundImage: `url(${require("assets/LandingPage/images/06_Cards.jpg").default})`,backgroundRepeat:`no-repeat`,backgroundOrigin:`center`,backgroundSize:`cover`}}>
							</li>
						</ul>
						<div className="swspan"></div>
					</div>
				</div>
			</div>
		</>
	)
}
export default fthetypes;
import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

async function putVideoFile(videoFileEntity) {
    
    let result;
    let oldEntity = videoFileEntity;
    if (videoFileEntity) {

        videoFileEntity.Type = "VideoFileInfo";

        if (videoFileEntity.SK) {
            if (videoFileEntity.SK.length > 0) {
                // do nothing
            } else {
                if (videoFileEntity.ProductSKU){
                    if (videoFileEntity.ProductSKU.length > 0) {
                        videoFileEntity.SK = "VFile#"+videoFileEntity.ProductSKU;
                    }
                }
            }
        } else {
            if (videoFileEntity.ProductSKU){
                if (videoFileEntity.ProductSKU.length > 0) {
                    videoFileEntity.SK = "VFile#"+videoFileEntity.ProductSKU;
                }
            }
        }

        if (videoFileEntity.PK && videoFileEntity.SK && videoFileEntity.ProductSKU) {
            if (videoFileEntity.PK.length > 0 && videoFileEntity.SK.length > 0 && videoFileEntity.ProductSKU.length > 0) {
                
                result = await putItem(videoFileEntity);
            }
        }
    }
    if (result) {
        return videoFileEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putVideoFile;
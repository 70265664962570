import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

// {
//     "PK": "P#coreytu",
//     "SK": "IDV#coreytu",
//     "TYPE": "P_PrivacyIDV",
//     "birthday": "1978/8/12",
//     "email": "coreytu@gmail.com",
//     "fullname": "杜世鵬",
//     "gender": "M",
//     "phone": "+886935161290",
//     "SID": "F124445678"
//   }

async function putProducerProfileIDV(producerProfileIDVEntity) {
    
    let result;
    let oldEntity = producerProfileIDVEntity;
    if (producerProfileIDVEntity) {

        producerProfileIDVEntity.TYPE = "P_PrivacyIDV";

        if (producerProfileIDVEntity.PK && producerProfileIDVEntity.SK) {
            if (producerProfileIDVEntity.PK.length > 0 && producerProfileIDVEntity.SK.length > 0) {
                
                result = await putItem(producerProfileIDVEntity);
            }
        }
    }
    if (result) {
        return producerProfileIDVEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putProducerProfileIDV;
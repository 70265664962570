import kvnConfig from "./../views/KeyValueNameConfig.js";

function byteArrayEncodeToBase64(byteArray) {
  const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  let output = "";
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  let i = 0;
 
  while (i < byteArray.length) {
    chr1 = byteArray[i++];
    chr2 = i < byteArray.length ? byteArray[i++] : Number.NaN;
    chr3 = i < byteArray.length ? byteArray[i++] : Number.NaN;
 
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
 
    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + 
              keyStr.charAt(enc3) + keyStr.charAt(enc4);
  }
  return output;
}

async function getS3FileDataUrl(fileType, bucketName, pathAndKey, cdnEnable, cdnTokenEnable, cdnSecret, tokenExpireMillis) {
    if (cdnEnable) {
      if (cdnTokenEnable) {
        if (pathAndKey && cdnSecret && tokenExpireMillis) {
          if (pathAndKey.length > 0 && cdnSecret.length > 0 && tokenExpireMillis > 0) {
            const uri = "/"+pathAndKey;
            const expireDate = tokenExpireMillis ? (new Date((new Date()).getTime() + tokenExpireMillis)):(new Date((new Date()).getTime() + 1000 * 60 * 60 * 6));
            const expire = Math.floor(expireDate.getTime()/1000);
            
            // createTokenWithURI
            let st = "";
            if (cdnSecret) {
                st = st + cdnSecret;
            }
            if (expire) {
                st = st + expire;
            }
            if (uri) {
                st = st + uri;
            }
            
            const crypto = require('crypto');
            const hash = crypto.createHash('md5');
            hash.update(st); // hash.update(st, 'utf-8');
            const buffer = hash.digest();
            const b64Str = buffer.toString('base64');
            const token = b64Str.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
            // createTokenWithURI
            
            return "https://s3-sunwai.cdn.hinet.net"+uri+"?token="+token+"&expire="+expire;
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "https://s3-sunwai.cdn.hinet.net/" + pathAndKey;
      }
    } else {
  
      const AWS = require('aws-sdk');
      AWS.config.update({
          region: kvnConfig.awsRegion,
          accessKeyId: kvnConfig.adminAccessKeyId,
          secretAccessKey: kvnConfig.adminSecretAccessKey
      });
      const s3 = new AWS.S3({apiVersion: '2006-03-01'});
  
      const s3GetFile = async (BucketName, PathAndKey) => {
        return new Promise((resolve, reject) => {
          const params = {
            Bucket: BucketName ? BucketName:"",
            Key: PathAndKey ? PathAndKey:""
          }
          s3.getObject(params, (getErr, getData) => {
            if (getErr) {
              reject(getErr);
            } else {
              resolve(getData);
            }
          });
        });
      };
  
      const s3GetFileAsPng = async (BucketName, PathAndKey) => {
        return new Promise((resolve, reject) => {
          s3GetFile(BucketName, PathAndKey)
          .then((res) => {
              let base64 = "";
              if (res) {
                  const resultBody = res.Body;
                  if (resultBody) {
                      const bufferArray = JSON.parse(JSON.stringify(resultBody)).data;
                      if (bufferArray) {
                        base64 = "data:image/png;base64," + byteArrayEncodeToBase64(bufferArray);
                      }
                  }
              }
              resolve(base64);
          })
          .catch((err) => {
              resolve("");
          });
        });
      };
    
      const s3GetFileAsMp4 = async (BucketName, PathAndKey) => {
        return new Promise((resolve, reject) => {
          s3GetFile(BucketName, PathAndKey)
          .then((res) => {
              let base64 = "";
              if (res) {
                  const resultBody = res.Body;
                  if (resultBody) {
                      const bufferArray = JSON.parse(JSON.stringify(resultBody)).data;
                      if (bufferArray) {
                        base64 = "data:video/mp4;base64," + byteArrayEncodeToBase64(bufferArray);
                      }
                  }
              }
              resolve(base64);
          })
          .catch((err) => {
              resolve("");
          });
        });
      };
  
      if (fileType === 'mp4') {
        const url = await s3GetFileAsMp4(bucketName, pathAndKey).catch();
        if (url) {
          return url;
        } else {
          return "";
        }
      } else if (fileType === 'png') {
        const url = await s3GetFileAsPng(bucketName, pathAndKey).catch();
        if (url) {
          return url;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
}
  
export default getS3FileDataUrl;
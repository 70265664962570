class ProducerProfileCOMPEntity {
    
    constructor(ProducerID) {
        this.PK = ProducerID ? ("P#"+ProducerID):"";
        this.SK = ProducerID ? ("COMP#"+ProducerID):"";
        this.TYPE = "P_PrivacyCOMP";
        // this.fullname = "";
        // this.phone = "";
        // this.email = "";
        // this.SID = "";
        // this.reg_name = "";
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK
        };
        if (this.TYPE) {
            obj["TYPE"] = this.TYPE;
        }
        if (this.reg_name) {
            obj["reg_name"] = this.reg_name;
        }
        if (this.email) {
            obj["email"] = this.email;
        }
        if (this.fullname) {
            obj["fullname"] = this.fullname;
        }
        if (this.phone) {
            obj["phone"] = this.phone;
        }
        if (this.SID) {
            obj["SID"] = this.SID;
        }
        return obj;
    }
}

const producerProfileCOMPEntityFromObject = (item) => {
    let entity = new ProducerProfileCOMPEntity("");
    entity.PK = item.PK;
    entity.SK = item.SK;
    if (item.TYPE) {
        entity.TYPE = item.TYPE;
    }
    if (item.reg_name) {
        entity.reg_name = item.reg_name;
    }
    if (item.email) {
        entity.email = item.email;
    }
    if (item.fullname) {
        entity.fullname = item.fullname;
    }
    if (item.phone) {
        entity.phone = item.phone;
    }
    if (item.SID) {
        entity.SID = item.SID;
    }
    return entity;
}

export { ProducerProfileCOMPEntity, producerProfileCOMPEntityFromObject };

// {
//     "email": "ginady@gmail.com",
//     "TYPE": "P_PrivacyComp",
//     "fullname": "",
//     "phone": "111",
//     "PK": "P#test",
//     "reg_name": "",
//     "SID": "",
//     "SK": "COMP#test"
//   }
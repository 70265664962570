import { videoFileChapterEntityFromObject } from './VideoFileChapterEntity.js';

class VideoFileEntity {
    
    constructor(ProducerID, ProductType, ProductSKU) {
        if (ProducerID) {
            if (ProducerID.length > 0) {
                this.PK = "VP#"+ProducerID;
            } else {
                this.PK = "";
            }
        } else {
            this.PK = "";
        }
        if (ProductSKU) {
            if (ProductSKU.length > 0) {
                this.SK = "VFile#"+ProductSKU;
                this.ProductSKU = ProductSKU;
            } else {
                this.SK = "";
                this.ProductSKU = "";
            }
        } else {
            this.SK = "";
            this.ProductSKU = "";
        }
        if (ProductType) {
            if (ProductType.length > 0) {
                this.ProductType = ProductType;
            } else {
                this.ProductType = "";
            }
        } else {
            this.ProductType = "";
        }
        this.Type = "VideoFileInfo";
    }

    changeProductTypeSKU(ProductType, ProductSKU) {
        if (ProductSKU) {
            if (ProductSKU.length > 0) {
                this.SK = "VFile#"+ProductSKU;
                this.ProductSKU = ProductSKU;
            } else {
                this.SK = "";
                this.ProductSKU = "";
            }
        } else {
            this.SK = "";
            this.ProductSKU = "";
        }
        if (ProductType) {
            if (ProductType.length > 0) {
                this.ProductType = ProductType;
            } else {
                this.ProductType = "";
            }
        } else {
            this.ProductType = "";
        }
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK,
            "Type": "VideoFileInfo",
            "ProductType": this.ProductType,
            "ProductSKU": this.ProductSKU
        };
        if (this.VPName) {
            obj["VPName"] = this.VPName;
        }
        if (this.CreateAt) {
            obj["CreateAt"] = this.CreateAt;
        }
        if (this.CreateBy) {
            obj["CreateBy"] = this.CreateBy;
        }
        if (this.UpdateAt) {
            obj["UpdateAt"] = this.UpdateAt;
        }
        if (this.UpdateBy) {
            obj["UpdateBy"] = this.UpdateBy;
        }
        if (this.Chapters) {
            obj["Chapters"] = [];
            for(let i=0;i<this.Chapters.length;i++) {
                this.Chapters[i].ChapterNumber = i + 1;
                let chapterObj = this.Chapters[i].toObject();
                if (chapterObj) {
                    obj["Chapters"].push(chapterObj);
                }
            }
        }

        return obj;
    }

    appendUnit(chapterEntity) {
        this.Chapters.push(chapterEntity);
        for(let i=0;i<this.Chapters.length;i++) {
            this.Chapters[i].ChapterNumber = i + 1;
        }
    }

    insertUnit(chapterEntity, atIndex) {
        this.Chapters.splice(atIndex, 0, chapterEntity);
        for(let i=0;i<this.Chapters.length;i++) {
            this.Chapters[i].ChapterNumber = i + 1;
        }
    }

    removeUnit(atIndex) {
        this.Chapters.splice(atIndex, 1);
        for(let i=0;i<this.Chapters.length;i++) {
            this.Chapters[i].ChapterNumber = i + 1;
        }
    }
}

const videoFileEntityFromObject = (item) => {
    let entity = new VideoFileEntity("", "", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    entity.Type = "VideoFileInfo";
    entity.ProductType = item.ProductType;
    entity.ProductSKU = item.ProductSKU
    if (item.VPName) {
        entity.VPName = item.VPName;
    }
    if (item.CreateAt) {
        entity.CreateAt = item.CreateAt;
    }
    if (item.CreateBy) {
        entity.CreateBy = item.CreateBy;
    }
    if (item.UpdateAt) {
        entity.UpdateAt = item.UpdateAt;
    }
    if (item.UpdateBy) {
        entity.UpdateBy = item.UpdateBy;
    }
    if (item.Chapters) {
        entity.Chapters = [];
        for(let i=0;i<item.Chapters.length;i++) {
            item.Chapters[i].ChapterNumber = i + 1;
            const chapterEnt = videoFileChapterEntityFromObject(item.Chapters[i]);
            entity.Chapters.push(chapterEnt);
        }
    }
    
    return entity;
}

export { VideoFileEntity, videoFileEntityFromObject };

// {
//     "PK": "VP#ProducerID",
//     "SK": "VFile#VideoPID",
//     "Type": "VideoFileInfo",
//     "ProductType": "VPID",
//     "ProductSKU": "",
//     "VPName": "",
//     "CreateAt": "",
//     "CreateBy": "",
//     "UpdateAt": "",
//     "UpdateBy": "",
//     "Chapters": [
//         {
//             "ChapterNumber": 1,
//             "ChapterTitle": "",
//             "ChapterBrief": "",
//             "Sections": [
//                 {
//                     "SectionNumber": 1,
//                     "SectionTitle": "",
//                     "SectionBrief": "",
//                     "Units": [
//                         {
//                             "UnitNumber": 1,
//                             "UnitTitle": "",
//                             "UnitBrief": "",
//                             "UnitBucketName": "",
//                             "UnitPathAndKey": "",
//                             "UnitFileFormat": "mp4",
//                             "UnitFileLengthSec": 0,
//                             "UnitFileSizeByte": 0,
//                             "UnitFileBitrateBps": 0
//                         }
//                     ]
//                 }
//             ]
//         }
//     ]
// }

// chapter(章) => section(節) => subsection(款) => article(條)
// chapter(章) => section(節) => subsection(款) => unit(單元)
import React from "react";

function hPage6(props) {
	return (
		<>
			<div className="hpage6">
				<div className="wrap">
					<div className="tit wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
						<h2><span>絕不能錯過的必修課程！</span> Why？</h2>
						<p>久等了！我們知道，您夢想中的立裁課程就該如此</p>
					</div>
					<ul className="clearfix">
						<li className="wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="1s">
							<div className="ico">
								<img src={require("assets/FashionDraping/images/qm25.png").default} alt="" />
							</div>
							<h3>重磅級講師<br />
								親自用心授課</h3>
							<h6>打版業界推崇資深講師傾心相授</h6>
						</li>
						<li className="wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1s">
							<div className="ico">
								<img src={require("assets/FashionDraping/images/qm26.png").default} alt="" />
							</div>
							<h3>難得一遇的<br />
								純立裁專業課程</h3>
							<h6>時尚設計課程中罕有的純立裁課程</h6>
						</li>
						<li className="wow fadeInUp" data-wow-delay="0.8s" data-wow-duration="1s">
							<div className="ico">
								<img src={require("assets/FashionDraping/images/qm27.png").default} alt="" />
							</div>
							<h3>洋裝立裁打版課為<br />
								自創工作室的捷徑</h3>
							<h6>本堂課可教你變化出成千上百件單品</h6>
						</li>
						<li className="wow fadeInUp" data-wow-delay="0.9s" data-wow-duration="1s">
							<div className="ico">
								<img src={require("assets/FashionDraping/images/qm28.png").default} alt="" />
							</div>
							<h3>跳脫框架的<br />
								沉浸式線上教學</h3>
							<h6>以學員為中心更勝實體教學模式</h6>
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default hPage6;
import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

async function putVideoProfile(videoProfileEntity) {
    
    let result;
    let oldEntity = videoProfileEntity;
    if (videoProfileEntity) {

        videoProfileEntity.Type = "ContentInfo";

        if (videoProfileEntity.SK) {
            if (videoProfileEntity.SK.length > 0) {
                // do nothing
            } else {
                if (videoProfileEntity.ProductSKU){
                    if (videoProfileEntity.ProductSKU.length > 0) {
                        videoProfileEntity.SK = "VProfile#"+videoProfileEntity.ProductSKU;
                    }
                }
            }
        } else {
            if (videoProfileEntity.ProductSKU){
                if (videoProfileEntity.ProductSKU.length > 0) {
                    videoProfileEntity.SK = "VProfile#"+videoProfileEntity.ProductSKU;
                }
            }
        }

        if (videoProfileEntity.PK && videoProfileEntity.SK && videoProfileEntity.ProductSKU) {
            if (videoProfileEntity.PK.length > 0 && videoProfileEntity.SK.length > 0 && videoProfileEntity.ProductSKU.length > 0) {
                
                result = await putItem(videoProfileEntity);
            }
        }
    }
    if (result) {
        return videoProfileEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putVideoProfile;
import { deleteItem } from "./DynamodbMethods.js";

async function deleteVideoProductionByPkAndVid(PK, ProductSKUString) {
    let deleteRes;
    if (PK && ProductSKUString) {
        if (PK.length > 0 && ProductSKUString.length > 0) {
            deleteRes = await deleteItem(PK, "VProduction#"+ProductSKUString);
        }
    }
    return deleteRes;
}
export default deleteVideoProductionByPkAndVid;
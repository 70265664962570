class VideoPAPEntity {
    
    constructor(ProductType, ProductSKU, submitDateTime) {
        this.PK = "VPAP";
        this.SK = ProductType+"#"+ProductSKU+"-"+submitDateTime;
        this.ProductType = ProductType ? ProductType:"";
        this.ProductSKU = ProductSKU ? ProductSKU:"";
        this.submitDateTime = submitDateTime ? submitDateTime:"";
        // this.ProducerID = "";
        // this.finishFlag = false;
        // this.approval = false;
        // this.S3_RecordPath = "";
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK,
            "ProductType": this.ProductType,
            "ProductSKU": this.ProductSKU
        };
        if (this.submitDateTime) {
            obj["submitDateTime"] = this.submitDateTime;
        }
        if (this.ProducerID) {
            obj["ProducerID"] = this.ProducerID;
        }
        if (this.finishFlag !== undefined && this.finishFlag !== null) {
            if (this.finishFlag) {
                obj["finishFlag"] = true;
            } else {
                obj["finishFlag"] = false;
            }
        }
        if (this.approval !== undefined && this.approval !== null) {
            if (this.approval) {
                obj["approval"] = true;
            } else {
                obj["approval"] = false;
            }
        }
        if (this.S3_RecordPath) {
            obj["S3_RecordPath"] = this.S3_RecordPath;
        }
        return obj;
    }
}

const videoPAPEntityFromObject = (item) => {
    let entity = new VideoPAPEntity("", "", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    entity.ProductType = item.ProductType;
    entity.ProductSKU = item.ProductSKU;
    if (item.submitDateTime) {
        entity.submitDateTime = item.submitDateTime;
    }
    if (item.ProducerID) {
        entity.ProducerID = item.ProducerID;
    }
    if (item.finishFlag !== undefined && item.finishFlag !== null) {
        if (item.finishFlag) {
            entity.finishFlag = true;
        } else {
            entity.finishFlag = false;
        }
    }
    if (item.approval !== undefined && item.approval !== null) {
        if (item.approval) {
            entity.approval = true;
        } else {
            entity.approval = false;
        }
    }
    if (item.S3_RecordPath) {
        entity.S3_RecordPath = item.S3_RecordPath;
    }
    return entity;
}

export { VideoPAPEntity, videoPAPEntityFromObject };

// {
//     "PK":"VPAP",
//     "SK":"VPID#vpid-submitDateTime",
//     "ProducerID":"coreytu",
//     "ProductType": "VPID",
//     "ProductSKU": "coreytu01",
//     "submitDateTime":"",
//     "finishFlag":false,
//     "approval":false,
//     "S3_RecordPath":"01.jpg,02.jpg"
//   }
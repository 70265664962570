import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import LineSdkInstance from "./../data/LineSdkInstance.js";
import kvnConfig from "./KeyValueNameConfig.js";
import DataMethods from "./../data/DataMethods.js";
import ENT from './../entities/DataEntity.js';

// core components

function UserLogin(props) {
  console.log("UserLogin");

  const lineSdkIns = new LineSdkInstance(kvnConfig.lineChannelID, kvnConfig.lineChannelSecret, kvnConfig.stateSend, kvnConfig.nonceSend, kvnConfig.redirectUri);
  let LineAccessToken = "";
  if (LineAccessToken.length === 0) {
    if (props) {
      if (props.LineAccessToken) {
        if (props.LineAccessToken.length > 0) {
          LineAccessToken = props.LineAccessToken;
        }
      }
    }
  }
  if (LineAccessToken.length === 0) {
    if (props.location) {
      if (props.location.state) {
        if (props.location.state.lineAccessToken) {
          if (props.location.state.lineAccessToken.length > 0) {
            LineAccessToken = props.location.state.lineAccessToken;
          }
        }
      }
    }
  }
  if (LineAccessToken.length === 0) {
    const temp = window.localStorage.getItem("lineAccessToken");
    if (temp) {
      if (temp.length > 0) {
        LineAccessToken = temp;
      }
    }
  }

  let LineUserID = "";
  if (LineUserID.length === 0) {
    const temp = window.localStorage.getItem('lineIdForTest');
    if (temp) {
      if (temp.length > 0) {
        LineUserID = temp;
      }
    }
  }
  if (LineUserID.length === 0) {
    if (props) {
      if (props.LineUserID) {
        if (props.LineUserID.length > 0) {
          LineUserID = props.LineUserID;
        }
      }
    }
  }
  if (LineUserID.length === 0) {
    if (props.location) {
      if (props.location.state) {
        if (props.location.state.lineUserId) {
          if (props.location.state.lineUserId.length > 0) {
            LineUserID = props.location.state.lineUserId;
          }
        }
      }
    }
  }
  if (LineUserID.length === 0) {
    const temp = window.localStorage.getItem("lineUserId");
    if (temp) {
      if (temp.length > 0) {
        LineUserID = temp;
      }
    }
  }

  let MemberID = "";
  if (MemberID.length === 0) {
    const temp = window.localStorage.getItem('memberIdForTest');
    if (temp) {
      if (temp.length > 0) {
        MemberID = temp;
      }
    }
  }
  if (MemberID.length === 0) {
    if (props) {
      if (props.MemberID) {
        if (props.MemberID.length > 0) {
          MemberID = props.MemberID;
        }
      }
    }
  }
  if (MemberID.length === 0) {
    if (props.location) {
      if (props.location.state) {
        if (props.location.state.memberId) {
          if (props.location.state.memberId.length > 0) {
            MemberID = props.location.state.memberId;
          }
        }
      }
    }
  }
  if (MemberID.length === 0) {
    const temp = window.localStorage.getItem("memberId");
    if (temp) {
      if (temp.length > 0) {
        MemberID = temp;
      }
    }
  }

  React.useEffect(() => {

    if (LineAccessToken && MemberID) {
      if (LineAccessToken.length > 0 && MemberID.length > 0) {
        window.location.href = "/";
 
      } else {
        if (LineAccessToken && LineUserID) {
          if (LineAccessToken.length > 0 && LineUserID.length > 0) {
            window.location.href = "/MemberSignUp";
          }
        }
      }
    } else {
      if (LineAccessToken && LineUserID) {
        if (LineAccessToken.length > 0 && LineUserID.length > 0) {
          window.location.href = "/MemberSignUp";
        }
      }
    }

  }, [LineAccessToken, MemberID, LineUserID]);

  React.useEffect(() => {

    // 網頁標題列
    document.title = "會員登入"
    const favicon = document.querySelector('[rel=icon]');
    favicon.href = require("assets/img/apple-icon.png").default;

  }, []);
  
  return (
    <>
      <div className="page-header header-filter" filter-color="blue" style={{ paddingBottom: 60 }}>
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/login.jpg").default + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-login card-plain">
                  <Form action="" className="form" method="">
                    <CardHeader className="text-center">
                      <div className="logo-container">
                        <img
                          alt="..."
                          src={require("assets/img/apple-icon.png").default}
                        ></img>
                      </div>
                    </CardHeader>
                    <CardBody>

                      <Button onClick={(e) => {
                        
                        // for test
                        // const redirectPath = window.localStorage.getItem("redirectPath");
                        // const lineAccessToken = "lineAccessTokenForTest";
                        // const lineUserId = "Ueefcced91908cbced1944adcb3aef435";
                        // const lineDisplayName = "coreytu";
                        // const memberId = "coreytu";
                        // window.localStorage.setItem('lineAccessToken', lineAccessToken);
                        // window.localStorage.setItem('lineIdForTest', lineUserId); // coreytu
                        // window.localStorage.setItem('lineDisplayNameForTest', lineDisplayName); // coreytu
                        // window.localStorage.setItem('memberIdForTest', memberId); // coreytu
                        // if (redirectPath) {
                        //   window.location.href = redirectPath;
                        // } else {
                        //   window.location.href = "/";
                        // }


                        // for publish
                        window.localStorage.removeItem("lineAccessToken");
                        window.localStorage.removeItem("lineUserId");
                        window.localStorage.removeItem("lineDisplayName");
                        window.localStorage.removeItem("memberId");
                        window.localStorage.removeItem("lineIdForTest");
                        window.localStorage.removeItem("lineDisplayNameForTest");
                        window.localStorage.removeItem("memberIdForTest");
                        lineSdkIns.requestLineAuthCode();

                      }} style={{ backgroundColor:"#00c300", borderColor:"#00c300", color:"#fff", borderRadius:"0.2rem", fontSize:"1em", fontWeight:"400", boxShadow:"none", paddingTop:"10px", paddingBottom:"10px", paddingLeft:"16px", paddingRight:"20px" }}>
                        <i className="fab fa-line" style={{ paddingRight:"8px" }} />LINE 登入
                      </Button>
                      
                        {/* <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={(e) => {
                            window.location.href = "/MemberSignUp";
                          }}
                        >
                          <CardTitle tag="h5">註冊帳號</CardTitle>
                        </a> */}

                    </CardBody>
                    <CardFooter className="text-center">
                      
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default UserLogin;
import React from "react";

function hPage7(props) {
	return (
		<>
			<div className="hpage7">
				<div className="wrap">
					<div className="tit wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1s">
						<h2>這堂課適合什麼族群的您！？</h2>
						<p>提供不同領域、程度學員所需的服裝製作課程，滿足您對立裁打版的學習需求</p>
					</div>
					<ul className="wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
						<li>
							<div className="ts">
								<h3><i>零</i>基礎</h3>
								<h6>對學習服裝製作有興趣<br />
									的0基礎新手</h6>
							</div>
						</li>
						<li>
							<div className="ts">
								<h3><i>相</i>關科系</h3>
								<h6>想再精進打版實力的 <br />
									服裝相關科系學生或工作者</h6>
							</div>
						</li>
						<li>
							<div className="ts">
								<h3><i>創</i>業者</h3>
								<h6>透過原創設計建立自創<br />
									服飾品牌的創業者</h6>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default hPage7;
import kvnConfig from "./../views/KeyValueNameConfig.js";
import DataMethods from "./DataMethods.js";

const AWS = require('aws-sdk');
AWS.config.update({
    region: kvnConfig.awsRegion,
    accessKeyId: kvnConfig.adminAccessKeyId,
    secretAccessKey: kvnConfig.adminSecretAccessKey
});
const s3 = new AWS.S3({apiVersion: '2006-03-01'});
const docClient = new AWS.DynamoDB.DocumentClient();

function captureVideoDuration(videoSrc) {

  return new Promise((resolve, reject) => {
      const temp = document.getElementById("captureVideoDurationTemp");
      if (temp) {
        if (temp.parentNode) {
          temp.parentNode.removeChild(temp);
        }
      }

      const video = document.createElement('video'); //  創建video 用來存放被截圖的影片
      video.setAttribute('id', 'captureVideoDurationTemp');
      video.setAttribute('crossOrigin', 'anonymous'); //  支援跨域
      document.body.appendChild(video); //  把影片插入頁面里
      video.setAttribute('src', videoSrc); //  設置video路徑
      video.style.visibility = "hidden" //  影片不顯示

      video.onerror = (e) => {
        if (video) {
          if (video.parentNode) {
              video.parentNode.removeChild(video);
          }
        }
        resolve(0);
      };

      video.onloadeddata = () => {
        let duration = 0;
        if (video) {
          duration = Math.floor(video.duration);
          if (video.parentNode) {
            video.parentNode.removeChild(video);
          }
        }
        resolve(duration);
      };
  });
}

async function uploadFilms(ProducerID, files, someProgressCallback, someCompleteCallback) {
  let fileJsonArray = [];

  if (files) {
    for(let i=0;i<files.length;i++) {

      const fileObj = files[i];
      const fileTempName = fileObj.name;
      const fileMimeType = fileObj.type;
      const fileByteSize = fileObj.size;
      let fileSubName = "";

      const tempNames = fileMimeType.split("/");
      if (tempNames) {
        if (tempNames.length > 0) {
          if (tempNames[tempNames.length - 1]) {
            if (tempNames[tempNames.length - 1].length > 0) {
              fileSubName = tempNames[tempNames.length - 1];
            }
          }
        }
      }

      let fileMainName = "";
      const tempIndex = fileTempName.lastIndexOf(".");
      if (tempIndex > 0) {
        fileMainName = fileTempName.substring(0, tempIndex);
        if (fileSubName.length === 0) {
          if ((tempIndex + 1) < fileTempName.length) { // 確認 tempIndex+1 在範圍內
            const tempSub = fileTempName.substring(tempIndex + 1, fileTempName.length);
            if (tempSub.length > 0) {
              fileSubName = tempSub;
            }
          }
        }
      }

      const timestamp = (new Date()).getTime();
      const BucketName = kvnConfig.s3MainBucketName;
      const PathAndKey = "Film/"+ProducerID+"-"+timestamp+"."+fileSubName;

      const putEntityPromise = new Promise((resolve, reject) => {
        
        const params = {
          TableName : kvnConfig.dbMainTableName,
          Item: {
            PK: "Films",
            SK: ProducerID+"-"+timestamp,
            ProducerID: ProducerID,
            ExtensionName: fileSubName,
            BucketName: BucketName,
            PathAndKey: PathAndKey,
            MimeType: fileMimeType,
            SizeBytes: fileByteSize,
            DisplayName: fileMainName
          }
        };
        docClient.put(params, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
      });

      await putEntityPromise;
      
      const putFilePromise = new Promise((resolve, reject) => {
        const params = {
            Bucket: BucketName ? BucketName:"",
            Key: PathAndKey ? PathAndKey:"",
            Body: fileObj
        }
        s3.putObject(params, (putErr, putData) => {
            if (putErr) {
                reject(putErr);
            } else {
                resolve(putData);
            }
        })
        .on('httpUploadProgress', (progress) => {
            if (someProgressCallback) {
              someProgressCallback(i, progress);
            }
        });
      });
      
      await putFilePromise;
      
      let fileSecLength = 0;
      const cdnRes = await DataMethods.getCDNSecret();
      if (cdnRes) {
        if (cdnRes.Secret) {

          const secret = cdnRes.Secret;
          const sixHoursDate = new Date((new Date()).setHours((new Date()).getHours()+6));
          const expire = Math.floor(sixHoursDate.getTime()/1000);
          const cdnEnable = ((window.localStorage.getItem('hinetCDN') === 'off') !== true);
          const videoUrl = await DataMethods.getS3FileDataUrl('mp4', kvnConfig.s3MainBucketName, PathAndKey, cdnEnable, true, secret, expire);
          const tempLength = await captureVideoDuration(videoUrl);
          if (tempLength) {
            fileSecLength = tempLength;
          }
        }
      }

      const updateLengthPromise = new Promise((resolve, reject) => {
        
        const params = {
          TableName : kvnConfig.dbMainTableName,
          Key: {
            PK: "Films",
            SK: ProducerID+"-"+timestamp
          },
          ExpressionAttributeNames: {
            "#keyAlias0": "LengthSec"
          },
          ExpressionAttributeValues: {
            ":valueAlias0": fileSecLength
          },
          UpdateExpression: "set #keyAlias0 = :valueAlias0",
          ReturnValues:"ALL_NEW",
        };
        
        docClient.update(params, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
      });

      await updateLengthPromise;

      const fileJson = {
        PK: "Films",
        SK: ProducerID+"-"+timestamp,
        ProducerID: ProducerID,
        ExtensionName: fileSubName,
        BucketName: BucketName,
        PathAndKey: PathAndKey,
        MimeType: fileMimeType,
        SizeBytes: fileByteSize,
        LengthSec: fileSecLength,
        DisplayName: fileMainName
      };
      
      if (someCompleteCallback) {
        someCompleteCallback(i, fileJson);
      }

      fileJsonArray.push(fileJson);
    }
  }

  return fileJsonArray;
}
  
export default uploadFilms;
import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

async function putVideoPAP(videoPAPEntity) {
    
    let result;
    let oldEntity = videoPAPEntity;
    if (videoPAPEntity) {

        videoPAPEntity.PK = "VPAP";

        if (videoPAPEntity.SK) {
            if (videoPAPEntity.SK.length > 0) {
                // do nothing
            } else {
                if (videoPAPEntity.ProductType && videoPAPEntity.ProductSKU && videoPAPEntity.submitDateTime){
                    if (videoPAPEntity.ProductType.length > 0 && videoPAPEntity.ProductSKU.length > 0 && videoPAPEntity.submitDateTime.length > 0) {
                        videoPAPEntity.SK = videoPAPEntity.ProductType+"#"+videoPAPEntity.ProductSKU+"-"+videoPAPEntity.submitDateTime;
                    }
                }
            }
        } else {
            if (videoPAPEntity.ProductType && videoPAPEntity.ProductSKU && videoPAPEntity.submitDateTime){
                if (videoPAPEntity.ProductType.length > 0 && videoPAPEntity.ProductSKU.length > 0 && videoPAPEntity.submitDateTime.length > 0) {
                    videoPAPEntity.SK = videoPAPEntity.ProductType+"#"+videoPAPEntity.ProductSKU+"-"+videoPAPEntity.submitDateTime;
                }
            }
        }


        if (videoPAPEntity.PK && videoPAPEntity.SK && videoPAPEntity.ProductType && videoPAPEntity.ProductSKU && videoPAPEntity.submitDateTime) {
            if (videoPAPEntity.PK.length > 0 && videoPAPEntity.SK.length > 0 && videoPAPEntity.ProductType.length > 0 && videoPAPEntity.ProductSKU.length > 0 && videoPAPEntity.submitDateTime.length > 0) {
                
                result = await putItem(videoPAPEntity);
            }
        }
    }
    if (result) {
        return videoPAPEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putVideoPAP;
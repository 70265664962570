import { updateItem } from "./DynamodbMethods.js";

async function updateVideoFile(videoFileEntity) {
    
    let result;

    if (videoFileEntity) {

        videoFileEntity.Type = "VideoFileInfo";

        if (videoFileEntity.SK) {
            if (videoFileEntity.SK.length > 0) {
                // do nothing
            } else {
                if (videoFileEntity.ProductSKU){
                    if (videoFileEntity.ProductSKU.length > 0) {
                        videoFileEntity.SK = "VFile#"+videoFileEntity.ProductSKU;
                    }
                }
            }
        } else {
            if (videoFileEntity.ProductSKU){
                if (videoFileEntity.ProductSKU.length > 0) {
                    videoFileEntity.SK = "VFile#"+videoFileEntity.ProductSKU;
                }
            }
        }

        if (videoFileEntity.PK && videoFileEntity.SK) {
            if (videoFileEntity.PK.length > 0 && videoFileEntity.SK.length > 0) {
                result = await updateItem(videoFileEntity);
            }
        }
    }
    return result;
}
  
export default updateVideoFile;
import { queryItems } from "./DynamodbMethods.js";

async function getVideoPricesByVid(ProductSKUString) {
    let res;
    if (ProductSKUString) {
        if (ProductSKUString.length > 0) {
            res = await queryItems("VP#"+ProductSKUString, "unitPrice#");
        }
    }
    return res;
}
  
export default getVideoPricesByVid;
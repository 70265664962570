import kvnConfig from "./../views/KeyValueNameConfig.js";
import { docClientQuery } from "./AWSConfig.js";

function getProducerInfoItems() {
    const paramsQuery = {
        TableName : kvnConfig.dbMainTableName,
        ExpressionAttributeNames: {
            "#keyAlias1": "PK"
        },
        ExpressionAttributeValues: {
            ":valueAlias1": "Producers"
        },
        KeyConditionExpression: "#keyAlias1 = :valueAlias1"
    };
    return docClientQuery(paramsQuery);
}
  
export default getProducerInfoItems;
import kvnConfig from "./../views/KeyValueNameConfig.js";
import { docClientQuery } from "./AWSConfig";
import DataMethods from "../data/DataMethods"

async function queryMember(LineAccessId){
    let obj
    if (LineAccessId) {
        if (LineAccessId.length > 0) {
            
            const paramsQuery = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK",
                    "#keyAlias2": "SK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": "Members",
                    ":valueAlias2": `Line#${LineAccessId}`
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1 and #keyAlias2 = :valueAlias2",
            };

            const res = await docClientQuery(paramsQuery);
            if (res.Items.length > 0) {
                obj = res.Items[0];
            }
        }
    }
    return obj;
}

async function putMemberProfile(memberProfileEntity) {
    let result;
    let oldEntity = memberProfileEntity;
    if (memberProfileEntity) {

        memberProfileEntity.PK = "Members";
        memberProfileEntity.Type = "M_Main";
        if (memberProfileEntity.SK) {
            if (memberProfileEntity.SK.length > 0) {
                // do nothing
            } else {
                // if (memberProfileEntity.MemberID){
                //     if (memberProfileEntity.MemberID.length > 0) {
                //         memberProfileEntity.SK = "M#"+memberProfileEntity.MemberID
                //     }
                // }
            }
        } else {
            // if (memberProfileEntity.MemberID){
            //     if (memberProfileEntity.MemberID.length > 0) {
            //         memberProfileEntity.SK = "M#"+memberProfileEntity.MemberID
            //     }
            // }
        }

        if (memberProfileEntity.PK && memberProfileEntity.SK) {
            if (memberProfileEntity.PK.length > 0 && memberProfileEntity.SK.length > 0) {
                result = await DataMethods.putItem_V2(memberProfileEntity);
            }
        }
    }
    if (result) {
        return memberProfileEntity;
    } else {
        return oldEntity;
    }
}

async function queryLineMember(LineAccessId){
    let obj
    if (LineAccessId) {
        if (LineAccessId.length > 0) {
            
            const paramsQuery = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK",
                    "#keyAlias2": "SK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": `Line#${LineAccessId}`,
                    ":valueAlias2": `Line#${LineAccessId}`
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1 and #keyAlias2 = :valueAlias2",
            };

            const res = await docClientQuery(paramsQuery);
            if (res.Items.length > 0) {
                obj = res.Items[0];
            }
        }
    }
    return obj;
}



async function queryFashionMember(LineAccessId){
    let obj
    if (LineAccessId) {
        if (LineAccessId.length > 0) {
            const paramsQuery = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK",
                    "#keyAlias2": "SK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": "FD-Dresses-Basics",
                    ":valueAlias2": `Line#${LineAccessId}`
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1 and #keyAlias2 = :valueAlias2",
            };

            const res = await docClientQuery(paramsQuery);
            if (res.Items.length > 0) {
                obj = res.Items[0];
            }
        }
    }
    return obj;
}

async function queryFashionAll(LineAccessId){
    let obj
    if (LineAccessId) {
        if (LineAccessId.length > 0) {
            
            const paramsQuery = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK",
                    "#keyAlias2": "SK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": `Line#${LineAccessId}`,
                    ":valueAlias2": `VPID#FDDB-ALL`
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1 and #keyAlias2 = :valueAlias2",
            };

            const res = await docClientQuery(paramsQuery);
            if (res.Items.length > 0) {
                obj = res.Items[0];
            }
        }
    }
    return obj;
}

async function queryFashionRegularAll(LineAccessId){
    let obj
    if (LineAccessId) {
        if (LineAccessId.length > 0) {
            
            const paramsQuery = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK",
                    "#keyAlias2": "SK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": `Line#${LineAccessId}`,
                    ":valueAlias2": `VPID#FDDB-ALL-PP`
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1 and #keyAlias2 = :valueAlias2",
            };

            const res = await docClientQuery(paramsQuery);
            if (res.Items.length > 0) {
                obj = res.Items[0];
            }
        }
    }
    return obj;
}

async function queryFashionChapter(Chapter,LineAccessId){
    let obj
    if (LineAccessId) {
        if (LineAccessId.length > 0) {          
            const paramsQuery = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK",
                    "#keyAlias2": "SK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": `Line#${LineAccessId}`,
                    ":valueAlias2": `VPID#${Chapter}`
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1 and #keyAlias2 = :valueAlias2",
            };

            const res = await docClientQuery(paramsQuery);
            if (res.Items.length > 0) {
                obj = res.Items[0];
            }
        }
    }
    return obj;
}

async function putFashionProfile(fashionProfileEntity) {
    let result;
    let oldEntity = fashionProfileEntity;
    if (fashionProfileEntity) {
        fashionProfileEntity.PK = "FD-Dresses-Basics";
        fashionProfileEntity.Type = "VP_BuyList";
        fashionProfileEntity.MemberID =  "M#" + fashionProfileEntity.MemberID
        if (fashionProfileEntity.SK) {
            if (fashionProfileEntity.SK.length > 0) {
                // do nothing
            } else {
                if (fashionProfileEntity.LineID){
                    if (fashionProfileEntity.LineID.length > 0) {
                        fashionProfileEntity.SK = fashionProfileEntity.LineID
                    }
                }
            }
        } else {
            if (fashionProfileEntity.LineID){
                if (fashionProfileEntity.LineID.length > 0) {
                    fashionProfileEntity.SK = fashionProfileEntity.LineID
                }
            }
        }

        if (fashionProfileEntity.PK && fashionProfileEntity.SK) {
            if (fashionProfileEntity.PK.length > 0 && fashionProfileEntity.SK.length > 0) {
                result = await DataMethods.putItem_V2(fashionProfileEntity);
            }
        }
    }
    if (result) {
        return fashionProfileEntity;
    } else {
        return oldEntity;
    }
}
// async function joinMember(PK,SK){
//     const value = getItem(PK,SK)
//     return value
// }

export {queryMember,queryLineMember,queryFashionMember,putMemberProfile,putFashionProfile,queryFashionAll,queryFashionRegularAll,queryFashionChapter}
import React, { useMemo, useEffect, useState, useValue } from 'react'
import "assets/css/policy.css"
import OnlyHeader from "../../components/Headers/OnlyHeader"
import Footer from '../Landing-Sections/footer'
function PolicySections() {
    return (
        <>
            <OnlyHeader></OnlyHeader>
            <header className="policy mb-4 pb-4">
                <div className='container'>
                    <div>
                        {/* <img src={require("assets/FashionDraping/images/videoStore.png").default} alt="" style={{ height: `100%`, minWidth: `117px` }} /> */}
                        <div className="span">
                            <h3>隱私權政策</h3>
                            <h4 id="info-share">1.引言</h4>
                            <ul style={{ margin: `4px 0` }}>

                                <li>1.1  歡迎使用由翔偉資安科技股份有限公司及其關係企業(個別及統稱「Videostores」、「本公司」、「我們」或「我們的」)經營的Videostores平台。Videostores認真看待其依據適用之隱私權及個人資料保護相關法律及規範 (下稱「隱私權法」)下的責任，並致力於尊重Videostores網站及行動應用程式(下稱「本平台」)所有使用者的隱私權和關切點(我們將本平台以及我們在本平台中提供的服務統稱為「本服務」)。向我們註冊帳戶使用本服務之使用者，包含買方及賣方(個別及統稱「使用者」、「您」或「您們」)，我們瞭解您委託給我們的個人資料極為重要，並認為妥善管理、保護及處理您的個人資料是我們的責任。此隱私權政策(下稱「隱私權政策」或「政策」)旨在協助您瞭解我們目前和未來會如何蒐集、使用、揭露及/或處理您提供給我們及/或我們所擁有和您有關的個人資料，以及協助您在向我們提供任何個人資料之前作出明智的決定。請仔細閱讀本隱私權政策。</li>
                                <li>1.2  「個人資料」是指有關個人不論真實與否的資料，該個人可透過該資料，或透過該資料與其他組織持有或可能有權限存取資料，予以識別。個人資料常見的例子包括姓名、身分證號碼和聯絡資訊。</li>
                                <li>1.3  若您使用本服務、向我們註冊帳戶、造訪我們的平台或存取本服務，即表示您知悉並同意接受本隱私權政策概述之作法、要求及/或政策，並特此同意我們蒐集、使用、揭露及/或處理此處所述之個人資料。若您不同意您的個人資料依此隱私權政策所述之方式進行處理，請勿使用我們的服務或存取本平台。如果我們變更隱私權政策，我們會透過包括在本平台上公佈這些變更或是修改過的隱私權政策等方式通知您。我們保留隨時修改本隱私權政策的權利。在適用法律允許範圍內，您繼續使用本服務或本平台之行為，包括訂購商品，應視為瞭解並同意本隱私權政策之修改。</li>
                                <li>1.4  本隱私權政策與其他適用於我們蒐集、儲存、利用、揭露或處理您的個人資料有關的通知、合約條款、同意條款一併適用而非排除適用後者，除非我們另有明示聲明。</li>
                                <li>1.5  本隱私權政策適用於使用本服務的買賣雙方，除非另有明示排除。</li>
                            </ul>

                            <h4 >2.Videostores會在何時蒐集個人資料？</h4>
                            <ul style={{ margin: `4px 0` }}>
                                <li>2.1  我們會/可能會在下列時候蒐集您的個人資料：</li>
                                <ul style={{ paddingLeft: `4px`, margin: ` 0` }}>
                                    <li>(a)  當您註冊及/或使用本服務或本平台，或是向我們開立帳戶時；</li>
                                    <li>(b)  當您透過線上或實體表單方式提交表單，包括但不限於申請表或是與我們的產品或服務相關的其他表單時；</li>
                                    <li>(c)  當您簽定協議，或是針對您與我們的互動提供其他文件或資訊，或是當您使用我們的產品和服務時；</li>
                                    <li>(d)  當您透過電話 (可能會錄音)、信件、傳真、面對面會議、社交媒體平台和電子郵件等方式與我們互動時，包括與我們的客服人員互動時；</li>
                                    <li>(e)  當您使用我們的電子服務，或是透過我們的應用程式或使用我們平台上的服務與我們互動時。這包括但不限於透過 Cookie (可能會在您與我們的應用程式或平台互動時部署)；</li>
                                    <li>(f)  當您在行動裝置上允許分享資訊給我們的應用程式或平台；</li>
                                    <li>(g)  您將您的Videostores帳戶與您的社群媒體帳戶或其他外部帳戶連結或使用其他社群媒體功能時，根據該服務提供者的政策；</li>
                                    <li>(h)  當您透過本服務進行交易時；</li>
                                    <li>(i)  當您提供意見或投訴給我們時；</li>
                                    <li>(j)  當您註冊參加競賽時；</li>
                                    <li>(k)  當您基於任何原因提交個人資料給我們時。</li>
                                </ul>
                                上述情形並非包含全部情形，僅列出可能會蒐集您個人資料的一些常見實例。
                            </ul>
                            <h4 id="mktg-email">3.Videostores會蒐集哪些個人資料？</h4>
                            <ul style={{ margin: `4px 0` }}>
                                <li> 3.1  Videostores會在使用本服務的必要範圍內蒐集使用者的個人資料，包括但不限於：
                                    <ul style={{ paddingLeft: `4px` }}>
                                        <li>•   姓名； </li>
                                        <li>•   電子郵件地址； </li>
                                        <li>•   出生日期； </li>
                                        <li>•   帳單及/或寄送地址； </li>
                                        <li>•   銀行帳戶和付款資訊； </li>
                                        <li>•   電話號碼； </li>
                                        <li>•   性別； </li>
                                        <li>•   與您用來存取本服務或本平台的裝置相關資訊或該裝置所傳送出的資訊； </li>
                                        <li>•   與您的人際網路及與您互動的人及其帳號有關的資訊； </li>
                                        <li>•   您的照片、錄音或影片； </li>
                                        <li>•   基於我們的盡職調查（due diligence）、認識客戶(know your customer)、身分確認或預防詐欺等目的所要求的政府機關核發之證明或其他資訊； </li>
                                        <li>•   行銷及通訊資訊，例如您接收來自我們或第三方行銷資訊的偏好，您的通訊偏好及與我們、我們的服務提供商或其他第三方通訊的紀錄； </li>
                                        <li>•   使用及交易紀錄，包括您的搜尋、訂單內容，您在本平台上互動的廣告內容，及其他與您有關的產品或服務； </li>
                                        <li>•   位置資訊； </li>
                                        <li>•   當使用者註冊使用本服務或本平台及使用者使用本服務或本平台時所提供有關使用者的其他任何資訊，以及使用者如何使用本服務或本平台的相關資訊；以及 </li>
                                        <li>•   彙總使用者互動內容的相關資訊。 </li>
                                    </ul>
                                    <ul style={{ paddingLeft: `4px` }}>
                                        前述資料，依據法務部頒佈之「個人資料保護法之特定目的及個人資料之類別」，本公司蒐集、處理、利用及保有您個人資料之種類列示如下：
                                        <li>•   C001 辨識個人者。(例如：姓名、職稱、住址、工作地址、以前地址、住家電話號碼、行動電話、網路平臺申請之帳號、會員編號、通訊及戶籍地址、電子郵遞地址及其他任何可辨識資料本人者等。) </li>
                                        <li>•   C002 辨識財務者。(例如：金融機構帳戶之號碼與姓名、信用卡或簽帳卡之號碼、個人之其他號碼或帳戶等。) </li>
                                        <li>•   C003 政府資料中之辨識者。(例如：身分證統一編號、護照號碼等。) </li>
                                        <li>•   C011 個人描述。(例如：年齡、性別、出生年月日、出生地、國籍等。) </li>
                                        <li>•   C031 住家及設施。(如：住所地址) </li>
                                        <li>•   C034 旅行及其他遷徙細節。(例如：旅行細節) </li>
                                        <li>•   C035 休閒活動及興趣。(例如：嗜好、運動及其他興趣等。) </li>
                                        <li>•   C036 生活格調。(例如：使用消費品之種類及服務之細節、個人或家庭之消費模式等。) </li>
                                        <li>•   C102 約定或契約。(例如：關於交易、商業、法律或其他契約、代理等。) </li>
                                        <li>•   C103 與營業有關之執照。(例如：執照之有無。) </li>
                                        <li>•   C132 未分類之資料。(例如：無法歸類之信件、檔案、報告或電子郵件等。) </li>
                                    </ul>
                                </li>



                                <li> 3.2  您同意不提供任何錯誤或誤導的資訊給我們，並且您同意在您所提供的資訊有任何錯誤或變更時通知我們。我們保留單方決定向您要求進一步文件以驗證您所提供的資料之權利。</li>
                                <li> 3.3  若您使用您的社群媒體帳戶(下稱「社群媒體帳戶」)來註冊成為本平台的使用者，或將您的Videostores帳戶與您的社群媒體帳戶連結，或使用任何Videostores的社群功能，我們可能會存取您在該社群媒體帳戶提供者之政策下所自願提供予該社群媒體帳戶提供者的資訊，我們將持續依照本隱私權政策來管理及使用相關個人資料。</li>
                                <li> 3.4  若您不希望我們蒐集前述資訊/個人資料，您可以隨時透過書面方式通知我們的「資料保護主管」以選擇退出。您可以在下方標題是「如何撤回同意、移除、要求存取或修改您提供給我們的資訊？」的小節中，找到選擇退出的進一步資訊。不過請注意，若您不允許或是不同意我們蒐集、使用或處理您的個人資料，您對本服務及本平台的使用可能會受到影響。例如，不讓我們蒐集有關您的位置資訊，可能會造成以位置為基礎的功能遭到停用。</li>
                            </ul>
                            <h4>4.其他資訊蒐集</h4>
                            <ul style={{ margin: `4px 0` }}>
                                <li>4.1  如同對大多數的網站及行動應用程式一樣，您的裝置會傳送可能包含與您相關的資訊，而網頁伺服器則會在您瀏覽本平台時記錄該資訊。這通常包括但不限於：您裝置的IP位址、電腦或行動裝置作業系統和瀏覽器類型、行動裝置的種類、行動裝置的特性、行動裝置的唯一裝置識別碼(UDID)或行動設備識別碼(MEID)、參考網站的地址(若有)，以及您在我們的平台或行動應用程式時所造訪的網頁以及造訪時間，有時也會利用Cookie(可使用瀏覽器喜好設定予以停用) 協助本平台記住您前次的造訪資訊。當您登入時，這項資訊會與您的個人帳戶建立關聯。該資訊也會納入匿名的統計資料中，以協助我們瞭解訪客使用我們平台的情形。</li>
                                <li>4.2  我們的行動應用程式可能會使用GPS、Wi-Fi等技術蒐集您行動裝置位置的精確資訊。我們會針對一或多項「目的」以蒐集、使用、揭露及/或處理該資訊，包括但不限於：您所要求以位置為基礎的服務、根據您的位置傳遞相關內容給您、透過我們的行動應用程式所包含的服務，讓您與其他使用者分享您的位置。您可以透過大部分行動裝置的裝置設定，撤銷我們獲取您位置資訊的權限。如果您對停用行動裝置位置服務的方式有任何疑問，請洽詢行動裝置服務供應商或裝置製造商。</li>
                                <li>4.3  因為您在我們的網站或行動應用程式上瀏覽頁面，當您觀看內容、廣告及透過我們平台或本服務存取其他軟體時，大多數相同的資訊也會傳送給我們（包括但不限於IP地址、作業系統等），但您的裝置會向我們傳送的是有關您查看的內容、廣告及/或本服務和本平台安裝的軟體及時間的資訊，而不是查看的頁面。</li>
                            </ul>
                            <h4>5.COOKIE</h4>
                            <ul style={{ margin: `4px 0` }}>
                                <li>5.1  我們或我們授權的服務提供者及廣告夥伴有時可能會使用「Cookie」或其他功能，讓我們或第三方能夠蒐集或分享與您使用本服務或本平台有關的資訊。這些功能協助我們改進所提供的本平台和本服務，協助我們提供新的服務及功能，及/或使我們或我們的廣告夥伴提供您更相關的內容，包括再行銷。「Cookie」是儲存在您的電腦或行動裝置的識別碼紀錄電腦或裝置的數據，同時告訴我們本服務或本平台的使用或造訪方式、時間、造訪人次，及其他我們平台上的活動。我們可能會將Cookie資訊與個人資料連結起來。Cookie也會連結至您選擇購買之商品及您有檢視之網頁的相關資訊。這項資訊會用於追蹤您的購物車，傳遞與您興趣相關的特定內容，使我們的第三方廣告夥伴於網路上各網站提供廣告，進行數據分析，以及監控本服務的使用情形。</li>
                                <li>5.2  若您要拒絕 Cookie 的使用，請選擇瀏覽器上的適當設定。不過請注意，如果您這麼做，可能無法使用本平台或本服務的完整功能。</li>
                            </ul>
                            <h4>6.我們如何使用您提供給我們的資訊？</h4>
                            <ul style={{ margin: `4px 0` }}>
                                6.1  我們可能會針對下列一或多個目的去蒐集、使用、揭露及/或處理您的個人資料：
                                <ul style={{ paddingLeft: `4px` }}>
                                    <li>(a)   考量及/或處理您向我們提出的申請/與我們進行的交易，或是您透過本服務與第三方進行的交易或通訊；</li>
                                    <li>(b)   管理、操作、提供及/或處理您對我們的本服務和本平台的使用及/或存取(包括但不限於記住您的喜好設定)、您與我們的關係，以及您向我們開立的使用者帳戶；</li>
                                    <li>(c)   回應、處理、處理或完成交易及/或滿足您對特定產品及服務的要求，以及向您告知服務問題和不尋常的帳戶操作；</li>
                                    <li>(d)   執行我們的「服務條款」或任何適用的使用者授權合約；</li>
                                    <li>(e)   保護人身安全和權利、財產或其他人的安全；</li>
                                    <li>(f)   識別、驗證、盡職調查（due diligence）或認識客戶(know your customer)；</li>
                                    <li>(g)   維護及管理偶爾需要進行的軟體更新及/或其他更新及支援，以確保本服務能順暢運作；</li>
                                    <li>(h)   處理或促進客戶服務、執行您的指示、處理或回應由您或代表您 (或聲稱由您或代表您) 提出的一切詢問；</li>
                                    <li>(i)   基於執行及/或管理您與我們的關係，或是您的使用本服務之目的，透過語音通話、文字簡訊及/或傳真訊息、電子郵件及/或郵寄或其他方式與您聯繫或溝通，例如但不限於向您傳達與本服務有關的管理資訊。您知悉並同意我們得透過郵寄信件、文件和通知的方式與您進行上述通訊，遞送過程中可能會在信封/郵件包裹外部揭露您的特定個人資料；</li>
                                    <li>(j)   便利其他使用者在本平台上與您互動、連繫或看到您在本平台上的部分活動，包括當另一名使用者在本平台上向您傳送私訊或是張貼要給您的意見時通知您或透過本平台上的社群功能與您聯繫；</li>
                                    <li>(k)   進行研究、分析和開發活動 (包括但不限於資料分析、問卷調查、產品和服務開發及/或剖析)，以分析您對本服務的使用情形、推薦與您興趣相關的商品或服務、改進我們的本服務或產品及/或加強您的客戶體驗；</li>
                                    <li>(l)   允許進行稽核及調查以確認目標受眾的規模和組成，並瞭解他們對本服務的體驗；</li>
                                    <li>(m)   基於行銷或廣告這方面的目的，透過各種通訊媒介及模式，向您寄送有關Videostores (及/或其關係企業或關聯公司) 可能正在銷售、行銷或促銷之產品及/或服務 (包括但不限於可能與Videostores合作或聯合提供之第三方的產品及/或服務) 的行銷和促銷資訊及資料，無論該產品或服務目前已存在或是未來才會建立；您隨時可以透過電子行銷資料中的取消訂閱功能來取消接受行銷資訊。我們可能會使用您的聯絡資訊向您寄送我們或關係企業電子報或行銷資訊。</li>
                                    <li>(n)   回應法律程序或是遵守相關管轄地之任何適用法律、政府或監管要求或是我們有理由相信揭露為必要的，包括但不限於依據對Videostores或是其關聯公司或關係企業具有約束力之任何法律規定，符合揭露之要求(包括但不限於，如適用法令要求，揭露您的姓名、聯絡資訊及公司資料)；</li>
                                    <li>(o)   針對內部和法定報告及/或記錄保存要求，產生統計資料和研究結果；</li>
                                    <li>(p)   根據法律或法規義務或是我們的風險管理程序 (依據法律規定或是我方所實施者)，執行盡職調查或其他過濾行動 (包括但不限於背景調查)；</li>
                                    <li>(q)   稽核我們的本服務或Videostores的業務；</li>
                                    <li>(r)   防止或調查任何實際或可能違反我們服務條款之情形、詐欺、非法活動、疏忽或不當行為，以瞭解這些行為是否與您對本服務的使用有關、是否因為您與我們的關係而產生；</li>
                                    <li>(s)   回應任何聲稱的或實際的針對Videostores之主張，或其他對於內容侵害第三方權利之主張；</li>
                                    <li>(t)   儲存、託管、備份 (無論是為了災難復原或其他目的) 您的個人資料，不管是在您所在管轄地內或外；</li>
                                    <li>(u)   處理及/或促進企業資產交易或可能進行的企業資產交易，其中該交易的參與者包含Videostores，或是僅包含Videostores的關聯公司或關係企業，或是包含Videostores及/或Videostores任一家或多家關聯公司或或關係企業，而且可能還有其他第三方組織也參與該交易。「企業資產交易」是指組織、組織的一部分或組織之任何業務或資產的收購、出售、租賃、整合、合併或其他任何的收購、處置或融資行為；以/或</li>
                                    <li>(v)   本公司/本服務可能以LINE通知型訊息傳送重要訊息給您。即使未加入本公司/本服務LINE好友，您仍可接收到本公司/本服務傳送之LINE通知型訊息。本公司/本服務傳送之LINE通知型訊息以對您有效且重要的訊息為限，以廣告或其他目的的訊息皆不會被傳送。滿足以下三個條件者，將可收到LINE通知型訊息：(1) LINE 帳號設定的電話號碼與您註冊使用本公司/本服務的電話號碼比對相符、(2) 該 LINE 帳號已在 LINE APP 設定中，同意接收通知型訊息、(3) LINE 帳號未封鎖傳送訊息之本公司/本服務 LINE 官方帳號。欲變更LINE通知型訊息的設定，操作如下：(1) 點選「主頁」＞「設定」、(2) 點選「隱私設定」、(3) 點選「提供使用資料」、(4) 點選「LINE通知型訊息」、(5) 開關「接收LINE通知型訊息」。關閉「接收通知型訊息」後，將不會接收到來自任何企業官方帳號或認證官方帳號的LINE通知型訊息；以/或</li>
                                    <li>(w)　我們在取得您同意的當時，向您告知的任何其他目的。</li>
                                </ul>
                                (以上統稱「目的」)。
                                以上目的，屬於法務部頒佈之「個人資料保護法之特定目的及個人資料之類別」之下列目的：
                                <ul style={{ paddingLeft: `4px` }}>
                                    <li>•    040 行銷</li>
                                    <li>•    063 非公務機關依法定義務所進行個人資料之蒐集處理及利用</li>
                                    <li>•    067 信用卡、現金卡、轉帳卡或電子票證業務</li>
                                    <li>•    069 契約、類似契約或其他法律關係事務</li>
                                    <li>•    077 訂位、住宿登記與購票業務</li>
                                    <li>•    090 消費者、客戶管理與服務</li>
                                    <li>•    091 消費者保護</li>
                                    <li>•    095 財稅行政、調查、統計與研究分析</li>
                                    <li>•    098 商業與技術資訊</li>
                                    <li>•    107 採購與供應管理</li>
                                    <li>•    111 票券業務</li>
                                    <li>•    118 智慧財產權、光碟管理及其他相關行政</li>
                                    <li>•    125 傳播行政與管理</li>
                                    <li>•    127 募款 (包含公益勸募)</li>
                                    <li>•    129 會計與相關服務</li>
                                    <li>•    132 經營傳播業務</li>
                                    <li>•    135 資(通)訊服務</li>
                                    <li>•    136 資(通)訊與資料庫管理</li>
                                    <li>•    148 網路購物及其他電子商務服務</li>
                                    <li>•    152 廣告或商業行為管理</li>
                                    <li> •    157 調查、統計與研究分析</li>
                                    <li>•    173 其他公務機關對目的事業之監督管理</li>
                                    <li>•    179 其他財政服務及配合財政部電子發票整合服務</li>
                                    <li>•    181 其他經營合於營業登記項目或組織章程所定之業務</li>
                                </ul>
                                <li>6.2  您知悉並同意若依法律要求、或根據法院命令、或對Videostores有管轄權之任何政府或主管機關之要求、或基於善意及誠信原則認有合理之必要性時，Videostores得存取、保存及揭露您的帳戶資訊和內容，以：(a) 遵守法律程序；(b) 執行對Videostores有管轄權之任何主管機關之要求；(c) 履行Videostores服務條款或本隱私權政策；(d) 回應任何實際或可能對Videostores不利之主張或其他本平台內容侵害第三方權利之主張；(d) 回應您的客戶服務請求；或者 (e) 保護Videostores、其使用者及/或公眾之權利、財產或人身安全。</li>
                                <li>6.3  由於我們將會/可能蒐集、使用、揭露或處理您個人資料的目的取決於當時的情況，該目的可能未出現在上述項目中。不過，我們會在取得您同意的當時，向您告知該其他目的，除非隱私權法允許我們在未取得您同意的情況下即可處理適用的資料。</li>
                            </ul>
                            <h4>7.Videostores如何保護及保存客戶資訊？</h4>
                            <ul style={{ margin: `4px 0` }}>
                                <li>7.1  我們實施各式各樣的安全措施，以致力確保您的個人資料在我們的系統上安全無虞，但不可避免地無法保證絕對的安全。使用者個人資料包含在安全網路之後，只有具有該系統之特殊存取權的有限員工可以存取。</li>
                                <li>7.2  我們會按照隱私權法及/或其他適用法律保存個人資料。也就是說，在我們合理認為 (i) 保存該個人資料不再符合蒐集個人資料之目的； (ii) 不再需要針對任何法律或業務目的保存個人資料時；以及 (iii) 沒有其他法律上之利益需要進一步保存該個人資料時，我們會立即銷毀您的個人資料，或是進行匿名化處理。若您停止使用本平台或是您使用本平台及/或本服務的權限遭到終止或撤銷，我們會繼續根據此隱私權政策及隱私權法所規定的義務，加以儲存、使用及/或揭露您的個人資料。根據適用法律，我們得於未事先通知您的情況下，安全處置您的個人資料。</li>
                            </ul>
                            <h4>8.Videostores是否會向第三人揭露其向訪客蒐集的資訊？</h4>
                            <ul style={{ margin: `4px 0` }}>
                                <li>8.1  在開展業務時，我們將會/可能需要針對上述一或多個目的向第三方服務供應商、代理人及/或我們的關係企業或關聯公司及/或其他第三方 (無論是否設址於新加坡) 使用、處理、揭露或移轉您的個人資料。該第三方服務供應商、代理人及/或關係企業或關聯公司及/或其他第三方可能會針對上述一或多個目的，代表我們或以其他方式處理您的個人資料。我們將努力確保該第三方或關係企業保障您的個人資料不受任何未授權的取得、蒐集、揭露、使用或經歷任何類似的風險，並只有在符合前述使用目的時保存您的個人資料。該第三方包括但不限於：</li>
                                <ul style={{ paddingLeft: `4px` }}>
                                    <li>(a)   我們的子公司、關係企業及關聯公司；</li>
                                    <li>(b)   您曾在本平台上交易或互動過的買方或賣方，或與您基於前述目的使用本服務有關；</li>
                                    <li>(c)   本平台的其他使用者基於本隱私權政策所述之目的;</li>
                                    <li>(d)   承包商、代理人、服務供應商以及其他我們用以支援我們的業務的第三方。這些包括但不限於向我們提供管理或其他服務的單位，如郵寄公司、物流服務提供者、金融服務提供者、廣告及行銷夥伴、電信公司、資訊技術公司和資料中心；</li>
                                    <li>(e)   對Videostores有管轄權的政府或監管機關或依據第6.2條所允許；</li>
                                    <li>(f)   整合、剝奪、改組、重整、解散或其他銷售或轉移 (無論是持續經營的形式，或是破產、清算或類似程序的一部分) Videostores部分或全部資產的買方或其他繼任者，其中由Videostores所持有、有關本服務使用者的個人資料也在轉移的資產中；或是Videostores或其任何關係企業或關聯公司所參與之企業資產交易的對手；以及</li>
                                    <li>(g)   我們針對一或多個目的而向其揭露個人資料的第三方，該第三方可能會針對一或多個目的反過來蒐集和處理您的個人資料。</li>
                                </ul>
                                <li>8.2  我們得與廣告夥伴及第三方廣告、再行銷及/或方案供應商分享和使用者及其對本服務使用情形相關的統計及人口統計資訊。</li>
                                <li> 8.3  為避免疑慮，在隱私權法或其他適用法律允許組織 ，像是我們， 在未取得您的同意即可蒐集、使用或揭露您個人資料的情況下，由該等法律授予的該權限應繼續適用。承前述及適用法律允許下，我們得依據認定的法律理由使用您的個人資料以遵守我們的法律義務、履行對您的契約義務、實現合法利益，以及其他任何優先於您的保護數據權利之理由，或與合法主張有關所必要時。</li>
                                <li>8.4  在我方沒有責任的情況下，第三方可能會非法攔截或存取傳送至網站或是網站所包含的個人資料，技術可能會故障或無法如預期運作，或是有人可能會存取、濫用或誤用資訊。不過，我們會依照隱私權法的規定，部署合理的安全措施以保護您的個人資料，但必然無法保證絕對的安全，包括但不限於在我方沒有責任的情況下，因不滿份子惡意和精密的駭客攻擊而造成未授權揭露等狀況。</li>
                                <li>8.5  Videostores允許您自YouTube分享影片至Videostores直播功能(“YouTube內容”)，為便利此行為，Videostores使用YouTube提供的YouTube API服務。經由分享YouTube內容之行為，您同意受到Google隱私權政策的拘束(http://www.google.com/policies/privacy)。</li>
                                <li>8.6  如Videostores服務條款中所述，因使用本服務而持有其他用戶個人資料之用戶（“接收方”，包含用戶之員工、代理人、公司負責人及任何為該用戶服務或代用戶使用本服務者）在此同意（i）遵守所有與此類資料之蒐集、處理、儲存或傳送等相關的個人資料保護法律，（ii）允許videostores或接收方已蒐集其個人資料的用戶（“揭露方”）從接收方的資料庫中刪除其被蒐集的資料，及（iii）允許videostores或揭露方審查接收方蒐集了哪些揭露方的資料。上述（ii）和（iii）的每件個案，應依據適用法律的要求。</li>
                                <li>8.7  不論本政策有任何約定，賣方（包含其員工、代理人、公司負責人及任何為賣方服務或代賣方使用本服務者）應遵守所有適用的隱私法律，對於從Videostores接收到的任何買方個人資料（i）除非有合理必要需回應買方的查詢和回應、處理或完成交易，否則不得利用買方個人資料，（ii）應避免在Videostores平台之外使用此類資訊與買方聯繫；（iii）不得未經買方和Videostores的事先書面同意即將此類買方的個人資料揭露給任何未經授權的第三方，（iv）應採取足夠的安全措施來保護所持有的Videostores用戶個人資料，僅於符合個人資料法規範與符合前述目的情況下保存用戶個人資料，並依據videostores要求或交易完成後合理期間內儘快返還資料給videostores或儘速刪除這些資料，及（v）如果有任何潛在的資料洩露或其他此類用戶資料遺失的情況，請透過contact@videostores.io通知Videostores的個人資料保護主管，賣方並應儘速透過平台將潛在的洩漏或資料遺失狀況，通知所有可能受影響之用戶。</li>
                            </ul>
                            <h4>9.關於兒童的資訊</h4>
                            <li>9.1  本服務不適用於未滿13歲的兒童。我們不會故意蒐集或保存未滿13歲兒童的任何個人資料或無法識別個人身分的資訊，而且本平台或其他本服務的所有部分都不是以未滿13歲兒童為對象。未滿13歲兒童的父母或法定監護人應確保該兒童不向本平台提供其個人資料，否則視為該兒童父母或法定監護人代表該兒童所為並受其拘束。我們會關閉由未滿13歲兒童專用的一切帳戶，並且會移除及/或刪除我們認為是由未滿13歲兒童且非父母或法定監護人代表所提交的一切個人資料。</li>

                            <h4>10.由第三方蒐集的資訊</h4>
                            <li>10.1  本平台使用由 Google, Inc.(下稱「Google」)提供的網頁分析服務 - Google Analytics。Google Analytics使用Cookie，在您裝置上的文字檔案，協助平台分析使用者使用本平台的情形。由 Cookie 產生有關您的本平台使用情形的資訊 (包括您的 IP 位址) 會傳送至Google，並由Google存放在位於美國的伺服器。Google會使用這項資訊評估您的本平台使用情形、為網站經營者編製網站活動報告，並提供與網站活動和網際網路使用情形相關的其他服務。Google可能也會在法律要求下將這項資訊傳送給第三方，或是將該資訊傳送給代表Google處理資訊的第三方。Google不會將您的IP位址與Google所持有的其他資料建立關聯。</li>
                            <li>10.2  我們和第三方偶爾可能會在本服務或透過本平台或本服務提供軟體應用程式下載供您使用。這些應用程式可能會個別存取並允許第三方檢視您的身分識別資訊(如您的姓名、使用者 ID、裝置IP位址或其他資訊)，例如您之前所安裝或是由第三方軟體應用程式或網站為您安裝的Cookie。此外，這些應用程式可能會要求您直接向第三方提供其他資訊。Videostores並不擁有或控制經由這些應用程式所提供的第三方產品或服務。我們建議您詳閱這些第三方在其網站或其他地方公佈的條款與其他政策。</li>

                            <h4>11.有關安全性和第三方網站的免責聲明</h4>
                            <li>11.1  我們不保證您在第三方網站上提供之個人資料及/或其他資訊的安全性。為維護我們所擁有或控制與您有關之個人資料的安全性，我們確實實施了各種安全措施。您的個人資料包含在安全網路之後，只有具有該系統之特殊存取權且必須對個人資料保密的有限人員可以存取。當您下訂單或存取個人資料時，我們會提供安全伺服器以供使用。您提供的所有個人資料或敏感資訊都會以加密方式存放在我們的資料庫中，只有上述人員可以存取。</li>
                            <li>11.2  為嘗試提供更高的價值，我們可能會選擇將各種第三方網站連結至本平台，或是將第三方網站放在本平台框架中。我們可能也會參與聯合品牌和其他關係，以便為訪客提供電子商務及其他服務與功能。所連結的網站有個別和獨立的隱私權政策及安全措施。即使該第三方與我們有關，我們對於這些連結網站也沒有控制權，而且每一個連結網站都有不受我們約束的個別隱私權和資料蒐集作法。我們可能不會收到由聯合品牌夥伴或第三方網站 (即使是在我們的本網站上或透過我們的本平台提供) 所蒐集的資料。</li>
                            <li>111.3  因此，我們對於這些連結網站的內容、安全措施 (或缺乏該安全措施) 及活動，沒有任何責任或義務。這些連結網站只是為了方便起見而提供，因此當您存取這些網站時，應自行承擔風險。儘管如此，我們仍設法保護本平台及置於各網站之連結的完整性，因此歡迎提出與這些連結網站相關的意見 (包括但不限於特定連結無法運作的狀況)。</li>

                            <h4>12.Videostores是否會將您的資訊傳送至國外？</h4>
                            <li>12.1  您的個人資料及/或資訊可能會為了履行前述一個或多個目的，傳送至您的國家/地區以外的地方，並在該地根據本隱私權政策及隱私權法之規定保存或處理。在大多數情況下，您的個人資料會在新加坡保存，我們的伺服器即設立於此。Videostores只會在符合隱私權法的情況下將您的資訊傳送至國外。</li>

                            <h4>13.如何撤回同意、移除、要求存取或修改您提供給我們的資訊？</h4>
                            <li>13.1  撤回同意</li>
                            <li>13.1.1  若您不再同意我們蒐集、使用及/或揭露由我們擁有或控制之和您有關的個人資料或要求我們刪除您的個人資料，請傳送電子郵件至 contact@videostores.io給我們的「個人資料保護主管」，我們將依本政策及隱私權法規定處理您的申請。不過，若您撤回同意，這可能表示我們將無法繼續向您提供本服務、我們可能需要終止您目前與我們的關係及/或合約。</li>
                            <li>13.2  要求存取或修正個人資料</li>
                            <li>13.2.1  若您有我們的帳戶，您可以透過本平台的「帳戶設定」頁面，親自存取及/或修正目前由我們擁有或控制的個人資料。若您沒有我們的帳戶，您可以提交書面要求給我們，要求存取及/或修正目前由我們擁有或控制的個人資料。您必須提供足夠的資訊，供我們查明您的身分和要求的性質，以便能處理您的要求。因此，請傳送電子郵件至contact@videostores.io給我們的「個人資料保護主管」，以提交您的書面要求。</li>
                            <li>13.2.2  您可以請求查詢、閱覽本人的個人資料或要求給予複本(Videostores得酌收必要合理的費用)，我們會在取得您所提供、足以處理該要求的充分資訊後15天內將相關的個人資料提供給您。如果我們不能在前述的15天內對您做出回應，我們會通知您最快能將您所要求的資訊提供給您的時間。</li>
                            <li>13.2.3  您可以請求補充、更正、刪除、停止處理或利用本人的個人資料，我們會在取得您所提供、足以處理該要求的充分資訊後：</li>
                            <ul style={{ paddingLeft: `4px` }}>
                                <li>(a)   於30天內處理完成；以及</li>
                                <li>(b)   因可歸責Videostores之事由需更正或補充個人資料者，我們將於更正或補充後依法通知曾提供利用之對象。</li>
                            </ul>

                            <h4>14.問題、疑慮或投訴？聯絡我們</h4>
                            <li>14.1  如果您對於我們的隱私權作法有任何問題或疑慮，請逕行聯絡： contact@videostores.io</li>


                            最新修改日期：2022年11月30日
                        </div>
                    </div>

                </div>
            </header>
            <Footer></Footer>
        </>
    )
}

export default PolicySections
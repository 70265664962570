import kvnConfig from "./../views/KeyValueNameConfig.js";
import { deleteItem, queryItems } from "./DynamodbMethods.js";
import { s3DeleteFile } from './S3Methods.js';

async function deleteFileTemps(uid) {

    let counter = 0;
    const tempItems = await queryItems("Temp#"+kvnConfig.s3MainBucketName, uid+"#");
    if (tempItems.Items) {
        for(let i=0;i<tempItems.Items.length;i++) {
            const deleteRes = await s3DeleteFile(tempItems.Items[i].PK.replace('Temp#', ''), tempItems.Items[i].SK.replace(uid+"#", ''));
            const moveRes = await deleteItem(tempItems.Items[i].PK, tempItems.Items[i].SK);
            counter = counter + 1;
        }
    }
    return counter;
}
  
export default deleteFileTemps;
function footer(props){
    return (
        <>
        <div className="footer">
			<div className="wrap clearfix">
				<div className="rt">
					<a href="index.html"><img src={require("assets/LandingPage/images/logo1.png").default} alt=""/></a>
				</div>
				<div className="lt">
					<h6>合作聯繫: contact@videostores.io</h6>
					<h6>會員: members@videostores.io</h6>
					<h6>影品供應商: supports@videostores.io</h6>
				</div>
				
			</div>
			<div className="wrap clearfix" style={{color:`#fff`}}>
				<a style={{color:`#fff`}} href="/legal/terms" target="_blank">使用條款 </a> 和 <a style={{color:`#fff`}} href="/privacy/policy" target="_blank"> 隱私權政策</a>  Copyrights © 2023 翔偉資安科技 
			</div>
			
		</div>
        </>
    )
}

export default footer;
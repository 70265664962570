import React, { useMemo, useEffect, useState, useValue } from 'react'
import "assets/css/legal.css"
import OnlyHeader from "../../components/Headers/OnlyHeader"
import Footer from '../Landing-Sections/footer'
function LegalSections() {
    return (
        <>
            <OnlyHeader></OnlyHeader>
            <header className="legal  mb-4 pb-4">
                <div className='container'>
                    <div>
                        {/* <img src={require("assets/FashionDraping/images/videoStore.png").default} alt="" style={{ height: `100%`, minWidth: `117px` }} /> */}
                        <div className="span">
                            <h3>會員使用條款</h3>
                            <ul style={{ margin: `4px 0` }}>
                            <h4>一、本網站與所提供之相關服務，由翔偉資安科技股份有限公司 (地址：新竹市北區竹光路278號) 其關係企業及子公司 (個別及統稱為「VIDEOSTORES」)所提供。</h4>
                            <li>本會員使用條款(以下簡稱「本條款」)將規範您對本網站與網站所提供之相關服務之存取與使用。此外，本網站所含或可透過本網站取得的特定服務，或您透過本網站進行之交易，可能必須適用特定的其他條款，此類特定的其他條款將額外附加於本條款，或在特定範圍內取代本條款。
                            同意與接受本條款
                            VIDEOSTORES依據本條款提供本網站之使用與本網站所提供之相關服務（以下統稱「 本服務 」），當會員完成註冊手續、或開始使用本服務時，即表示已閱讀、瞭解並同意接受本條款之所有內容，且完全接受本服務現有與未來衍生的服務項目及內容。
                            VIDEOSTORES有權隨時修改本條款內容，修改後的條款內容將公佈於網站上， VIDEOSTORES將不會個別通知會員，請會員登入時注意相關修改。會員於任何修改後繼續使用本服務時，視為會員已閱讀、瞭解並同意接受該等修改。若您不同意本條款之修改或不接受本條款的任一約定，應立即停止使用本服務。
                            若會員為未滿十八歲之未成年人，應於會員的家長（或法定代理人）閱讀、瞭解並同意本條款之所有內容及其後修改變更後，方得註冊為會員、使用或繼續使用本服務。當會員使用或繼續使用本服務時，即推定會員的家長（或法定代理人）已閱讀、瞭解並同意接受本條款之所有內容及其後修改變更。</li>
                            <h4>二、會員的註冊義務</h4>
                            <li>會員同意：
                            依本服務註冊表之提示提供會員正確、最新的資料，且不得以第三人之名義註冊為會員。每位會員僅能註冊登錄一個帳號，不可重覆註冊。
                            即時維持並更新會員資料，確保其正確性，以獲取最佳之服務。
                            若會員提供任何錯誤或不實的資料、或未按指示提供資料、或欠缺必要之資料、或有重覆註冊帳號等情事時， VIDEOSTORES有權不經事先通知，暫停或終止會員帳號與本服務之提供。</li>
                            <h4>三、 隱私權政策與個資</h4>
                            <li>關於會員的註冊以及其他特定資料將依「VIDEOSTORES隱私權政策」受到保護與規範。
                            為了確保會員之個人資料、隱私及消費者權益之保護，於交易過程中將使用會員之個人資料，依個人資料保護法第8條規定告知以下事項：
                            蒐集之目的：
                            040行銷
                            063非公務機關依法定義務所進行個人資料之蒐集處理及利用
                            067信用卡、現金卡、轉帳卡或電子票證業務
                            069契約、類似契約或其他法律關係事務
                            090消費者、客戶管理與服務
                            091消費者保護
                            098商業與技術資訊
                            107採購與供應管理
                            118智慧財產權、光碟管理及其他相關行政
                            136資(通)訊與資料庫管理
                            148網路購物及其他電子商務服務
                            152廣告或商業行為管理
                            157調查、統計與研究分析
                            181其他經營合於營業登記項目或組織章程所定之業務
                            蒐集之個人資料類別包括：
                            辨識個人者。如姓名、地址、電話、電子郵件、寄送地址及收件人資料等。
                            辨識財務者。如信用卡或簽帳卡之號碼、個人之其他號碼或帳戶等。
                            政府資料中之辨識者。如身份證字號或護照號碼。
                            個人描述。如國籍。
                            約定或契約。如關於交易、商業、法律或其他契約等
                            利用期間、地區、對象及方式：
                            期間：本服務營運期間或依法令所定或因執行業務所必須之保存期間或依個別契約就資料之保存所定之保存年限（以期限最長者為準）。
                            地區：會員之個人與公司資料將用於台灣地區、會員所在地、符合主管機關國際傳輸規定之其他地區或下列利用對象其國內及國外所在地。
                            利用對象及方式：以符合個人資料保護相關法令以自動化機器或其他非自動化之方式利用。基於服務目的，宏碁VIDEOSTORES將委任處理營運相關事務之必要第三人（如：合作廠商）使用您的個人資料；當您要求刪除個人資料時，宏碁VIDEOSTORES將先凍結該會員資料與其歷史訂單記錄，並禁止員工對該會員資料進行因行銷目的之蒐集、處理、利用，並於法令規定之保存期間屆滿後進行刪除。
                            會員就個人資料依個人資料保護法得行使以下權利：
                            查詢或請求閱覽。
                            請求製給複製本。
                            請求補充或更正。
                            請求停止蒐集、處理或利用。
                            請求刪除。
                            會員如欲行使上述權利，可與VIDEOSTORES客服(supports@videostores.io)連絡進行申請，但查詢或請求閱覽個人資料或製給複製本者，本公司得酌收必要成本費用每件NT1000元。
                            對於您所登錄或留存之個人資料， VIDEOSTORES及委外與配合之相關廠商都將於公告之特定目的範圍內予以利用，並依主管機關指定之個人資料檔案安全維護計畫採取適當之安全措施予以保護及處理，但有下列情形時，不在此限：
                            基於法律之規定、或受司法機關與其他有權機關基於法定程序之要求。
                            為增進公共利益。
                            為免除會員之生命、身體、自由或財產上之危險。
                            為防止他人權益之重大危害。
                            經過您本人書面同意。
                            ※如拒絕提供加入會員所需必要之資料，將導致無法使用完整服務或完全無法使用本服務。</li>

                            <h4>四、會員帳號、密碼及安全</h4>
                            <li>完成會員註冊程序後，會員將取得所申請的特定密碼及會員帳號，您有責任維持密碼及帳號之機密及安全。任何依照規定方法輸入會員帳號及密碼與登入資料一致時，無論是否由本人親自輸入，均推定為會員本人所使用，利用該密碼及帳號所進行的一切行為，會員本人應負完全責任。
                            會員並同意：
                            密碼或帳號遭到盜用或有其他任何安全問題發生時，會員將立即通知VIDEOSTORES。
                            不得轉借、轉讓他人或與他人合用會員的帳號、密碼。
                            帳號及密碼遭盜用、不當使用或其他VIDEOSTORES無法辯識是否為本人使用所所導致會員的損害，除證明可歸責於VIDEOSTORES之事由所致，將由會員自行承擔。
                            VIDEOSTORES接獲會員通知其帳號密碼確遭他人冒用時，將立即暫停該帳號之使用(含該帳號所生交易之處理)。</li>
                            <h4>五、兒童及青少年之保護</h4>
                            <li>為確保兒童及青少年使用網路的安全，並避免隱私權受到侵犯，家長（或法定代理人）應盡到下列義務：未滿十二歲之兒童使用本服務時時，應全程在旁陪伴，十二歲以上未滿十八歲之青少年使用本服務前亦應斟酌是否給予同意。</li>
                            <h4>六、守法義務及承諾</h4>
                            <li>會員承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。會員若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。會員同意並保證不得利用本服務從事侵害他人權益或違法之行為，例如：
                            (a)    上傳、張貼、傳送或以任何其他形式提供任何違法、傷害、威脅、侮辱、騷擾、危言聳聽、令人痛苦、扭曲、誹謗、粗俗、淫穢、詆毀、侵犯他人隱私、憎恨、種族歧視或在道德上或其他方面令人反感的內容。
                            (b)    違反法律規定、VIDEOSTORES的「禁止和限制商品政策」或侵害第三方權利。
                            (c)    上傳、張貼、傳送或以任何其他形式提供涉及未成年人之不當內容，以任何方式使用本服務傷害未成年人。
                            (d)    使用本服務冒充任何人或實體，或虛報您與任何人或實體的關係。
                            (e)    偽造標題或操弄識別方式，以混淆透過本服務傳送之任何內容的來源。
                            (f)    從本網站上移除任何所有權標示。
                            (g)    在未經VIDEOSTORES明確許可的情況下，致使、准許或授權修改、創作衍生著作或翻譯本服務。
                            (h)    為任何第三方之利益或非本條款授予許可的任何方式使用本服務。
                            (i)    將本服務使用於不法用途。
                            (j)    註冊或使用多個使用者帳戶，進行違反本服務條款文義或規範精神之活動。
                            (k)    以非VIDEOSTORES提供之硬體或軟體使用VIDEOSTORES平台、註冊使用者帳戶或登入使用者帳戶。
                            (l)    操控任何商品價格或干擾其他使用者的刊登行為。
                            (m)    採取任何會破壞反饋或排名系統的行動。
                            (n)    試圖解碼、反向工程、拆解或駭入本服務 (或其任何部分)，或試圖破解VIDEOSTORES針對本服務及VIDEOSTORES所傳輸、處理或儲存之資料所採取的任何加密技術或安全措施。
                            (o)   收集有關其他帳戶所有人的任何資訊。
                            (p)    上傳、以電子郵件寄送、張貼、傳送或以其他形式提供任何您依據任何法律或契約或信任關係(例如由於僱傭關係和依據保密合約所得知或披露之內部資訊、專屬及機密資料) 無權提供之內容。
                            (q)    上傳、以電子郵件寄送、張貼、傳送或以任何其他形式提供侵害任一方之任何專利、商標、營業祕密、著作權或其他專屬權利的任何內容。
                            (r)    上傳、以電子郵件寄送、張貼、傳送或以其他形式提供任何未經請求或未經授權的廣告、促銷資料、垃圾郵件、廣告信件、連鎖信、金字塔騙局或其他任何未經授權形式的推銷內容。
                            (s)    上傳、以電子郵件寄送、張貼、傳送或以其他形式提供任何含有意圖直接或間接干擾、操縱、中斷、破壞或限制任何電腦軟體、硬體、資料或通訊設備功能或完整性之電腦病毒、蠕蟲、木馬或任何其他電腦代碼、常式、檔案或程式的資料。
                            (t)    破壞正常的對話流程、造成螢幕快速捲動致使本服務其他使用者無法打字，或對其他使用者參加即時交流的能力造成負面影響。
                            (u)    干擾、操縱或破壞本服務或與本服務連線之伺服器或網路或任何其他使用者對本服務之使用或享受，或不遵守本網站連線網路之任何規定、程序、政策或規範。
                            (v)    採取或參與任何可能直接或間接造成本服務或與本服務連線之伺服器或網路損害、癱瘓、負載過重或效能降低的行動或行為。
                            (w)    使用本服務以蓄意或非蓄意地違反任何適用的當地、州、國家或國際法律、規定、守則、指令、準則、政策或規範。
                            (x)    以違反或規避由美國財政部外國資產管制辦公室、聯合國安全理事會、歐盟或其財政機關所主管或執行之處罰或禁運的方式使用本服務。
                            (y)    使用本服務侵犯他人隱私、跟蹤或以其他方式騷擾他人。
                            (z)    侵犯VIDEOSTORES的權利，包括智慧財產權和相關仿冒行為。
                            (aa)    以前述禁止之行為和活動使用本服務以收集或儲存其他使用者之個人資料。
                            (bb)    刊登侵犯第三方著作權、商標或其他智慧財產權的項目，或以侵害他人智慧財產權的方式來使用本服務。
                            (cc)    聯繫VIDEOSTORES客服時，使用具攻擊性、不雅、猥褻意涵之字句，或涉及對VIDEOSTORES及所屬員工進行騷擾、誹謗、侮辱、言論攻擊等影響VIDEOSTORES客服作業的行為。</li>
                            <h4>七、交易</h4>
                            <li>會員經購買本服務之影視產品並放入會員之個人媒體庫，或經儲值取得VIDEOSTORES點數且將點數轉入會員錢包後，即不得以任何原因要求退貨、退款。前述影視產品均定有觀賞期間，觀賞期間屆期後不論會員有無實際觀賞，VIDEOSTORES均可自動自會員之個人媒體庫移除該影視產品 。</li>
                            <h4>八、網站之停止、中斷</h4>
                            <li>VIDEOSTORES將依一般合理之技術及方式，維持本網站之正常運作。但於以下各項情況不在此限：
                            網站電子通信設備進行必要之保養及施工時，或發生突發性之電子通信設備故障時；
                            網站申請之電子通信服務被停止，無法提供服務時；
                            由於不可抗力或其他不可歸責於VIDEOSTORES之情事，致使網站無法提供服務時。</li>
                            <h4>九、責任限制與排除</h4>
                            <li>不論在任何情況下， VIDEOSTORES或其任何子公司、關係企業、合作夥伴、授權人或供應商就因存取、使用，或無法存取或使用本網站或本服務所生或與之有關的一切直接、間接、嗣後、懲罰性、特殊、偶發或其他損害，不負賠償責任。即使VIDEOSTORES曾被告知發生此類損失發生之可能性者亦同。但單純因VIDEOSTORES一方之違法行為或重大過失所直接導致者，不在此限。</li>
                            如會員所居住之國家或地區不允許為前項排除、賠償責任限制，則這類排除或限制在其受法律禁止之範圍內不適用，並僅在相關法律所容許之範圍內適用之。
                            <h4>十、終止會員使用</h4>
                            <li>會員同意VIDEOSTORES得基於維護交易安全之考量，因任何理由，包含但不限於缺乏使用，或違反本條款的明文規定及精神，終止會員的密碼、帳號（或其任何部分）或本服務（或其任何部分）之使用，或將本服務內任何會員資料與內容移除。</li>
                            <h4>十一、準據法與管轄法院</h4>
                            <li>本條款之適用與解釋以中華民國法令為準據法，會員同意中華民國台灣台北地方法院就因本條款或您使用本網站所生或與之相關的一切爭議(包括本條款之有效性有關之爭議)、請求或訴因，具有非排他性的司法管轄權。</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <Footer></Footer>
        </>
    )
}

export default LegalSections
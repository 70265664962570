import { updateItem } from "./DynamodbMethods.js";

async function updateVideoProduction(videoProductionEntity) {
    
    let result;

    if (videoProductionEntity) {

        videoProductionEntity.Type = "ProduceTeam";

        if (videoProductionEntity.PK && videoProductionEntity.SK) {
            if (videoProductionEntity.PK.length > 0 && videoProductionEntity.SK.length > 0) {
                result = await updateItem(videoProductionEntity);
            }
        }
    }
    return result;
}
  
export default updateVideoProduction;
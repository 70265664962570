import TagManager from 'react-gtm-module'
import "assets/FashionDraping/css/animate.css";
import "assets/FashionDraping/css/responsive.css";
import "assets/FashionDraping/css/slicy.css";
import "assets/FashionDraping/css/style.css";
import "assets/FashionDraping/css/swiper.min.css";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useHistory  } from "react-router-dom";
import VPFooter from "./VPFooter.js";
import VPHead from "./VPHead.js";
import VPList from "./VPList.js";
import VPPage from "./VPPage.js";
import VPReceipt from "./VPReceipt.js";
import dayjs from "dayjs";
import { Helmet } from 'react-helmet-async'

function FashionVideo(props) {
  const [session, setSession] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userVpList, setUserVpList] = useState([]);
  const history = useHistory ();
  
  const loadSession = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const sessionToken = {
        idToken: currentSession.getIdToken().getJwtToken(),
        refreshToken: currentSession.getRefreshToken().getToken(),
        accessToken: currentSession.getAccessToken().getJwtToken(),
      };
      setSession(currentSession);
      return sessionToken;
    } catch {
      return null;
    }
  };
  const init = async () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
        pagePath: '/fashion-draping/basics/vp',
        pageTitle: 'VP',
      },
    });
    const sessionToken = await loadSession();
    if (sessionToken) {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", sessionToken.accessToken);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        "https://nnfeel0fu3.execute-api.ap-northeast-1.amazonaws.com/dev/productList",
        requestOptions
      );
      const data = await res.json();
      setUserVpList(data);
      if (!data || data.length === 0) {
       window.location.href = "/fashion-draping/basics/"
      } else {
        let start;
        let end;
        for (const item of data) {
          const s = dayjs(item.BuyDate, "YYYYMMDDHHmmss");
          const e = dayjs(item.ExpireDate, "YYYYMMDDHHmmss");
          if (!start || s.isBefore(start)) {
            start = s;
          }
          if (!end || e.isAfter(end)) {
            end = e;
          }
        }
        if (start) {
          setStartDate(start.format("YYYY-MM-DD HH:mm"));
        }
        if (end) {
          setEndDate(end.format("YYYY-MM-DD HH:mm"));
        }
      }
    }
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>課程系列-洋裝篇 - 服裝立裁打版 課程明細 </title>
        <meta name='description' content='課程系列-洋裝篇 - 服裝立裁打版 課程明細' />
        </Helmet>
      <VPHead></VPHead>
      <div className="hpage4" style={{ padding: `52px 0` }}>
        <div className="wrap">
          <VPReceipt startDate={startDate} endDate={endDate}></VPReceipt>
          <VPList></VPList>
          <VPPage userVpList={userVpList}></VPPage>
        </div>
      </div>
      <VPFooter></VPFooter>
    </>
  );
}

export default FashionVideo;

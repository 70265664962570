import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

async function putMemberVideoExpire(memberVideoExpireEntity) {
    
    let result;
    let oldEntity = memberVideoExpireEntity;
    if (memberVideoExpireEntity) {

        memberVideoExpireEntity.Type = "M_Shelf";

        if (memberVideoExpireEntity.PK) {
            if (memberVideoExpireEntity.PK.length > 0) {
                // do nothing
            } else {
                if (memberVideoExpireEntity.MemberID){
                    if (memberVideoExpireEntity.MemberID.length > 0) {
                        memberVideoExpireEntity.PK = "M#"+memberVideoExpireEntity.MemberID
                    }
                }
            }
        } else {
            if (memberVideoExpireEntity.MemberID){
                if (memberVideoExpireEntity.MemberID.length > 0) {
                    memberVideoExpireEntity.PK = "M#"+memberVideoExpireEntity.MemberID
                }
            }
        }

        if (memberVideoExpireEntity.SK) {
            if (memberVideoExpireEntity.SK.length > 0) {
                // do nothing
            } else {
                if (memberVideoExpireEntity.ProductType && memberVideoExpireEntity.ProductSKU){
                    if (memberVideoExpireEntity.ProductType.length > 0 && memberVideoExpireEntity.ProductSKU.length > 0) {
                        memberVideoExpireEntity.SK = memberVideoExpireEntity.ProductType+"#"+memberVideoExpireEntity.ProductSKU;
                    }
                }
            }
        } else {
            if (memberVideoExpireEntity.ProductType && memberVideoExpireEntity.ProductSKU){
                if (memberVideoExpireEntity.ProductType.length > 0 && memberVideoExpireEntity.ProductSKU.length > 0) {
                    memberVideoExpireEntity.SK = memberVideoExpireEntity.ProductType+"#"+memberVideoExpireEntity.ProductSKU;
                }
            }
        }

        if (memberVideoExpireEntity.PK && memberVideoExpireEntity.SK && memberVideoExpireEntity.MemberID && memberVideoExpireEntity.ProductType && memberVideoExpireEntity.ProductSKU) {
            if (memberVideoExpireEntity.PK.length > 0 && memberVideoExpireEntity.SK.length > 0 && memberVideoExpireEntity.MemberID.length > 0 && memberVideoExpireEntity.ProductType.length > 0 && memberVideoExpireEntity.ProductSKU.length > 0) {
                
                result = await putItem(memberVideoExpireEntity);
            }
        }
    }
    if (result) {
        return memberVideoExpireEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putMemberVideoExpire;
import React from "react";

function VPFooter(props) {
    return (
        <>
        	<div className="hpage11">
				<div className="wrap wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
					<p>在此提供購課詳細使用權益條款，<br />
						如有遇到以上所述無法解決的問題，<br />
						可透過右侧聯絡方式與我們聯繫，我們會盡快回覆您，感謝您。</p>
					<h6>翔偉資安科技股份有限公司（videostores）<br />
						聯絡電話：<span>02-2711-6930 </span><br />
						客戶服務電子郵件：contact@videostores.io</h6>
				</div>
			</div>
        </>
    )
}

export default VPFooter;
function getUrlWithToken(Secret, ExpiredTime, URI) {

    // Secret => 'ABCDEFG'
    // Path => '/VP/files/'
    // ExpiredTime => '1637225099' // 秒
    // URI => '/VP/files/chunk.mp4'

    let st = "";
    if (Secret) {
        st = st + Secret;
    }
    if (ExpiredTime) {
        st = st + ExpiredTime;
    }
    if (URI) {
        st = st + URI;
    }

    const crypto = require('crypto');
    const hash = crypto.createHash('md5');
    hash.update(st); // hash.update(st, 'utf-8');
    const buffer = hash.digest();
    const b64Str = buffer.toString('base64');
    const token = b64Str.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');

    return "https://s3-sunwai.cdn.hinet.net"+URI+"?token="+token+"&expire="+ExpiredTime;
}

function createTokenWithURI(Secret, ExpiredTime, URI) {

    // Secret => 'ABCDEFG'
    // Path => '/VP/files/'
    // ExpiredTime => '1637225099' // 秒
    // URI => '/VP/files/chunk.mp4'

    let st = "";
    if (Secret) {
        st = st + Secret;
    }
    if (ExpiredTime) {
        st = st + ExpiredTime;
    }
    if (URI) {
        st = st + URI;
    }

    const crypto = require('crypto');
    const hash = crypto.createHash('md5');
    hash.update(st); // hash.update(st, 'utf-8');
    const buffer = hash.digest();
    const b64Str = buffer.toString('base64');
    const tokenStr = b64Str.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');

    return tokenStr;
}

function createTokenWithPath(Secret, Path, ExpiredTime) {

    // Secret => 'ABCDEFG'
    // Path => '/VP/files/'
    // ExpiredTime => '1637225099' // 秒
    // URI => '/VP/files/chunk.mp4'

    let st = "";
    if (Secret) {
        st = st + Secret;
    }
    if (Path) {
        st = st + Path;
    }
    if (ExpiredTime) {
        st = st + ExpiredTime;
    }
    
    const crypto = require('crypto');
    const hash = crypto.createHash('md5');
    hash.update(st); // hash.update(st, 'utf-8');
    const buffer = hash.digest();
    const b64Str = buffer.toString('base64');
    const tokenStr = b64Str.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');

    return tokenStr;
}

export { getUrlWithToken, createTokenWithURI, createTokenWithPath };
import React from "react";

function hPage3(props) {
    return (
        <>
            <div className="hpage3">
                <div className="wrap">
                    <div className="tit wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1s">
                        <h2>時尚服裝背後的<span>神級妙手</span></h2>
                        <p>眾多知名服裝設計師指名合作打版師</p>
                    </div>
                    <div className="bd clearfix wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
                        <div className="pic">
                            <img src={require("assets/FashionDraping/images/qm7.png").default} alt="" />
                        </div>
                        <div className="txt">
                            <h3>Dianna Ho 何佩珊</h3>
                            <h6>紡研所立裁講師/法雅時尚有限公司打版師/北京易初風尚版型顧問講師<br />
                                設計師尹培袞特聘打版師</h6>
                            <p>何佩珊老師在於高級服飾品牌和服裝訂製業界擁有多年的打版師經驗，更是服裝學界少有的立裁打版課程講師。在眾多知名的服裝設計作品中，都能看到何老師與許多知名服裝設計師的精湛合作，更善用自身豐富的經歷成為資深顧問，是服裝設計相關課程中的超卓師資，更是立裁技法的重要推手</p>
                        </div>
                    </div>
                    <h4 className="wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="1s">知名配合打版作品</h4>
                    <div className="ovs wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1s">
                        <div className="swipic">
                            <ul className='swiper-wrapper'>
                                <li className='swiper-slide'>
                                    <a href='#'>
                                        <img src={require("assets/FashionDraping/images/qm8.jpg").default} alt=""/>
                                            <h6>克萊亞服飾</h6>
                                    </a>
                                </li>
                                <li className='swiper-slide'>
                                    <a href='#'>
                                        <img src={require("assets/FashionDraping/images/qm9.jpg").default} alt="" />
                                            <h6>設計師Weilling Chang</h6>
                                    </a>
                                </li>
                                <li className='swiper-slide'>
                                    <a href='#'>
                                        <img src={require("assets/FashionDraping/images/qm10.jpg").default} alt="" />
                                            <h6>設計師-尹培袞</h6>
                                    </a>
                                </li>
                                <li className='swiper-slide'>
                                    <a href='#'>
                                        <img src={require("assets/FashionDraping/images/qm11.jpg").default} alt="" />
                                            <h6>設計師-尹培袞</h6>
                                    </a>
                                </li>
                                <li className='swiper-slide'>
                                    <a href='#'>
                                        <img src={require("assets/FashionDraping/images/qm12.jpg").default} alt="" />
                                            <h6>設計師-尹培袞</h6>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='prev'></div>
                        <div className='next'></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default hPage3;
import kvnConfig from "./../views/KeyValueNameConfig.js";

const AWS = require('aws-sdk');
AWS.config.update({
    region: kvnConfig.awsRegion,
    accessKeyId: kvnConfig.adminAccessKeyId,
    secretAccessKey: kvnConfig.adminSecretAccessKey
});
const s3 = new AWS.S3({apiVersion: '2006-03-01'});
const docClient = new AWS.DynamoDB.DocumentClient();

async function uploadPhotos(ProducerID, files) {
  let fileJsonArray = [];

  if (files) {
    for(let i=0;i<files.length;i++) {

      const fileObj = files[i];
      const fileTempName = fileObj.name;
      const fileMimeType = fileObj.type;
      const fileByteSize = fileObj.size;
      let fileSubName = "";

      const tempNames = fileMimeType.split("/");
      if (tempNames) {
        if (tempNames.length > 0) {
          if (tempNames[tempNames.length - 1]) {
            if (tempNames[tempNames.length - 1].length > 0) {
              fileSubName = tempNames[tempNames.length - 1];
            }
          }
        }
      }

      let fileMainName = "";
      const tempIndex = fileTempName.lastIndexOf(".");
      if (tempIndex > 0) {
        fileMainName = fileTempName.substring(0, tempIndex);
        if (fileSubName.length === 0) {
          if ((tempIndex + 1) < fileTempName.length) { // 確認 tempIndex+1 在範圍內
            const tempSub = fileTempName.substring(tempIndex + 1, fileTempName.length);
            if (tempSub.length > 0) {
              fileSubName = tempSub;
            }
          }
        }
      }

      const timestamp = (new Date()).getTime();
      const BucketName = kvnConfig.s3MainBucketName;
      const PathAndKey = "Photo/"+ProducerID+"-"+timestamp+"."+fileSubName;

      const putEntityPromise = new Promise((resolve, reject) => {
        
        const params = {
          TableName : kvnConfig.dbMainTableName,
          Item: {
            PK: "Photos",
            SK: ProducerID+"-"+timestamp,
            ProducerID: ProducerID,
            ExtensionName: fileSubName,
            BucketName: BucketName,
            PathAndKey: PathAndKey,
            MimeType: fileMimeType,
            SizeBytes: fileByteSize,
            DisplayName: fileMainName
          }
        };
        docClient.put(params, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
      });

      await putEntityPromise;
      
      const putFilePromise = new Promise((resolve, reject) => {
        const params = {
            Bucket: BucketName ? BucketName:"",
            Key: PathAndKey ? PathAndKey:"",
            Body: fileObj
        }
        s3.putObject(params, (putErr, putData) => {
            if (putErr) {
                reject(putErr);
            } else {
                resolve(putData);
            }
        });
      });
      
      await putFilePromise;

      const fileJson = {
        PK: "Photos",
        SK: ProducerID+"-"+timestamp,
        ProducerID: ProducerID,
        ExtensionName: fileSubName,
        BucketName: BucketName,
        PathAndKey: PathAndKey,
        MimeType: fileMimeType,
        SizeBytes: fileByteSize,
        DisplayName: fileMainName
      };

      fileJsonArray.push(fileJson);
    }
  }

  return fileJsonArray;
}
  
export default uploadPhotos;
import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

// {
// 	PK: "Invoice",
// 	SK: "I#InvoiceNumber",
// 	Type: "Invoice",
// 	InvoiceNumber: "MemberID20211231095959",
// 	InvoiceStatusFlag: false,
// 	InvoiceContent: "",
// 	InvoiceAmount: 0,
// 	currecy: "NTD",
// 	OrderNumber: "",
// 	tax: 0,
// 	beforeTax: "0",
// 	MemberID: "",
// 	ProducerID: "",
// 	TotalPrice: 0,
// 	Timestamp: ""
// }

async function putInvoice(invoiceEntity) {
    
    let result;
    let oldEntity = invoiceEntity;
    if (invoiceEntity) {

        invoiceEntity.PK = "Invoice";
        invoiceEntity.Type = "Invoice";

        if (invoiceEntity.SK) {
            if (invoiceEntity.SK.length > 0) {
                // do nothing
            } else {
                if (invoiceEntity.InvoiceNumber){
                    if (invoiceEntity.InvoiceNumber.length > 0) {
                        invoiceEntity.SK = "I#"+invoiceEntity.InvoiceNumber
                    }
                }
            }
        } else {
            if (invoiceEntity.InvoiceNumber){
                if (invoiceEntity.InvoiceNumber.length > 0) {
                    invoiceEntity.SK = "I#"+invoiceEntity.InvoiceNumber
                }
            }
        }

        if (invoiceEntity.PK && invoiceEntity.SK && invoiceEntity.InvoiceNumber) {
            if (invoiceEntity.PK.length > 0 && invoiceEntity.SK.length > 0 && invoiceEntity.InvoiceNumber.length > 0) {
                result = await putItem(invoiceEntity);
            }
        }
    }
    if (result) {
        return invoiceEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putInvoice;
import React from "react";

function ftiwen(props) {
	return (<>
		<div className="ftiwen">
			<div className="wrap">
				<div
					className="ftitle wow fadeInUp"
					data-wow-delay="0.2s"
					data-wow-duration="1s"
				>
					<h2>Q&A</h2>
					<img
						src={require("assets/LandingPage/images/fico5.png").default}
						alt=""
					/>
				</div>
				<div className="list">
					<ul>
						<li
							className=" wow fadeInUp"
							data-wow-delay="0.3s"
							data-wow-duration="1s"
						>
							<h2>問：VideoStores平台能上架什麼樣的影片?</h2>
							<div className="fnli">
								<p>
									答：VideoStores是一個單純的服務平台，提供影片創造者上架影音內容，僅會審核是否涉及情色影片，上架作品需是原創，相關版權使用需合法。
								</p>
							</div>
						</li>
						<li
							className=" wow fadeInUp"
							data-wow-delay="0.3s"
							data-wow-duration="1s"
						>
							<h2>
								問：加入VideoStores平台創作伙伴後，創作者將能使用平台上何種營利功能?
							</h2>
							<div className="fnli">
								<p>
									答：VideoStores是一個單純的服務平台，提供影片創造者上架影音內容，僅會審核是否涉及情色影片，上架作品需是原創，相關版權使用需合法。
								</p>
							</div>
						</li>
						<li
							className=" wow fadeInUp"
							data-wow-delay="0.3s"
							data-wow-duration="1s"
						>
							<h2>問：如何成為合作伙伴?加入創作者的資格條件?</h2>
							<div className="fnli">
								<p>
									答：VideoStores是一個單純的服務平台，提供影片創造者上架影音內容，僅會審核是否涉及情色影片，上架作品需是原創，相關版權使用需合法。
								</p>
							</div>
						</li>
						<li
							className=" wow fadeInUp"
							data-wow-delay="0.3s"
							data-wow-duration="1s"
						>
							<h2>問：可否提供免費觀看的創作影片?</h2>
							<div className="fnli">
								<p>
									答：VideoStores是一個單純的服務平台，提供影片創造者上架影音內容，僅會審核是否涉及情色影片，上架作品需是原創，相關版權使用需合法。
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>;
	</>)
}


export default ftiwen
import React from "react";
// react plugin used to create DropdownMenu for selecting items

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

import FixedTransparentNavbar from "./../components/Navbars/FixedTransparentNavbar.js";
import AboutUsHeader from "./AboutUsHeader.js";
import Footer from "./../components/Footers/Footer.js";

function AboutUs() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

      // clean at componentWillUnmount
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      
      {/* <FixedTransparentNavbar /> */}
      <div className="wrapper">
        {/* <AboutUsHeader /> */}
        <div className="section pt-0 py-0" style={{fontSize:`18px`}}>
          <div className=" text-center">
            <div className="features-3 py-0">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="8">
                    <h2 className="title">簡單。容易。快速。</h2>
                    <h4 className="description">
                    我們提供給您能收費的影音平台，保護您的創作，只要您的作品能讓粉絲願意訂閱或是贊助，就可以換成相對應的收入。
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-success icon-circle">
                        <i className="now-ui-icons objects_globe"></i>
                      </div>
                      <h4 className="info-title">影片商品管理</h4>
                      <p className="description">
                        簡單影視商品上架後台，自建商品資訊和上傳影片，即可開始銷售!
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-info icon-circle">
                        <i className="now-ui-icons education_atom"></i>
                      </div>
                      <h4 className="info-title">影片自訂售價</h4>
                      <p className="description">
                        自訂您的影視產品價格，將提供單影片、影集系列、課程影片、以及包月套餐。
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-primary icon-circle">
                        <i className="now-ui-icons tech_watch-time"></i>
                      </div>
                      <h4 className="info-title">即時隨訂隨撥</h4>
                      <p className="description">
                        隨選隨撥銷售服務，透過雲端影視產品撥放控制平台僅提供給予付費會員使用。
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="projects-5 py-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-left" md="8">
                  <h2 className="title">關於 VideoStores.io</h2>
                  <h4 className="description">
                  202X 年代，影音、影集、教學等等影視商品已經成為個人或是企業專業人員在娛樂休閒、資訊來源、以及線上學習的重要渠道。 隨著各領域的專家在社群、YT 平台衝刺出高粉絲數量和流量來提升知名度，然而，好的影視作品卻無法直接轉換成營收。透過影視新零售概念成形，把個人化的影視服務內建電商和影視撥放控制的功能，有望帶來新世代的創新收入，並進一步聯合帶動出更高的創意提升，這便是 VideoStores （簡稱 VS）。
                  </h4>
                  <h4 className="description">
                  VS 能帶給各領域的菁英創作者們，將流量轉換成收入這件事給簡單化。  VS 將影視服務零售化，給擅長創作影視服務的團隊更簡單的專屬創作內容。不論影視、節目、影集，或是專業課程，都能建立起高流量的社群，用來轉換成製作更多精彩內容的動力。
                  </h4>
                  <div className="section-space"></div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/project8.jpg").default +
                        ")",
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">影品數字分析</CardTitle>
                      <p className="card-description">
                        即將提供銷售數據分析、觀看分析，可了解用戶觀看紀錄，付費用戶評價，讓您更了解您的用戶喜好，幫助製作更有感內容，提升銷售業績
                      </p>
                      <Badge className="badge-neutral">Big Data</Badge>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons ui-2_chat-round"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        受眾分類報告
                      </h4>
                      <p className="description">
                        即將提供瀏覽和銷售分眾報告，幫您更了解誰是您的用戶。
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons business_chart-pie-36"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">付費影視排行榜</h4>
                      <p className="description">
                        即將提供各影品內容分析排行榜，讓您了解各類別觀看用戶內容分析。
                        reports.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons design-2_ruler-pencil"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">內容大數據</h4>
                      <p className="description">
                        即將提供各影品內容觀賞分析，可了解客戶觀賞喜好習慣，快轉還是重複觀看，讓您更了解用戶行為。
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
            </Container>
          </div>
          <div className="about-office py-0">
            <Container>
              <Row className="text-left">
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">關於 翔偉資安科技</h2>
                  <h4 className="description">
                    翔偉資安科技為台灣資訊安全服務商，源2004年投入資訊安全系統整合供應領域後，致力於提供企業架構規劃系統服務、資訊安全應用整合與軟體開發服務，同時代理國際知名應用系統與網路安全相關產品。
                    <ul className="py-2">
                      <li className="pt-1" style={{listStyle:`disc`}}>
                        2005年首度將防毒品牌卡巴斯基 Kaspersky Lab 和其系列產品產品引進台灣，成為國內少數能提供全方位端點安全整合服務的供應商。
                      </li>
                      <li className="pt-1" style={{listStyle:`disc`}}>
                        2007年正式取得國際知名品牌系統安全軟體 F-Secure 芬-安全大中華區總代理授權，以全球最大雲端防毒技術芬-安全為技術後盾，提供企業用戶專業進階防護服務，並協助整合系統安全平台，與芬-安全全球病毒實驗室24 小時零時差連線，同步監控網路威脅，提供用戶全方位、零時差的資安服務。芬安全產品服務保護企業、政府機關、教育單位擁有世界一等資訊安全管理保護機制，並提供個人用戶電腦與行動上網安全，降低木馬、詐欺、行動裝置遺失被駭帶來的損失，提供全面性防護。
                      </li>
                      <li className="pt-1" style={{listStyle:`disc`}}>
                      2015年增加網路安全技術專業，提供企業用戶聯網安全防護，並致力成為企業資安重要策略夥伴。
                      </li>
                      <li className="pt-1" style={{listStyle:`disc`}}>
                      2017年增加TLS加密安全顧問服務，和台灣聯凱衛生醫管研究協會合作，並取得BSI TR-03116-4 SSL/TLS 資訊交換安全檢測認證。
                        </li>
                        <li className="pt-1" style={{listStyle:`disc`}}>
                        2019 年啟動資安顧問服務於 亞太區域遊戲產業、多媒體影視內容製作產業、第三方支付公司、區塊鏈領域技術公司，提供雲技術資安架構和程式碼安全檢視規範諮詢。
                        </li>
                        <li className="pt-1" style={{listStyle:`disc`}}>
                        2021 年啟動高科技製造業、多媒體文創產業媒體公關資安事件處理，提供高階主管資安公關顧問服務以及教育訓練。
                        </li>
                        <li className="pt-1" style={{listStyle:`disc`}}>
                        2023 年啟動 VideoStores 影視撥放和銷售平台，協助影音新創建立自有運營收費內容頻道，更專注於內容創作，免複雜技術和資安投入，將專業及知名度轉換成營收。
                        </li>
                    </ul>
                  </h4>
                </Col>
              </Row>
              
            </Container>
          </div>

          {/* <div className="about-office py-0">
            <Container>
              <Row className="text-left">
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">聯絡方式</h2>
                  <h4 className="description">
                    <p>合作聯繫:  <a href="mailto:contact@videostores.io" style={{ color: "#9a9a9a" }}>contact@videostores.io</a></p>
                    <p>會員:  <a href="mailto:members@videostores.io" style={{ color: "#9a9a9a" }}>members@videostores.io</a></p>
                    <p>影品供應商:  <a href="mailto:supports@videostores.io" style={{ color: "#9a9a9a" }}>supports@videostores.io</a></p>
                  </h4>
                </Col>
              </Row>
            </Container>
          </div> */}

        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default AboutUs;

import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

// {
//     "PK": "P#test",
//     "SK": "COMP#test",
//     "TYPE": "P_PrivacyComp",
//     "email": "ginady@gmail.com",
//     "fullname": "",
//     "phone": "111",
//     "reg_name": "",
//     "SID": "",
//   }

async function putProducerProfileCOMP(producerProfileCOMPEntity) {
    
    let result;
    let oldEntity = producerProfileCOMPEntity;
    if (producerProfileCOMPEntity) {

        producerProfileCOMPEntity.TYPE = "P_PrivacyCOMP";

        if (producerProfileCOMPEntity.PK && producerProfileCOMPEntity.SK) {
            if (producerProfileCOMPEntity.PK.length > 0 && producerProfileCOMPEntity.SK.length > 0) {
                
                result = await putItem(producerProfileCOMPEntity);
            }
        }
    }
    if (result) {
        return producerProfileCOMPEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putProducerProfileCOMP;
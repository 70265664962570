class VideoInfoEntity {

    constructor(ProducerID, ProductType, ProductSKU) {
        this.PK = (ProducerID ? ((ProducerID.length > 0) ? ("VP#"+ProducerID):""):"");
        if (ProductType && ProductSKU) {
            if (ProductType.length > 0 && ProductSKU.length > 0) {
                this.SK = ProductType+"#"+ProductSKU;
                this.ProductType = ProductType;
                this.ProductSKU = ProductSKU;
            } else {
                this.SK = "";
                this.ProductType = "";
                this.ProductSKU = "";
            }
        } else {
            this.SK = "";
            this.ProductType = "";
            this.ProductSKU = "";
        }
        this.ProducerID = ProducerID ? ProducerID:"";
        this.Type = "V_Main";
        // this.CreateAt = "";
        // this.Active = false;
        // this.Delete = false;
        // this.Buys = 0;
        // this.UpdateBy = "";
        // this.UpdateAt = "";
        // this.VApply = "";
        // this.VApprove = "";
        // this.approval = false;
        // this.Views = 0;
        // this.VPName = "";
    }

    changeProductTypeSKU(ProductType, ProductSKU) {
        if (ProductType && ProductSKU) {
            if (ProductType.length > 0 && ProductSKU.length > 0) {
                this.SK = ProductType+"#"+ProductSKU;
                this.ProductType = ProductType;
                this.ProductSKU = ProductSKU;
            } else {
                this.SK = "";
                this.ProductType = "";
                this.ProductSKU = "";
            }
        } else {
            this.SK = "";
            this.ProductType = "";
            this.ProductSKU = "";
        }
    }
    
    changeProducerID(ProducerID) {
        this.PK = (ProducerID ? ((ProducerID.length > 0) ? ("VP#"+ProducerID):""):"");
        this.ProducerID = ProducerID ? ProducerID:"";
    }
    
    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK,
            "Type": "V_Main",
            "ProducerID": this.ProducerID,
            "ProductType": this.ProductType,
            "ProductSKU": this.ProductSKU
        };
        if (this.CreateAt) {
            obj["CreateAt"] = this.CreateAt;
        }
        if (this.Active !== undefined && this.Active !== null) {
            if (this.Active) {
                obj["Active"] = true;
            } else {
                obj["Active"] = false;
            }
        }
        if (this.Delete !== undefined && this.Delete !== null) {
            if (this.Delete) {
                obj["Delete"] = true;
            } else {
                obj["Delete"] = false;
            }
        }
        if (this.Buys !== undefined && this.Buys !== null) {
            obj["Buys"] = Math.floor(this.Buys);
            if (isNaN(obj["Buys"])) {
                obj["Buys"] = 0;
            }
        }
        // if (this.Price) {
        //     obj["Price"] = this.Price;
        // }
        if (this.UpdateBy) {
            obj["UpdateBy"] = this.UpdateBy;
        }
        if (this.UpdateAt) {
            obj["UpdateAt"] = this.UpdateAt;
        }
        if (this.VApply) {
            obj["VApply"] = this.VApply;
        }
        if (this.VApprove) {
            obj["VApprove"] = this.VApprove;
        }
        if (this.approval !== undefined && this.approval !== null) {
            if (this.approval) {
                obj["approval"] = true;
            } else {
                obj["approval"] = false;
            }
        }
        if (this.Views !== undefined && this.Views !== null) {
            obj["Views"] = Math.floor(this.Views);
            if (isNaN(obj["Views"])) {
                obj["Views"] = 0;
            }
        }
        if (this.VPName) {
            obj["VPName"] = this.VPName;
        }
        return obj;
    }
}

const videoInfoEntityFromObject = (item) => {
    let entity = new VideoInfoEntity("", "", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    entity.Type = "V_Main";
    entity.ProducerID = item.ProducerID;
    entity.ProductType = item.ProductType;
    entity.ProductSKU = item.ProductSKU;
    if (item.CreateAt) {
        entity.CreateAt = item.CreateAt;
    }
    if (item.Active !== undefined && item.Active !== null) {
        if (item.Active) {
            entity.Active = true;
        } else {
            entity.Active = false;
        }
    }
    if (item.Delete !== undefined && item.Delete !== null) {
        if (item.Delete) {
            entity.Delete = true;
        } else {
            entity.Delete = false;
        }
    }
    if (item.Buys !== undefined && item.Buys !== null) {
        entity.Buys = Math.floor(item.Buys);
        if (isNaN(entity.Buys)) {
            entity.Buys = 0;
        }
    }
    // if (item.Price) {
    //     entity.Price = item.Price;
    // }
    if (item.UpdateBy) {
        entity.UpdateBy = item.UpdateBy;
    }
    if (item.UpdateAt) {
        entity.UpdateAt = item.UpdateAt;
    }
    if (item.VApply) {
        entity.VApply = item.VApply;
    }
    if (item.VApprove) {
        entity.VApprove = item.VApprove;
    }
    if (item.approval !== undefined && item.approval !== null) {
        if (item.approval) {
            entity.approval = true;
        } else {
            entity.approval = false;
        }
    }
    if (item.Views !== undefined && item.Views !== null) {
        entity.Views = Math.floor(item.Views);
        if (isNaN(entity.Views)) {
            entity.Views = 0;
        }
    }
    if (item.VPName) {
        entity.VPName = item.VPName;
    }
    return entity;
}

export { VideoInfoEntity, videoInfoEntityFromObject };

// {
//     "PK": "VP#coreytu",
//     "SK": "VPID#coreytu01",
//     "ProducerID": "coreytu",
//     "ProductType": "VPID",   // VPID影品, VPSID訂閱, VSID影集, VCID影課
//     "ProductSKU": "coreytu01",
//     "Type": "V_Main",
//     "CreateAt": "20210801",
//     "Active": true,
//     "Delete": false,
//     "Buys": 500,
//     "UpdateAt": 1631599834085,
//     "UpdateBy": "coreytu",
//     "VApply": "20210827",
//     "VApprove": "20210830",
//     "approval": true,
//     "Views": 5000,
//     "VPName": "鬼滅之刃劇場版"
//   }
import DataMethods from "./../data/DataMethods.js";

class VideoFileUnitEntity {

    constructor() {
        this.UnitNumber = 0;
    }
    
    toObject() {
        let obj = { };
        if (this.UnitNumber !== undefined && this.UnitNumber !== null) {
            obj["UnitNumber"] = Math.floor(this.UnitNumber);
            if (isNaN(obj["UnitNumber"])) {
                obj["UnitNumber"] = 0;
            }
        }
        if (this.UnitTitle) {
            obj["UnitTitle"] = this.UnitTitle;
        }
        if (this.UnitBrief) {
            obj["UnitBrief"] = this.UnitBrief;
        }
        if (this.UnitBucketName) {
            obj["UnitBucketName"] = this.UnitBucketName;
        }
        if (this.UnitPathAndKey) {
            obj["UnitPathAndKey"] = this.UnitPathAndKey;
        }
        if (this.UnitFileFormat) {
            obj["UnitFileFormat"] = this.UnitFileFormat;
        }
        if (this.UnitFileLengthSec !== undefined && this.UnitFileLengthSec !== null) {
            obj["UnitFileLengthSec"] = Math.floor(this.UnitFileLengthSec);
            if (isNaN(obj["UnitFileLengthSec"])) {
                obj["UnitFileLengthSec"] = 0;
            }
        }
        if (this.UnitFileSizeByte !== undefined && this.UnitFileSizeByte !== null) {
            obj["UnitFileSizeByte"] = Math.floor(this.UnitFileSizeByte);
            if (isNaN(obj["UnitFileSizeByte"])) {
                obj["UnitFileSizeByte"] = 0;
            }
        }
        if (this.UnitFileBitrateBps !== undefined && this.UnitFileBitrateBps !== null) {
            obj["UnitFileBitrateBps"] = Math.floor(this.UnitFileBitrateBps);
            if (isNaN(obj["UnitFileBitrateBps"])) {
                obj["UnitFileBitrateBps"] = 0;
            }
        }
        return obj;
    }
}

const videoFileUnitEntityFromObject = (item) => {
    let entity = new VideoFileUnitEntity();
    if (item.UnitNumber !== undefined && item.UnitNumber !== null) {
        entity.UnitNumber = Math.floor(item.UnitNumber);
        if (isNaN(entity.UnitNumber)) {
            entity.UnitNumber = 0;
        }
    }
    if (item.UnitTitle) {
        entity.UnitTitle = item.UnitTitle;
    }
    if (item.UnitBrief) {
        entity.UnitBrief = item.UnitBrief;
    }
    if (item.UnitBucketName) {
        entity.UnitBucketName = item.UnitBucketName;
    }
    if (item.UnitPathAndKey) {
        entity.UnitPathAndKey = item.UnitPathAndKey;
    }
    if (item.UnitFileFormat) {
        entity.UnitFileFormat = item.UnitFileFormat;
    }
    if (item.UnitFileLengthSec !== undefined && item.UnitFileLengthSec !== null) {
        entity.UnitFileLengthSec = Math.floor(item.UnitFileLengthSec);
        if (isNaN(entity.UnitFileLengthSec)) {
            entity.UnitFileLengthSec = 0;
        }
    }
    if (item.UnitFileSizeByte !== undefined && item.UnitFileSizeByte !== null) {
        entity.UnitFileSizeByte = Math.floor(item.UnitFileSizeByte);
        if (isNaN(entity.UnitFileSizeByte)) {
            entity.UnitFileSizeByte = 0;
        }
    }
    if (item.UnitFileBitrateBps !== undefined && item.UnitFileBitrateBps !== null) {
        entity.UnitFileBitrateBps = Math.floor(item.UnitFileBitrateBps);
        if (isNaN(entity.UnitFileBitrateBps)) {
            entity.UnitFileBitrateBps = 0;
        }
    }
    return entity;
}

export { VideoFileUnitEntity, videoFileUnitEntityFromObject };

// {
//     UnitNumber: 1,
//     UnitTitle: "",
//     UnitBrief: "",
//     UnitBucketName: "",
//     UnitPathAndKey: "",
//     UnitFileFormat: "mp4",
//     UnitFileLengthSec: 26.008333,
//     UnitFileSizeByte: 3072091,
//     UnitFileBitrateBps: 9600000
// }
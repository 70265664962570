import { deleteItem } from "./DynamodbMethods.js";

async function deleteVideoFileByPidAndVid(ProducerIDString, ProductSKUString) {
    
    let deleteRes;
    if (ProducerIDString && ProductSKUString) {
        if (ProducerIDString.length > 0 && ProductSKUString.length > 0) {
            deleteRes = await deleteItem("VP#"+ProducerIDString, "VFile#"+ProductSKUString);
        }
    }
    return deleteRes;
}
  
export default deleteVideoFileByPidAndVid;
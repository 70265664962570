import { videoFileSectionEntityFromObject } from "./VideoFileSectionEntity.js";

class VideoFileChapterEntity {
    
    constructor() {
        this.ChapterNumber = 0;
    }

    toObject() {
        let obj = { };
        if (this.ChapterNumber !== undefined && this.ChapterNumber !== null) {
            obj["ChapterNumber"] = Math.floor(this.ChapterNumber);
            if (isNaN(obj["ChapterNumber"])) {
                obj["ChapterNumber"] = 0;
            }
        }
        if (this.ChapterTitle) {
            obj["ChapterTitle"] = this.ChapterTitle;
        }
        if (this.ChapterBrief) {
            obj["ChapterBrief"] = this.ChapterBrief;
        }
        if (this.Sections) {
            obj["Sections"] = [];
            for(let i=0;i<this.Sections.length;i++) {
                this.Sections[i].SectionNumber = i + 1;
                let sectionObj = this.Sections[i].toObject();
                if (sectionObj) {
                    obj["Sections"].push(sectionObj);
                }
            }
        }
        return obj;
    }

    appendUnit(sectionEntity) {
        this.Sections.push(sectionEntity);
        for(let i=0;i<this.Sections.length;i++) {
            this.Sections[i].SectionNumber = i + 1;
        }
    }

    insertUnit(sectionEntity, atIndex) {
        this.Sections.splice(atIndex, 0, sectionEntity);
        for(let i=0;i<this.Sections.length;i++) {
            this.Sections[i].SectionNumber = i + 1;
        }
    }

    removeUnit(atIndex) {
        this.Sections.splice(atIndex, 1);
        for(let i=0;i<this.Sections.length;i++) {
            this.Sections[i].SectionNumber = i + 1;
        }
    }
}

const videoFileChapterEntityFromObject = (item) => {
    let entity = new VideoFileChapterEntity();
    if (item.ChapterNumber !== undefined && item.ChapterNumber !== null) {
        entity.ChapterNumber = Math.floor(item.ChapterNumber);
        if (isNaN(entity.ChapterNumber)) {
            entity.ChapterNumber = 0;
        }
    }
    if (item.ChapterTitle) {
        entity.ChapterTitle = item.ChapterTitle;
    }
    if (item.ChapterBrief) {
        entity.ChapterBrief = item.ChapterBrief;
    }
    if (item.Sections) {
        entity.Sections = [];
        for(let i=0;i<item.Sections.length;i++) {
            item.Sections[i].SectionNumber = i + 1;
            const sectionEnt = videoFileSectionEntityFromObject(item.Sections[i]);
            entity.Sections.push(sectionEnt);
        }
    }
    return entity;
}

export { VideoFileChapterEntity, videoFileChapterEntityFromObject };

// {
//     ChapterNumber: 1,
//     ChapterTitle: "",
//     ChapterBrief: "",
//     Sections: []
// }
import { getItem } from "./DynamodbMethods.js";

async function getMemberVideoExpire(MemberID, ProductType, ProductSKU) {
    let res;
    if (MemberID && ProductType && ProductSKU) {
        if (MemberID.length > 0 && ProductType.length > 0 && ProductSKU.length > 0) {
            res = await getItem("M#"+MemberID, ProductType+"#"+ProductSKU);
        }
    }
    return res;
}
  
export default getMemberVideoExpire;
import { queryItems } from "./DynamodbMethods.js";

async function getVideoProfilesByPid(ProducerIDString) {
    let res;
    if (ProducerIDString) {
        if (ProducerIDString.length > 0) {
            res = await queryItems("VP#"+ProducerIDString, "VProfile#");
        }
    }
    return res;
}
  
export default getVideoProfilesByPid;
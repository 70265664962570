import React from "react";

function hPage2(props) {
    return (
        <>
            <div className="hpage2">
				<div className="wrap">
					<div className="tit wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1s">
						<h2><span>立裁技法</span> - 為服裝賦予靈魂</h2>
						<p>將設計完美契合於穿著者的極致工藝</p>
					</div>
					<div className="del wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
						服裝製版的本質就是結構技法設計，是一門將設計師圖稿轉換為實體的專業工藝。一名優良的打版師必須具備<br />
						對人體機能、體型的細微洞察及研究，同時具備打版技巧的靈活應用，才能在時尚的外型設計與剪裁的內在中找到完美平衡
					</div>
					<div className="pic wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="1s">
						<img src={require("assets/FashionDraping/images/qm4.jpg").default} alt="" />
					</div>
				</div>
			</div>
        </>
    )
}

export default hPage2;
import kvnConfig from "./../views/KeyValueNameConfig.js";
import { docClientQuery } from "./AWSConfig.js";

async function getMemberProfileByLineTokenID(LineAccessToken, MemberIDString) {
    
    let obj;

    if (LineAccessToken && MemberIDString) {
        if (LineAccessToken.length > 0 && MemberIDString.length > 0) {
            
            const paramsQuery = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK",
                    "#keyAlias2": "MemberID",
                    "#keyAlias3": "LineAccessToken"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": "Members",
                    ":valueAlias2": MemberIDString,
                    ":valueAlias3": LineAccessToken
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1",
                FilterExpression: "#keyAlias2 = :valueAlias2 and #keyAlias3 = :valueAlias3"
            };
          
            const res = await docClientQuery(paramsQuery);
            if (res.Items.length > 0) {
                obj = res.Items[0];
            }
        }
    }

    return obj;
}
  
export default getMemberProfileByLineTokenID;
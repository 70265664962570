import { deleteItem } from "./DynamodbMethods.js";

async function deleteVideoInfoByPkAndPtypeAndPsku(PK, ProductTypeString, ProductSKUString) {
    let deleteRes
    if (PK && ProductTypeString && ProductSKUString) {
        if (PK.length > 0 && ProductTypeString.length > 0 && ProductSKUString.length > 0) {
            deleteRes = await deleteItem(PK, ProductTypeString+"#"+ProductSKUString);
        }
    }
    return deleteRes;
}
  
export default deleteVideoInfoByPkAndPtypeAndPsku;
import {
    docClientGet, docClientPut, docClientUpdate, docClientDelete, docClientQuery, docClientTransactWrite
} from "./AWSConfig.js";
import kvnConfig from "../views/KeyValueNameConfig.js";

const transactWriteItems = (putItemEntities, updateItemEntities, deleteItemEntities) => {
    
    let wirteItemsParam =  {
        TransactItems:[],
        ReturnConsumedCapacity: "INDEXES",
        ReturnItemCollectionMetrics: "SIZE"
    }

    if (putItemEntities) {
        for(let i=0;i<putItemEntities.length;i++) {
            if (putItemEntities[i].PK && putItemEntities[i].SK) {
                if (putItemEntities[i].PK.length > 0 && putItemEntities[i].SK.length > 0) {
                    const putItemParam = {
                        Put: {
                            TableName : kvnConfig.dbMainTableName,
                            Item: putItemEntities[i].toObject(),
                            ReturnValuesOnConditionCheckFailure: "ALL_OLD"
                        }
                    };
                    wirteItemsParam.TransactItems.push(putItemParam);
                }
            }
        }
    }
    
    if (updateItemEntities) {
        for(let x=0;x<updateItemEntities.length;x++) {
            if (updateItemEntities[x].PK && updateItemEntities[x].SK) {
                if (updateItemEntities[x].PK.length > 0 && updateItemEntities[x].SK.length > 0) {
                    const itemJson = updateItemEntities[x].toObject();
                    const keys = Object.keys(itemJson);
                    let attKeys = {};
                    let attValues = {};
                    for(let m=0;m<keys.length;m++) {
                        if (keys[m] !== "PK" && keys[m] !== "SK") {
                            attKeys["#kwd"+m] = keys[m];
                            const value = itemJson[keys[m]];
                            if (value) {
                                attValues[":att"+m] = value;
                            } else {
                                if (typeof value === 'boolean') {
                                    attValues[":att"+m] = false;
                                } else {
                                    attValues[":att"+m] = "";
                                }
                            }
                        }
                    }
                    let updateExp = "";
                    for(let i=0;i<keys.length;i++) {
                        if (keys[i] !== "PK" && keys[i] !== "SK") {
                            if (updateExp.length === 0) {
                                updateExp = updateExp + "set #kwd" + i + "=:att" + i;
                            } else {
                                updateExp = updateExp + ", #kwd" + i + "=:att" + i;
                            }
                        }
                    }
                    
                    const updateItemParam = {
                        Update: {
                            TableName : kvnConfig.dbMainTableName,
                            Key: {
                                PK: updateItemEntities[x].PK,
                                SK: updateItemEntities[x].SK
                            },
                            ExpressionAttributeNames: attKeys,
                            ExpressionAttributeValues:attValues,
                            UpdateExpression: updateExp,
                            ReturnValuesOnConditionCheckFailure: "ALL_OLD"
                        }
                    };
                    wirteItemsParam.TransactItems.push(updateItemParam);
                }
            }
        }
    }

    if (deleteItemEntities) {
        for(let x=0;x<deleteItemEntities.length;x++) {
            if (deleteItemEntities[x].PK && deleteItemEntities[x].SK) {
                if (deleteItemEntities[x].PK.length > 0 && deleteItemEntities[x].SK.length > 0) {
                    const deleteItemParam = {
                        Delete: {
                            TableName : kvnConfig.dbMainTableName,
                            Key: {
                                PK: deleteItemEntities[x].PK,
                                SK: deleteItemEntities[x].SK
                            },
                            ReturnValuesOnConditionCheckFailure: "ALL_OLD"
                        }
                    };
                    wirteItemsParam.TransactItems.push(deleteItemParam);
                }
            }
        }
    }
    
    return docClientTransactWrite(wirteItemsParam);
};

const putItem = async (itemEntity) => {
    let putData;
    if (itemEntity.PK && itemEntity.SK) {
        if (itemEntity.PK.length > 0 && itemEntity.SK.length > 0) {
            const params = {
                TableName : kvnConfig.dbMainTableName,
                Item: itemEntity.toObject()
            };
            putData = await docClientPut(params);
        }
    }

    return putData;
};

const putItem_V2 = async (itemEntity) => {
    let putData;
    if (itemEntity.PK && itemEntity.SK) {
        if (itemEntity.PK.length > 0 && itemEntity.SK.length > 0) {
            const params = {
                TableName : kvnConfig.dbMainTableName,
                Item: itemEntity
            };
            putData = await docClientPut(params);
        }
    }

    return putData;
};


const updateItem = async (itemEntity) => {

    let updateRes;

    if (itemEntity.PK && itemEntity.SK) {
        if (itemEntity.PK.length > 0 && itemEntity.SK.length > 0) {
            const itemJson = itemEntity.toObject();
            const keys = Object.keys(itemJson);
            let attKeys = {};
            let attValues = {};
            for(let m=0;m<keys.length;m++) {
                if (keys[m] !== "PK" && keys[m] !== "SK") {
                    attKeys["#kwd"+m] = keys[m];
                    const value = itemJson[keys[m]];
                    if (value) {
                        attValues[":att"+m] = value;
                    } else {
                        if (typeof value === 'boolean') {
                            attValues[":att"+m] = false;
                        } else {
                            attValues[":att"+m] = "";
                        }
                    }
                }
            }
            let updateExp = "";
            for(let i=0;i<keys.length;i++) {
                if (keys[i] !== "PK" && keys[i] !== "SK") {
                    if (updateExp.length === 0) {
                        updateExp = updateExp + "set #kwd" + i + "=:att" + i;
                    } else {
                        updateExp = updateExp + ", #kwd" + i + "=:att" + i;
                    }
                }
            }
            const params = {
                TableName : kvnConfig.dbMainTableName,
                Key: {
                    PK: itemEntity.PK,
                    SK: itemEntity.SK
                },
                ExpressionAttributeNames: attKeys,
                ExpressionAttributeValues:attValues,
                UpdateExpression: updateExp,
                ReturnValues:"ALL_NEW",
            };

            updateRes = await docClientUpdate(params);
        }
    }

    return updateRes;
};

const deleteItem = async (pkFull, skFull) => {

    let result;

    if (pkFull && skFull) {
        if (pkFull.length > 0 && skFull.length > 0) {
        
            const params = {
                TableName : kvnConfig.dbMainTableName,
                Key: {
                    PK: pkFull,
                    SK: skFull
                }
            };

            result = await docClientDelete(params);
        }
    }
    return result;
};

const getItem = async (pkFull, skFull) => {

    let result;

    if (pkFull && skFull) {
        if (pkFull.length > 0 && skFull.length > 0) {
        
            const params = {
                TableName : kvnConfig.dbMainTableName,
                Key: {
                    PK: pkFull,
                    SK: skFull
                }
            };

            const resItem = await docClientGet(params);
            if (resItem) {
                if (resItem.Item) {
                    result = resItem.Item;
                }
            }
        }
    }
    
    return result;
};

const queryItems = async (pkFull, skPrefixOptional) => {

    let result = {Items:[]};

    if (pkFull) {
        if (pkFull.length > 0) {
            let params = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": pkFull
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1"
            };
            
            if (skPrefixOptional) {
                if (skPrefixOptional.length > 0) {
                    params = {
                        TableName : kvnConfig.dbMainTableName,
                        ExpressionAttributeNames: {
                            "#keyAlias1": "PK",
                            "#keyAlias2": "SK"
                        },
                        ExpressionAttributeValues: {
                            ":valueAlias1": pkFull,
                            ":valueAlias2": skPrefixOptional
                        },
                        // 必須將分割區索引鍵名稱及數值指定為相等條件(#keyAlias1 = :valueAlias1)
                        // a = b, a < b, a <= b, a > b, a >= b, a BETWEEN b AND c, begins_with (a, substr)
                        KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)"
                        // ,FilterExpression: "#keyAlias3 = :valueAlias3"
                        // ,ScanIndexForward: false // 預設true表示升序
                        // ,Limit: '100' // 限制總資料筆數
                        // ,ConditionExpression: "#keyAlias1 <> :valueAlias1" // 限制條件，條件成立才執行( <> 表示不等於 )
                        // ,ExclusiveStartKey: "提取下一分頁所需參數，由上一次執行結果中取得LastEvaluatedKey，上次結果有此值才表示有下一頁，超過1MB會分頁"
                    };
                }
            }
        
            const resItems = await docClientQuery(params);
        
            if (resItems) {
                if (resItems.Items) {
                    result = {Items:resItems.Items}
                }
            }
        
            if (result.Items.length === 0) {
                if (resItems) {
                    if (resItems.Item) {
                        result = {Items:[resItems.Item]}
                    }
                }
            }
        }
    }
    
    return result;
};

const queryItemsDesc = async (pkFull, skPrefixOptional) => {

    let result = {Items:[]};

    if (pkFull) {
        if (pkFull.length > 0) {
            let params = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": pkFull
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1"
            };
            
            if (skPrefixOptional) {
                if (skPrefixOptional.length > 0) {
                    params = {
                        TableName : kvnConfig.dbMainTableName,
                        ExpressionAttributeNames: {
                            "#keyAlias1": "PK",
                            "#keyAlias2": "SK"
                        },
                        ExpressionAttributeValues: {
                            ":valueAlias1": pkFull,
                            ":valueAlias2": skPrefixOptional
                        },
                        // 必須將分割區索引鍵名稱及數值指定為相等條件(#keyAlias1 = :valueAlias1)
                        // a = b, a < b, a <= b, a > b, a >= b, a BETWEEN b AND c, begins_with (a, substr)
                        KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)"
                        // ,FilterExpression: "#keyAlias3 = :valueAlias3"
                        ,ScanIndexForward: false // 預設true表示升序
                        // ,Limit: '100' // 限制總資料筆數
                        // ,ConditionExpression: "#keyAlias1 <> :valueAlias1" // 限制條件，條件成立才執行( <> 表示不等於 )
                        // ,ExclusiveStartKey: "提取下一分頁所需參數，由上一次執行結果中取得LastEvaluatedKey，上次結果有此值才表示有下一頁，超過1MB會分頁"
                    };
                }
            }
        
            const resItems = await docClientQuery(params);
        
            if (resItems) {
                if (resItems.Items) {
                    result = {Items:resItems.Items}
                }
            }
        
            if (result.Items.length === 0) {
                if (resItems) {
                    if (resItems.Item) {
                        result = {Items:[resItems.Item]}
                    }
                }
            }
        }
    }
    
    return result;
};

const getFirstItem = async (pkFull, skPrefixOptional) => {

    let result;

    if (pkFull) {
        if (pkFull.length > 0) {

            let params = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": pkFull
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1",
                Limit: 1
            };
            
            if (skPrefixOptional) {
                if (skPrefixOptional.length > 0) {
                    params = {
                        TableName : kvnConfig.dbMainTableName,
                        ExpressionAttributeNames: {
                            "#keyAlias1": "PK",
                            "#keyAlias2": "SK"
                        },
                        ExpressionAttributeValues: {
                            ":valueAlias1": pkFull,
                            ":valueAlias2": skPrefixOptional
                        },
                        KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                        Limit: 1
                    };
                }
            }

            const resItems = await docClientQuery(params);

            if (resItems) {
                if (resItems.Items) {
                    if (resItems.Items.length > 0) {
                        result = resItems.Items[0];
                    }
                }
            }

            if (result) { } else {
                if (resItems) {
                    if (resItems.Item) {
                        result = resItems.Item;
                    }
                }
            }
        }
    }
    
    return result;
};

const getLastItem = async (pkFull, skPrefixOptional) => {

    let result;
    if (pkFull) {
        if (pkFull.length > 0) {

            let params = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": pkFull
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1",
                ScanIndexForward: false,
                Limit: 1
            };
            
            if (skPrefixOptional) {
                if (skPrefixOptional.length > 0) {
                    params = {
                        TableName : kvnConfig.dbMainTableName,
                        ExpressionAttributeNames: {
                            "#keyAlias1": "PK",
                            "#keyAlias2": "SK"
                        },
                        ExpressionAttributeValues: {
                            ":valueAlias1": pkFull,
                            ":valueAlias2": skPrefixOptional
                        },
                        KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                        ScanIndexForward: false,
                        Limit: 1
                    };
                }
            }
    
            const resItems = await docClientQuery(params);

            if (resItems) {
                if (resItems.Items) {
                    if (resItems.Items.length > 0) {
                        result = resItems.Items[0];
                    }
                }
            }

            if (result) { } else {
                if (resItems) {
                    if (resItems.Item) {
                        result = resItems.Item;
                    }
                }
            }
        }
    }
    return result;
};

export { transactWriteItems, getItem, getFirstItem, getLastItem, putItem,putItem_V2, updateItem, deleteItem, queryItems, queryItemsDesc };
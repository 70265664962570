import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

async function putVideoPrice(videoPriceEntity) {
    
    let result;
    let oldEntity = videoPriceEntity;
    if (videoPriceEntity) {

        if (videoPriceEntity.PK) {
            if (videoPriceEntity.PK.length > 0) {
                // do nothing
            } else {
                if (videoPriceEntity.ProductSKU){
                    if (videoPriceEntity.ProductSKU.length > 0) {
                        videoPriceEntity.PK = "VP#"+videoPriceEntity.ProductSKU
                    }
                }
            }
        } else {
            if (videoPriceEntity.ProductSKU){
                if (videoPriceEntity.ProductSKU.length > 0) {
                    videoPriceEntity.PK = "VP#"+videoPriceEntity.ProductSKU
                }
            }
        }

        if (videoPriceEntity.SK) {
            if (videoPriceEntity.SK.length > 0) {
                // do nothing
            } else {
                if (videoPriceEntity.unitPrice){
                    if (videoPriceEntity.unitPrice >= 0) {
                        videoPriceEntity.SK = "unitPrice#"+videoPriceEntity.unitPrice
                    }
                }
            }
        } else {
            if (videoPriceEntity.unitPrice){
                if (videoPriceEntity.unitPrice >= 0) {
                    videoPriceEntity.SK = "unitPrice#"+videoPriceEntity.unitPrice
                }
            }
        }

        videoPriceEntity.TYPE = "VP_Price";

        if (videoPriceEntity.PK && videoPriceEntity.SK && videoPriceEntity.ProductSKU && videoPriceEntity.unitPrice) {
            if (videoPriceEntity.PK.length > 0 && videoPriceEntity.SK.length > 0 && videoPriceEntity.ProductSKU.length > 0 && videoPriceEntity.unitPrice >= 0) {
                
                result = await putItem(videoPriceEntity);
            }
        }
    }
    if (result) {
        return videoPriceEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putVideoPrice;
import { videoFileUnitEntityFromObject } from "./VideoFileUnitEntity.js";

class VideoFileSectionEntity {

    constructor() {
        this.SectionNumber = 0;
    }

    toObject() {
        let obj = { };
        if (this.SectionNumber !== undefined && this.SectionNumber !== null) {
            obj["SectionNumber"] = Math.floor(this.SectionNumber);
            if (isNaN(obj["SectionNumber"])) {
                obj["SectionNumber"] = 0;
            }
        }
        if (this.SectionTitle) {
            obj["SectionTitle"] = this.SectionTitle;
        }
        if (this.SectionBrief) {
            obj["SectionBrief"] = this.SectionBrief;
        }
        if (this.Units) {
            obj["Units"] = [];
            for(let i=0;i<this.Units.length;i++) {
                this.Units[i].UnitNumber = i + 1;
                let unitObj = this.Units[i].toObject();
                if (unitObj) {
                    obj["Units"].push(unitObj);
                }
            }
        }
        return obj;
    }

    appendUnit(unitEntity) {
        this.Units.push(unitEntity);
        for(let i=0;i<this.Units.length;i++) {
            this.Units[i].UnitNumber = i + 1;
        }
    }

    insertUnit(unitEntity, atIndex) {
        this.Units.splice(atIndex, 0, unitEntity);
        for(let i=0;i<this.Units.length;i++) {
            this.Units[i].UnitNumber = i + 1;
        }
    }

    removeUnit(atIndex) {
        this.Units.splice(atIndex, 1);
        for(let i=0;i<this.Units.length;i++) {
            this.Units[i].UnitNumber = i + 1;
        }
    }
}

const videoFileSectionEntityFromObject = (item) => {
    let entity = new VideoFileSectionEntity();
    if (item.SectionNumber !== undefined && item.SectionNumber !== null) {
        entity.SectionNumber = Math.floor(item.SectionNumber);
        if (isNaN(entity.SectionNumber)) {
            entity.SectionNumber = 0;
        }
    }
    if (item.SectionTitle) {
        entity.SectionTitle = item.SectionTitle;
    }
    if (item.SectionBrief) {
        entity.SectionBrief = item.SectionBrief;
    }
    if (item.Units) {
        entity.Units = [];
        for(let i=0;i<item.Units.length;i++) {
            item.Units[i].UnitNumber = i + 1;
            const unitEnt = videoFileUnitEntityFromObject(item.Units[i]);
            entity.Units.push(unitEnt);
        }
    }
    return entity;
}

export { VideoFileSectionEntity, videoFileSectionEntityFromObject };

// {
//     SectionNumber: 1,
//     SectionTitle: "",
//     SectionBrief: "",
//     Units: []
// }
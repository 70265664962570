import kvnConfig from "./../views/KeyValueNameConfig.js";

const AWS = require('aws-sdk');
AWS.config.update({
    region: kvnConfig.awsRegion,
    accessKeyId: kvnConfig.adminAccessKeyId,
    secretAccessKey: kvnConfig.adminSecretAccessKey
});
const dynamodb = new AWS.DynamoDB({apiVersion: '2012-08-10'}); // {apiVersion: '2011-12-05'}
const docClient = new AWS.DynamoDB.DocumentClient();
const s3 = new AWS.S3({apiVersion: '2006-03-01'});
const lambda = new AWS.Lambda({apiVersion: '2015-03-31'});

async function docClientGet(params) {
    return new Promise((resolve, reject) => {
        docClient.get(params, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}

async function docClientPut(params) {
    return new Promise((resolve, reject) => {
        docClient.put(params, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}

async function docClientUpdate(params) {
    return new Promise((resolve, reject) => {
        docClient.update(params, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}

async function docClientDelete(params) {
    return new Promise((resolve, reject) => {
        docClient.delete(params, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}

async function docClientQuery(params) {
    return new Promise((resolve, reject) => {
        docClient.query(params, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}

async function docClientTransactWrite(transactWriteItems) {
    return new Promise((resolve, reject) => {
        docClient.transactWrite(transactWriteItems, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}

async function lambdaInvoke(params) {
    return new Promise((resolve, reject) => {
        lambda.invoke(params, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}



export {
    AWS, dynamodb, docClient, s3, lambda,
    docClientGet, docClientPut, docClientUpdate, docClientDelete,
    docClientQuery, docClientTransactWrite,
    lambdaInvoke
};
import { getItem } from "./DynamodbMethods.js";

async function getVideoProductionByPidAndVid(ProducerIDString, ProductSKUString) {
    let res;
    if (ProducerIDString && ProductSKUString) {
        if (ProducerIDString.length > 0 && ProductSKUString.length > 0) {
            res = await getItem("VP#"+ProducerIDString, "VProduction#"+ProductSKUString);
        }
    }
    return res;
}
  
export default getVideoProductionByPidAndVid;
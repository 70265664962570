import React from "react";

function fplatform(props){
    return (<>
    	<div className="fplatform">
			<div className="wrap">
				<div className="list">
					<ul>
						<li className=" wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
							<div className="ico">
								<img src={require("assets/LandingPage/images/fico1.png").default} alt="" />
							</div>
							<div className="tet">
								<h2>平台服務特色介紹說明</h2>
								<h6>Introduction to platform service features</h6>
								<p>VideoStores平台，任何影音創作家都可以申請加入，沒有嚴苛的限制條件，
								可讓所有的影音創作者將即有或是新創的內容自行上架後，自己設定收費的金額及觀看限制，
								無需受制於平台商的規範要求，「我」就是自己的老闆，是VideoStores的原創精神。</p>
							</div>
						</li>
						<li className=" wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
							<div className="ico">
								<img src={require("assets/LandingPage/images/fico2.png").default} alt=""/>
							</div>
							<div className="tet">
								<h2>本服務有那些功能</h2>
								<h6>What are the functions of this service</h6>
								<p>VideoStores平台能提供給所有微創家及微網紅一個無法上架到大型平台收費的機會，
								讓每個創意及每個投資與付出都能直接得到實質的回饋。</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
    </>)
}

export default fplatform;
import { getItem } from "./DynamodbMethods.js";

async function getVideoPriceByVidAndPrice(ProductSKUString, priceInt) {
    let res;
    if (ProductSKUString && priceInt) {
        if (ProductSKUString.length > 0 && priceInt >= 0) {
            res = await getItem("VP#"+ProductSKUString, "unitPrice#"+priceInt);
        }
    }
    return res;
}
  
export default getVideoPriceByVidAndPrice;
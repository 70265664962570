import { getItem } from "./DynamodbMethods.js";

async function getVideoFileByPkAndVid(PK, ProductSKUString) {
    let res;
    if (PK && ProductSKUString) {
        if (PK.length > 0 && ProductSKUString.length > 0) {
            res = await getItem(PK, "VFile#"+ProductSKUString);
        }
    }
    return res;
}
  
export default getVideoFileByPkAndVid;
import { deleteItem } from "./DynamodbMethods.js";

async function deleteOrderByOid(orderNumber) {
    let deleteRes;
    if (orderNumber) {
        if (orderNumber.length > 0) {
            deleteRes = await deleteItem("Orders", "O#"+orderNumber);
        }
    }
    return deleteRes;
}
  
export default deleteOrderByOid;
import { updateItem } from "./DynamodbMethods.js";
import getMemberProfileByLineTokenID from "./getMemberProfileByLineTokenID.js";

async function updateMemberProfileWithoutLogo(lineAccessToken, memberId, newMemberProfileEntity) {
    let oldMemberProfileObject = await getMemberProfileByLineTokenID(lineAccessToken, memberId);
    if (oldMemberProfileObject && newMemberProfileEntity) {
      if (oldMemberProfileObject.PK && newMemberProfileEntity.PK && oldMemberProfileObject.SK && newMemberProfileEntity.SK) {
        if (oldMemberProfileObject.PK === newMemberProfileEntity.PK && oldMemberProfileObject.SK === newMemberProfileEntity.SK) {
          newMemberProfileEntity.BucketName = null;
          newMemberProfileEntity.PathAndKey = null;
          return await updateItem(newMemberProfileEntity);
        }
      }
    }
    return;
};
  
export default updateMemberProfileWithoutLogo;
import DataMethods from "./../data/DataMethods.js";

class VideoFileSubEntity {
    
    constructor(ProducerID, VPID) {
        this.VPID = VPID ? VPID:"";
        this.UpdateBy = ProducerID ? ProducerID:"";
        const dt = DataMethods.dateToUTCString(new Date(), "yyyyMMddHHmmss");
        this.CreateAt = dt;
        this.UpdateAt = dt;

        // this.FileNum = 0;
        // this.Bitrate = 0;
        // this.BucketName = "";
        // this.PathAndKey = "";
        // this.Format = "";
        // this.Length = 0;
        // this.MB = 0;
    }

    changeVPID(VPID) {
        this.VPID = VPID ? VPID:"";
    }
    
    toObject() {
        let obj = {
            "VPID": this.VPID,
            "UpdateBy": this.UpdateBy
        };
        if (this.CreateAt) {
            obj["CreateAt"] = this.CreateAt;
        }
        if (this.UpdateAt) {
            obj["UpdateAt"] = this.UpdateAt;
        }
        if (this.FileNum) {
            obj["FileNum"] = this.FileNum;
        }
        if (this.Bitrate) {
            obj["Bitrate"] = this.Bitrate;
        }
        if (this.BucketName) {
            obj["BucketName"] = this.BucketName;
        }
        if (this.PathAndKey) {
            obj["PathAndKey"] = this.PathAndKey;
        }
        if (this.Format) {
            obj["Format"] = this.Format;
        }
        if (this.Length) {
            obj["Length"] = this.Length;
        }
        if (this.MB) {
            obj["MB"] = this.MB;
        }
        return obj;
    }
}

const videoFileSubEntityFromObject = (item) => {
    let entity = new VideoFileSubEntity("", "");
    entity.VPID = item.VPID;
    entity.UpdateBy = item.UpdateBy;
    if (item.CreateAt) {
        entity.CreateAt = item.CreateAt;
    }
    if (item.UpdateAt) {
        entity.UpdateAt = item.UpdateAt;
    }
    if (item.FileNum) {
        entity.FileNum = item.FileNum;
    }
    if (item.Bitrate) {
        entity.Bitrate = item.Bitrate;
    }
    if (item.BucketName) {
        entity.BucketName = item.BucketName;
    }
    if (item.PathAndKey) {
        entity.PathAndKey = item.PathAndKey;
    }
    if (item.Format) {
        entity.Format = item.Format;
    }
    if (item.Length) {
        entity.Length = item.Length;
    }
    if (item.MB) {
        entity.MB = item.MB;
    }
    return entity;
}

export { VideoFileSubEntity, videoFileSubEntityFromObject };

// {
//     "Bitrate": ".264",
//     "BucketName": "kingvideoadmin5c1ac373cd004a24869147aafb0a82fd81745-staging",
//     "CreateAt": "20210721",
//     "FileNum": 1,
//     "Format": "mp4",
//     "Length": 100,
//     "MB": 2000,
//     "PathAndKey": "VP/files/coreytu-1631761933-01.mp4",
//     "S3": "S3/KingVideo/Producers/VPFile/coreytu01-01.mp4",
//     "UpdateAt": "20210721",
//     "UpdateBy": "coreytu",
//     "VPID": "coreytu01"
//   }
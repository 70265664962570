import { transactWriteItems } from "./DynamodbMethods.js";
import kvnConfig from "./../views/KeyValueNameConfig.js";
import { FileTempEntity } from "./../entities/FileTempEntity.js";
import { MemberProfileEntity, memberProfileEntityFromObject } from "./../entities/MemberProfileEntity.js";
import getMemberProfileByLineTokenID from "./getMemberProfileByLineTokenID.js";
import putFileTemp from "./putFileTemp.js";
import deleteFileTemps from "./deleteFileTemps.js";
import { s3CopyFile } from "./S3Methods.js";
import { dateToUTCString } from "./DatetimeFormatter.js";

async function updateMemberLogo(lineAccessToken, memberId, newLogoTempPathAndKey) {
  let oldMemberProfileObject = await getMemberProfileByLineTokenID(lineAccessToken, memberId);
  if (oldMemberProfileObject) {

    let putItems = [];
    let deleteItems = [];
    let updateItems = [];

    const memberId = oldMemberProfileObject.MemberID ? oldMemberProfileObject.MemberID:"";
    if (memberId.length > 0) {
      let newLogoTempEntity;
      if (newLogoTempPathAndKey) {
        if (newLogoTempPathAndKey.length > 0) {
          // 有上傳新檔, 產生正式路徑temp檔, 交易過程同步刪除temp檔, 交易失敗temp檔可供參照用
          const targetPathAndKey = newLogoTempPathAndKey.replace('Temp/', 'Members/');
          newLogoTempEntity = new FileTempEntity(memberId, kvnConfig.s3MainBucketName, newLogoTempPathAndKey);
          await putFileTemp(newLogoTempEntity);
          await s3CopyFile(kvnConfig.s3MainBucketName, newLogoTempPathAndKey, kvnConfig.s3MainBucketName, targetPathAndKey);
          deleteItems.push(newLogoTempEntity);

          // 存在舊檔, 產生對照temp
          if (oldMemberProfileObject.BucketName && oldMemberProfileObject.PathAndKey) {
            if (oldMemberProfileObject.BucketName.length > 0 && oldMemberProfileObject.PathAndKey.length > 0) {
              const oldLogoTempEntity = FileTempEntity(memberId, oldMemberProfileObject.BucketName, oldMemberProfileObject.PathAndKey);
              putItems.push(oldLogoTempEntity);
            }
          }

          let newMemberProfileEntity = new MemberProfileEntity(memberId);
          newMemberProfileEntity.BucketName = kvnConfig.s3MainBucketName;
          newMemberProfileEntity.PathAndKey = targetPathAndKey;
          newMemberProfileEntity.updateAt = dateToUTCString(new Date(), "yyyyMMddHHmmss");
          updateItems.push(memberProfileEntityFromObject(newMemberProfileEntity));
        }
      }

      if (putItems.length > 0 || updateItems.length > 0 || deleteItems.length > 0) {
        await transactWriteItems(putItems, updateItems, deleteItems);
      }

      await deleteFileTemps(memberId).catch();
    }
  }
};
  
export default updateMemberLogo;
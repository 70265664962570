import { updateItem } from "./DynamodbMethods.js";

async function updateFilm(filmEntity) {
    
    let result;

    if (filmEntity) {

        filmEntity.PK = "Films";

        if (filmEntity.PK && filmEntity.SK) {
            if (filmEntity.PK.length > 0 && filmEntity.SK.length > 0) {
                result = await updateItem(filmEntity);
            }
        }
    }
    return result;
}
  
export default updateFilm;
class PaymentEntity {
    
    constructor(orderNumber) {
        this.PK = "Pay";
        this.SK = orderNumber ? "Pay#"+orderNumber:"";
        this.Type = "Payment";
        this.OrderNumber = orderNumber;
        this.PayResult = false;
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK
        };
        if (this.Type) {
            obj["Type"] = this.Type;
        }
        if (this.OrderNumber) {
            obj["OrderNumber"] = this.OrderNumber;
        }
        if (this.PayResult !== undefined && this.PayResult !== null) {
            if (this.PayResult) {
                obj["PayResult"] = true;
            } else {
                obj["PayResult"] = false;
            }
        }

        // CC/ATM/ISP
        if (this.PayMethod) {
            obj["PayMethod"] = this.PayMethod;
        }

        // 三方交易號碼 或是 點數扣款編碼(未來)
        if (this.PayID) {
            obj["PayID"] = this.PayID;
        }
        if (this.PayTimestamp) {
            obj["PayTimestamp"] = this.PayTimestamp;
        }

        // 黑潮支付
        if (this.GatewayName) {
            obj["GatewayName"] = this.GatewayName;
        }

        // number
        if (this.PayAmount !== undefined && this.PayAmount !== null) {
            obj["PayAmount"] = Math.floor(this.PayAmount);
            if (isNaN(obj["PayAmount"])) {
                obj["PayAmount"] = 0;
            }
        }
        // number
        if (this.PayFee !== undefined && this.PayFee !== null) {
            obj["PayFee"] = Math.floor(this.PayFee);
            if (isNaN(obj["PayFee"])) {
                obj["PayFee"] = 0;
            }
        }
        
        if (this.PayMemo) {
            obj["PayMemo"] = this.PayMemo;
        }
        if (this.MemberID) {
            obj["MemberID"] = this.MemberID;
        }
        if (this.ProducerID) {
            obj["ProducerID"] = this.ProducerID;
        }
        if (this.ProductType) {
            obj["ProductType"] = this.ProductType;
        }
        if (this.ProductSKU) {
            obj["ProductSKU"] = this.ProductSKU;
        }
        if (this.VPName) {
            obj["VPName"] = this.VPName;
        }
        if (this.MerchantId) {
            obj["MerchantId"] = this.MerchantId;
        }
        if (this.TerminalId) {
            obj["TerminalId"] = this.TerminalId;
        }
        if (this.PaymentType) {
            obj["PaymentType"] = this.PaymentType;
        }
        if (this.Last4CardNo) {
            obj["Last4CardNo"] = this.Last4CardNo;
        }
        if (this.AuthCode) {
            obj["AuthCode"] = this.AuthCode;
        }
        return obj;
    }
}

const paymentEntityFromObject = (item) => {
    let entity = new PaymentEntity("");
    entity.PK = item.PK;
    entity.SK = item.SK;
    if (item.Type) {
        entity.Type = item.Type;
    }
    if (item.OrderNumber) {
        entity.OrderNumber = item.OrderNumber;
    }
    if (item.PayResult !== undefined && item.PayResult !== null) {
        if (item.PayResult) {
            entity.PayResult = true;
        } else {
            entity.PayResult = false;
        }
    }
    if (item.PayMethod) {
        entity.PayMethod = item.PayMethod;
    }
    if (item.PayID) {
        entity.PayID = item.PayID;
    }
    if (item.PayTimestamp) {
        entity.PayTimestamp = item.PayTimestamp;
    }
    if (item.GatewayName) {
        entity.GatewayName = item.GatewayName;
    }
    if (item.PayAmount !== undefined && item.PayAmount !== null) {
        entity.PayAmount = Math.floor(item.PayAmount);
        if (isNaN(entity.PayAmount)) {
            entity.PayAmount = 0;
        }
    }
    if (item.PayFee !== undefined && item.PayFee !== null) {
        entity.PayFee = Math.floor(item.PayFee);
        if (isNaN(entity.PayFee)) {
            entity.PayFee = 0;
        }
    }
    if (item.PayMemo) {
        entity.PayMemo = item.PayMemo;
    }
    if (item.MemberID) {
        entity.MemberID = item.MemberID;
    }
    if (item.ProducerID) {
        entity.ProducerID = item.ProducerID;
    }
    if (item.ProductType) {
        entity.ProductType = item.ProductType;
    }
    if (item.ProductSKU) {
        entity.ProductSKU = item.ProductSKU;
    }
    if (item.VPName) {
        entity.VPName = item.VPName;
    }
    if (item.MerchantId) {
        entity.MerchantId = item.MerchantId;
    }
    if (item.TerminalId) {
        entity.TerminalId = item.TerminalId;
    }
    if (item.PaymentType) {
        entity.PaymentType = item.PaymentType;
    }
    if (item.Last4CardNo) {
        entity.Last4CardNo = item.Last4CardNo;
    }
    if (item.AuthCode) {
        entity.AuthCode = item.AuthCode;
    }
    return entity;
}

export { PaymentEntity, paymentEntityFromObject };

// {
// 	PK: "Pay",
// 	SK: "Pay#OrderNumber",
// 	Type: "Payment",
// 	OrderNumber: "MemberID20211231095959",
// 	PayResult: false,
// 	PayMethod: "ATM", // CC/ATM/ISP
// 	PayID: "PayID", // 三方交易號碼 或是 點數扣款編碼(未來)
// 	PayTimestamp: "20211231095959",
// 	GatewayName: "黑潮支付",
// 	PayAmount: 0,
// 	PayFee: "0",
// 	PayMemo: "",
// 	MemberID: "",
// 	ProducerID: "",
// 	ProductType: "VPID",  // VPID影品, VPSID訂閱, VSID影集, VCID影課
// 	ProductSKU: "miffy-20211231095959",
//  VPName: "鬼滅之刃",
// 	MerchantId: "",
// 	TerminalId: "",
// 	PaymentType: "",
// 	Last4CardNo: "",
// 	AuthCode: ""
// }
import React from "react";

function VPHead(props) {
    return (
        <>
            <div className="hpage1" style={{padding:0}}>
                <div className="wrap">
                    <h1 className="wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s"><span style={{color:`#000`}}>服裝立裁打版</span><span>課程系列-洋裝篇</span></h1>     
                    <h4 className="wow fadeInUp h4class">課程明細</h4>
                </div>
            </div>
        </>
    )
}

export default VPHead;


import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

async function putVideoProduction(videoProductionEntity) {
    
    let result;
    let oldEntity = videoProductionEntity;
    if (videoProductionEntity) {

        videoProductionEntity.Type = "ProduceTeam";

        if (videoProductionEntity.PK && videoProductionEntity.SK) {
            if (videoProductionEntity.PK.length > 0 && videoProductionEntity.SK.length > 0) {
                
                result = await putItem(videoProductionEntity);
            }
        }
    }
    if (result) {
        return videoProductionEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putVideoProduction;
import React from "react";

function hPage5(props) {
    return (
        <>
           	<div className="hpage5">
				<div className="wrap wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1s">
					<ul>
						<li>
							<div className="ico">
                            <img src={require("assets/FashionDraping/images/qm22.png").default}  alt=""/>
							</div>
							<h3>預計課程時長</h3>
							<p>337分鐘</p>
						</li>
						<li>
							<div className="ico">
                            <img src={require("assets/FashionDraping/images/qm23.png").default}  alt=""/>
							</div>
							<h3>預計單元數</h3>
							<p>7章28節</p>
						</li>
						<li>
							<div className="ico">
								<img src={require("assets/FashionDraping/images/qm24.png").default} alt=""/>
							</div>
							<h3>期限時間</h3>
							<p>期限3個月,送3個月,不限觀看次數</p>
						</li>
					</ul>
				</div>
			</div>
        </>
    )
}

export default hPage5;
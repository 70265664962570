import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { queryMember, queryFashionMember,queryLineMember} from "data/LineMethods";
import DataMethods from "data/DataMethods";

function HPage9(props) {
  const [isShow,setShow] = useState(false);
  const [isLoading,setLoading] = useState(false);
  const [isPay,setPay] = useState(false);
  const [showError, setShowErrorError] = useState(false);
  const [vplist, setVpList] = useState([]);
  const [selectedChapter,setSelectedChapter] = useState({})
  const [selectedChapterAll,setSelectedChapterAll] = useState({})
  const [seletedChapterRegular,setSelectedChapterRegular] = useState({})
  const [chapterList,setChapterList] = useState([]);
  const [selected, setSelected] = useState("FDDB-0");
  const [paymentHtml, setPaymentHtml] = useState();
  const [MemberProfile, setMemberProfile] = useState(undefined);
  const [LineProfile, setLineProfile] = useState(undefined);
  const [FashionProfile,setFashionProfile] = useState(undefined);
  const [isLoadChapterFinish ,setIsLoadChapterFinish] = useState(false);
  const [modalLoading,setModalLoading] = useState(false)


  const loadSession = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const sessionToken = {
        idToken: currentSession.getIdToken().getJwtToken(),
        refreshToken: currentSession.getRefreshToken().getToken(),
        accessToken: currentSession.getAccessToken().getJwtToken(),
      };
      return sessionToken;
    } catch {
      return null;
    }
  };
  const sendOrder = async (vpid) => {
    try {
      setModalLoading(true)
      const currentSession = await Auth.currentSession();
      const sessionToken = {
        idToken: currentSession.getIdToken().getJwtToken(),
        refreshToken: currentSession.getRefreshToken().getToken(),
        accessToken: currentSession.getAccessToken().getJwtToken(),
      };
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", sessionToken.accessToken);
      const href = window.location.href;
      const raw = JSON.stringify({
        buyerEmail: LineProfile.Email,
        pid: vpid,
        printInv: 2,
        loveCode: "",
        buyerTel: LineProfile.Phone,
        carrierType: "3",
        buyerAddr: "",
        carrierStr: LineProfile.Email,
        buyerName: LineProfile.Fullname,
        buyerID: "",
        returnUrl: href,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const res = await fetch(
          "https://nnfeel0fu3.execute-api.ap-northeast-1.amazonaws.com/dev/client/order",
          requestOptions
        );
        const data = await res.json();
        setPaymentHtml(data);
        setModalLoading(false)
      } catch (e) {
        setShowErrorError(true);
        console.log(e);
        setModalLoading(false)
      }
    } catch (e) {
      setModalLoading(false)
      await Auth.federatedSignIn({
        customProvider: "Line",
      });
      return;
    }
  };

  
  const init = async () => {
    const sessionToken = await loadSession();
    if (sessionToken) {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", sessionToken.accessToken);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        "https://nnfeel0fu3.execute-api.ap-northeast-1.amazonaws.com/dev/productList",
        requestOptions
      );
      const data = await res.json();
      setVpList(data.map((v) => v.VPID));
    }
  };

  const getChapterInfo = async () => {
    const sessionToken = await loadSession();
    if (sessionToken) {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", sessionToken.accessToken);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        "https://nnfeel0fu3.execute-api.ap-northeast-1.amazonaws.com/dev/products?pid=FDDB-ALL&pid=FDDB-ALL-6&pid=FDDB-0&pid=FDDB-1&pid=FDDB-2&pid=FDDB-3&pid=FDDB-4&pid=FDDB-5&pid=FDDB-6",
        requestOptions
      );
      const data = await res.json();
      setChapterList(data)
      findChapterInfo()
      findChapterInfoALL()
      findChapterInfoRegular()
      setIsLoadChapterFinish(true)
    }
  }

  const findChapterInfo = async () => {
    const data = chapterList.find(x => x.P_ID === selected)
    if(data){
      setSelectedChapter(data)
    }
    return null
  }

  const findChapterInfoALL = async () =>{
    const data = chapterList.find(x => x.P_ID === "FDDB-ALL")
    if(data){
      setSelectedChapterAll(data)
    }
  }

  const findChapterInfoRegular = async () =>{
    const data = chapterList.find(x => x.P_ID === "FDDB-ALL-6")
    if(data){
      setSelectedChapterRegular(data)
    }
  }




  const getMemberProfile = async (payload) => {
    await queryMember(payload).then(async (res) => {
        if (res) {
            setMemberProfile(res);
            await getLineProfile(payload)
        }
    });
  };

  const getLineProfile = async (payload) => {
    await queryLineMember(payload).then(async (res) => {
        if (res) {
            setLineProfile(res);
            await getFashionProfile(payload)
        } 
    });
  };

  const getFashionProfile = async (payload) => {
    await queryFashionMember(payload).then(async (res) => {
        if (res) {
            setFashionProfile(res);
            if(res.ExpireDate){
                const ExpireDate = res.ExpireDate;
                const newDate = new Date();
                const localDate = DataMethods.utcStringToDate(ExpireDate, "yyyyMMddHHmmss")
                if(localDate > newDate){
                    // await getVPPDALL(payload);
                    // window.location.href = "/fashion-draping/basics/"
                }              
            }else{
                // window.location.href = "/fashion-draping/basics/"
            }
        } else {
            // window.location.href = "/fashion-draping/basics/"
        }
    });
};

  useEffect(() => {
    
    init();
    getChapterInfo()
    if (props.info) {
      const info = props.info;
      if (info && info.signInUserSession) {
          const payLoad = info.signInUserSession.idToken.payload.identities[0].userId
          getMemberProfile(payLoad);
      } 
    } 
    if(isShow){
      const timer = setTimeout(() =>{
          // setShow(false);
          clearTimeout(timer)
      },3000)
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props,isShow,isPay,isLoadChapterFinish] );
  return (
    <>
      {showError && (
        <div className="alert queryFashionMember alert-danger" role="alert">
          交易支付出現異常！
        </div>
      )}
      <div className="hpage9" id="hpage9">
        <div className="wrap">
          <div
            className="tit wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="1s"
          >
            <h2>
              課程訂閱<span>方案任您選</span>
            </h2>
            <p>立即購買整套課程 立享折扣優惠</p>
          </div>
          <div
            className="bd clearfix wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="1s"
          >
            <div className="txt">
              <h3>學習計劃</h3>
              <div className="del">
                Videostores 所提供的線上課程不限次數觀看，
                <br />
                提供您最彈性最自由的學習方式，
                <br />
                可輕鬆為自己安排學習計畫時程。
              </div>
            </div>
            <div className="pic">
              <h6>
                課程售價 <s>14,000</s> 元 一次購買全部擁有
              </h6>
              <h6>
                <i>限時活動價</i>
                <span> {selectedChapterAll ? selectedChapterAll.P_Price : null}元</span>
              </h6>
              
              {vplist.includes("FDDB-ALL") ? (
                <div className="contain">
                  <Button
                    type="button"
                    className="nav-link"
                    onClick={() =>
                      (window.location.href = "/fashion-draping/basics/vp")
                    }
                  >
                    <span>前往課程</span>
                  </Button>
                </div>
              ) : (
                <div className="contain">
                  <Button
                    type="button"
                    className="nav-link"
                    onClick={() => sendOrder("FDDB-ALL")}
                  >
                    <span>整套課程購買</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="fts">
            <ul className="clearfix">
              <li>
                <h6>
                  課程售價<s>14,000</s>元，一次購買 全部擁有
                </h6>
                <h5>
                  <i>限時活動價</i>
                  <span> {selectedChapterAll ? selectedChapterAll.P_Price : null}元</span>
                </h5>
                <h4>
                  <i>6期定期定額分期價</i>
                  <span>{seletedChapterRegular? seletedChapterRegular.P_Price : null}元<font>/每期</font>
                  </span>
                </h4>
                {vplist.includes("FDDB-ALL") ? (
                  <div className="containbtn">
                    <a
                      type=""
                      className="btn"
                      href="/fashion-draping/basics/vp"
                    >
                      <span>前往課程</span>
                    </a>
                  </div>
                ) : (
                  <div className="containbtn">
                    <a
                      type=""
                      className="btn"
                      onClick={() => sendOrder("FDDB-ALL-6")}
                    >
                      <span>6期分期課程購買</span>
                    </a>
                  </div>
                )}
              </li>
              <li>
                <h6>
                  單堂課程售價<s>2,000</s>元
                </h6>
                <h4>
                  <i>限時活動85折</i>
                  <span>{selectedChapter? selectedChapter.P_Price : null}元</span>
                </h4>
                <select
                  className="lsele"
                  value={selected}
                  onChange={(e) => {
                    setSelected(e.target.value);
                    findChapterInfo()
                  }}
                >
                  <option disabled={vplist.includes("FDDB-0")} value="FDDB-0">
                    第0章 人臺標示教學
                  </option>
                  <option disabled={vplist.includes("FDDB-1")} value="FDDB-1">
                    第1章 立裁概念與無袖上衣原型
                  </option>
                  <option disabled={vplist.includes("FDDB-2")} value="FDDB-2">
                    第2章 直裙原型
                  </option>
                  <option disabled={vplist.includes("FDDB-3")} value="FDDB-3">
                    第3章 褶子轉移概念與實操
                  </option>
                  <option disabled={vplist.includes("FDDB-4")} value="FDDB-4">
                    第4章 褶子轉移&無領無袖版型如何一次到位
                  </option>
                  <option disabled={vplist.includes("FDDB-5")} value="FDDB-5">
                    第5章 裙子變化概念
                  </option>
                  <option disabled={vplist.includes("FDDB-6")} value="FDDB-6">
                    第6章 立裁馬甲原型
                  </option>
                </select>
                {vplist.length === 7 ? (
                  <div className="containbtn">
                    <a
                      type=""
                      className="btn"
                      href="/fashion-draping/basics/vp"
                    >
                      <span>前往課程</span>
                    </a>
                  </div>
                ) : (
                  <div className="containbtn">
                    <a
                      type=""
                      className="btn"
                      onClick={() => sendOrder(selected)}
                    >
                      <span>單堂課程購買</span>
                    </a>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalLoading}
      ></Modal>
      {paymentHtml && (
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: paymentHtml.html }}
            ref={() => {
              document.getElementById(paymentHtml.formId).submit();
            }}
          />
        </div>
      )}
    </>
  );
}

export default HPage9;

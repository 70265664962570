import React from "react";
import "assets/LandingPage/css/slicy.css"
import "assets/LandingPage/css/style.css"
import "assets/LandingPage/css/swiper.min.css"
import "assets/LandingPage/css/responsive.css"
import FBanner from './fbanner'
import FPlatform from './fplatform'
import FThetypes from "./fthetypes";
// import FLegal from "./flegal";
import FTiwen from "./ftiwen"
import Footer from './footer'
import AboutUs from "../../views/AboutUs.js";
import { Helmet } from 'react-helmet-async'
// 第一次執行時，所有的useState資料初始化會先執行，所有的useEffect會最後執行
// function內的其他代碼會在 useState初始化後，useEffect執行前，之間依序執行

// 第一次輪執行完後，如有任何useState資料的set動作
// 則會觸發function執行，然後觸發useEffect執行(陣列如果有涵蓋set的useState變數的話)

function Landing(props) {
    return(
    <>
        <div className="layout">
        <Helmet>
            <title>Video Stores - 將知名度轉換成收入這事  簡單化</title>
            <meta name='description' content='Video Stores - 將知名度轉換成收入這事  簡單化' />
        </Helmet>
            <FBanner></FBanner>
            <FPlatform></FPlatform>
            <FThetypes></FThetypes>
            {/* <FLegal></FLegal> */}
            <AboutUs></AboutUs>
            <FTiwen></FTiwen>
            <Footer></Footer>
        </div>
        <div className="fvideobj"></div>
        <div className="fvideo">
            <div className='video1' id="videos"></div>
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/xswrHqMLXuQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
            {/* <video className='video1' id="videos" controls="video" poster={require("assets/LandingPage/images/fimg5.jpg").default} preload='' x5-playsinline='' playsInline='' webkit-playsinline=''>
                <source src='https://youtu.be/xswrHqMLXuQ' type='video/mp4' />
            </video> */}
            <div className="cha"></div>
        </div>
      
    </>
  );
}

export default Landing;

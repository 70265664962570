import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import "assets/css/custom.css"
import { Auth } from "aws-amplify";
import {
  NavbarBrand,
  Navbar,
  Button,
  Container,
} from "reactstrap";


function OnlyHeader(props){

    return (
        <>
        <Navbar className="" expand="lg">
            <Container fluid>
                <div className="navbar-translate">
                    <NavbarBrand to="/" tag={Link} id="navbar-brand">
                        <img src={require("assets/FashionDraping/images/videoStore.png").default}  alt="" style={{height:`100%`,width:`25vw`,maxWidth:`232px`,minWidth:`117px`}}/>
                    </NavbarBrand>
                </div>
            </Container>
        </Navbar>
        </>
    )
}
export default OnlyHeader
import { getItem } from "./DynamodbMethods.js";

async function getProducerProfileIDVByPid(ProducerIDString) {
    let res;
    if (ProducerIDString) {
        if (ProducerIDString.length > 0) {
            res = await getItem("P#"+ProducerIDString, "IDV#"+ProducerIDString);
        }
    }
    return res;
}
  
export default getProducerProfileIDVByPid;
import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

// {
//     "PK": "Members",
//     "SK": "M#coreytu",
//     "Type": "M_Main",
//     "MemberID": "coreytu",
//     "agreement": "HASH Strings",
//     "akaname": "Corey----",
//     "birthday": "",
//     "BucketName": "video-stores-1",
//     "country": "Taiwan",
//     "createAt": "",
//     "email": "coreytu@gmail.com",
//     "fullname": "杜世鵬",
//     "gender": "M",
//     "language": "ZH-TW",
//     "PathAndKey": "Members/coreytu-logo-1633401571296.jpg",
//     "phone": "+886935161290",
//     "updateAt": 1633401573835
//   }

async function putMemberProfile(memberProfileEntity) {
    
    let result;
    let oldEntity = memberProfileEntity;
    if (memberProfileEntity) {

        memberProfileEntity.PK = "Members";
        memberProfileEntity.Type = "M_Main";

        if (memberProfileEntity.SK) {
            if (memberProfileEntity.SK.length > 0) {
                // do nothing
            } else {
                if (memberProfileEntity.MemberID){
                    if (memberProfileEntity.MemberID.length > 0) {
                        memberProfileEntity.SK = "M#"+memberProfileEntity.MemberID
                    }
                }
            }
        } else {
            if (memberProfileEntity.MemberID){
                if (memberProfileEntity.MemberID.length > 0) {
                    memberProfileEntity.SK = "M#"+memberProfileEntity.MemberID
                }
            }
        }

        if (memberProfileEntity.PK && memberProfileEntity.SK) {
            if (memberProfileEntity.PK.length > 0 && memberProfileEntity.SK.length > 0) {
                
                result = await putItem(memberProfileEntity);
            }
        }
    }
    if (result) {
        return memberProfileEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putMemberProfile;
class FileTempEntity {
    constructor(Uid, BucketName, PathAndKey) {
        if (BucketName) {
            if (BucketName.length > 0) {
                this.PK = "Temp#"+BucketName;
            }
        }
        if (Uid && PathAndKey) {
            if (Uid.length > 0 && PathAndKey.length > 0) {
                this.SK = Uid+"#"+PathAndKey;
            }
        }
    }

    toObject() {
        return {
            "PK": this.PK,
            "SK": this.SK
        }
    }
}

const fileTempEntityFromObject = (item) => {
    let entity = new FileTempEntity("", "", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    return entity;
}

export { FileTempEntity, fileTempEntityFromObject };

// {
//     "PK":"Temp#"+BucketName,
//     "SK":uid+"#"+PathAndKey
//   }
import {
    getFirstItem,
    putItem,
    updateItem
} from "./DynamodbMethods.js";

async function putVideoPAPResult(videoPAPResultEntity) {
    
    let result;
    let oldEntity = videoPAPResultEntity;
    if (videoPAPResultEntity) {

        if (videoPAPResultEntity.PK) {
            if (videoPAPResultEntity.PK.length > 0) {
                // do nothing
            } else {
                if (videoPAPResultEntity.ProductType){
                    if (videoPAPResultEntity.ProductType.length > 0) {
                        videoPAPResultEntity.PK = "VPAP#"+videoPAPResultEntity.ProductType
                    }
                }
            }
        } else {
            if (videoPAPResultEntity.ProductType){
                if (videoPAPResultEntity.ProductType.length > 0) {
                    videoPAPResultEntity.PK = "VPAP#"+videoPAPResultEntity.ProductType
                }
            }
        }

        if (videoPAPResultEntity.SK) {
            if (videoPAPResultEntity.SK.length > 0) {
                // do nothing
            } else {
                if (videoPAPResultEntity.ProductSKU && videoPAPResultEntity.submitDateTime){
                    if (videoPAPResultEntity.ProductSKU.length > 0 && videoPAPResultEntity.submitDateTime.length > 0) {
                        const result = videoPAPResultEntity.status ? videoPAPResultEntity.status:false;
                        if (result) {
                            videoPAPResultEntity.SK = "YES#"+videoPAPResultEntity.ProductSKU+"-"+videoPAPResultEntity.submitDateTime
                        } else {
                            videoPAPResultEntity.SK = "NO#"+videoPAPResultEntity.ProductSKU+"-"+videoPAPResultEntity.submitDateTime
                        }
                    }
                }
            }
        } else {
            if (videoPAPResultEntity.ProductSKU && videoPAPResultEntity.submitDateTime){
                if (videoPAPResultEntity.ProductSKU.length > 0 && videoPAPResultEntity.submitDateTime.length > 0) {
                    const result = videoPAPResultEntity.status ? videoPAPResultEntity.status:false;
                    if (result) {
                        videoPAPResultEntity.SK = "YES#"+videoPAPResultEntity.ProductSKU+"-"+videoPAPResultEntity.submitDateTime
                    } else {
                        videoPAPResultEntity.SK = "NO#"+videoPAPResultEntity.ProductSKU+"-"+videoPAPResultEntity.submitDateTime
                    }
                }
            }
        }

        if (videoPAPResultEntity.PK && videoPAPResultEntity.SK && videoPAPResultEntity.ProductType && videoPAPResultEntity.ProductSKU) {
            if (videoPAPResultEntity.PK.length > 0 && videoPAPResultEntity.SK.length > 0 && videoPAPResultEntity.ProductType.length > 0 && videoPAPResultEntity.ProductSKU.length > 0) {
                
                result = await putItem(videoPAPResultEntity);
            }
        }
    }
    if (result) {
        return videoPAPResultEntity.toObject();
    } else {
        return oldEntity.toObject();
    }
}
  
export default putVideoPAPResult;
import kvnConfig from "./../views/KeyValueNameConfig.js";
import { docClientQuery } from "./AWSConfig.js";

async function getProducerInfoByLineID(LineIDString) {

    let obj;

    if (LineIDString) {
        if (LineIDString.length > 0) {
            
            const paramsQuery = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK",
                    "#keyAlias2": "LineID"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": "Producers",
                    ":valueAlias2": LineIDString
                },
                KeyConditionExpression: "#keyAlias1 = :valueAlias1",
                FilterExpression: "#keyAlias2 = :valueAlias2"
            };

            const res = await docClientQuery(paramsQuery);
            if (res.Items.length > 0) {
                obj = res.Items[0];
            }
        }
    }
    
    return obj;
}
  
export default getProducerInfoByLineID;
import React from "react";

function hPage8(props) {
	return (
		<>
			<div className="hpage8">
				<div className="wrap">
					<div className="tit wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1s">
						<h2>學員推薦分享</h2>
						<p>Student recommendation and sharing</p>
					</div>
					<ul className="clearfix wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
						<li>
							<div className="ts">
								<div className="ico">
									<img src={require("assets/FashionDraping/images/qm33.jpg").default} alt=""/>
								</div>
								<div className="txt">
									<h3>洪瓊芬</h3>
									<h6>服裝技能 0 基礎新手</h6>
								</div>
							</div>
							<div className="del">
								沒有任何服裝製作基礎的我，很喜歡何老師用深入淺出的方式引導我們一步步練習，讓我能順利完成能穿出門的衣服，讓真的有學習到技能的我非常有成就感，也讓我對服裝設計有更多創意想法。
							</div>
						</li>
						<li>
							<div className="ts">
								<div className="ico">
									<img src={require("assets/FashionDraping/images/qm34.jpg").default} alt=""/>
								</div>
								<div className="txt">
									<h3>陳柔辰</h3>
									<h6>客製旗袍服飾創業者</h6>
								</div>
							</div>
							<div className="del">
								因為我的工作是客製旗袍製作，而旗袍的打版是非常重要的，需要仔細掌握人體與版型的關係，因此透過何老師的立裁課程，讓我在打版技術更加精進，甚至更能掌握一件好的服裝所需的版型設計。
							</div>
						</li>
						<li>
							<div className="ts">
								<div className="ico">
									<img src={require("assets/FashionDraping/images/qm35.jpg").default} alt=""/>
								</div>
								<div className="txt">
									<h3>劉秀芳</h3>
									<h6>自我提升強化的打版師</h6>
								</div>
							</div>
							<div className="del">
								我的工作是打版師，立裁可以直接展現出完成的效果且立裁的成品較符合人體尺寸的寬鬆較好控制。結合平面打版與立裁相互使用可增加我的打版的技能並且讓我在打版中更能增加美感與版型的線條感。
							</div>
						</li>
						<li>
							<div className="ts">
								<div className="ico">
									<img src={require("assets/FashionDraping/images/qm36.jpg").default} alt=""/>
								</div>
								<div className="txt">
									<h3>S******</h3>
									<h6>想精進打版技術的設計師</h6>
								</div>
							</div>
							<div className="del">
								其實在業界就與何老師合作過，老師厲害的製版技術，讓我決定透過課程更多與老師學習更精湛的打版技巧。在課程中，教學步驟精準且鉅細靡遺，讓初學者也能在短時間學會打版重要的技術與概念，覺得非常值得。
							</div>
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default hPage8;
import { Button } from "reactstrap";
import React, { useMemo, useEffect, useState } from 'react'
import { payQuery } from "../data/1177pay"

function PayResult() {
  const [OrderNo,setOrderNum] = useState(undefined);
  const [periods,setPeriods] = useState(undefined);
  const [OrderStatus, setOrderStatus] = React.useState("訂單狀態：");


  useEffect(() => {
    if (OrderNo) {
     payQuery(OrderNo,periods)
      .then((res) => {
        if (res) {
          const result = JSON.parse(res.Payload)
          if (result && result.order && result.order.orderstate) {
            const paymentStatus = result.order.orderstate
            if(paymentStatus === 0){
              setOrderStatus("訂單狀態： 單號->"+OrderNo+", 已付款");
            }else if(paymentStatus === "1"){
              setOrderStatus("訂單狀態： 單號->"+OrderNo+", 已結束");
            }else if(paymentStatus === "2"){
              setOrderStatus("訂單狀態： 單號->"+OrderNo+", 已建立(未付款)");
            }else if(paymentStatus === "3"){
              setOrderStatus("訂單狀態： 單號->"+OrderNo+", 已作廢(取消)");
            }else if(paymentStatus === "4"){
              setOrderStatus("訂單狀態： 單號->"+OrderNo+",付款失敗");
            }else if(paymentStatus === "5"){
              setOrderStatus("訂單狀態： 單號->"+OrderNo+", 可退款");
            }
          }
        } else {
          setOrderStatus("訂單狀態：");
        }
      })
      .catch((err) => {
        setOrderStatus("訂單狀態：");
      });

    
    }else{
        //取得當前網址
      let queryString = decodeURIComponent(window.location.search);
      if (queryString) {
        //取得?後的 key 跟 value
        queryString = queryString.substring(1); //userName=ma&userPhone=0988
        if (queryString) {
          //拆成array
          const queries = queryString.split("&"); //["userName=su", "userPhone=0988"]
          if (queries) {
            
            //一個個抓出來
            for (var i = 0; i < queries.length; i++) {
              const kvp = queries[i].split("=");
              if (kvp) {
                if (kvp.length > 1) {
                  if (kvp[0] === "ordernumber") {
                    setOrderNum(kvp[1]);
                  }
                  if(kvp[0] === "periods"){
                    setPeriods(kvp[1])
                  }
                }
              }
            }
          }
        }
      }
    }
    
  }, [OrderNo,periods]);

  return (
    <>
    <div className="layout my-3 mx-3">
      <h3 style={{fontSize:`20px`}}>{ OrderStatus }</h3>
        <Button
        onClick={(e) => {
          window.location.href = "/fashion-draping/basics";
        }}
        >
          回到付款前頁面
        </Button>
      </div>
    </>
  );
}

export default PayResult;
import DataMethods from "./../data/DataMethods.js";

class VideoPAPResultEntity {
    
    constructor(ProductType, ProductSKU, submitDateTime, Result) {
        this.PK = ((ProductType && ProductSKU) ? ((ProductType.length > 0 && ProductSKU.length > 0) ? (ProductType+"#"+ProductSKU):""):"");
        if (Result) {
            this.SK = (ProductSKU ? ((ProductSKU.length > 0) ? ("YES#"+ProductSKU+"-"+submitDateTime):""):"");
        } else {
            this.SK = (ProductSKU ? ((ProductSKU.length > 0) ? ("NO#"+ProductSKU+"-"+submitDateTime):""):"");
        }
        this.ProductType = ProductType ? ProductType:"";
        this.ProductSKU = ProductSKU ? ProductSKU:"";
        this.status = Result ? Result:false;
        this.updateDateTime = DataMethods.dateToUTCString(new Date(), "yyyyMMddHHmmss");
        this.submitDateTime = submitDateTime;
        // this.auditBy = "";
        // this.reason = "";
        // this.BucketName = "";
        // this.S3_RecordPath = "";        
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK,
            "ProductType": this.ProductType,
            "ProductSKU": this.ProductSKU
        };
        if (this.status !== undefined && this.status !== null) {
            if (this.status) {
                obj["status"] = true;
            } else {
                obj["status"] = false;
            }
        }
        if (this.updateDateTime) {
            obj["updateDateTime"] = this.updateDateTime;
        }
        if (this.submitDateTime) {
            obj["submitDateTime"] = this.submitDateTime;
        }
        if (this.auditBy) {
            obj["auditBy"] = this.auditBy;
        }
        if (this.reason) {
            obj["reason"] = this.reason;
        }
        if (this.BucketName) {
            obj["BucketName"] = this.BucketName;
        }
        if (this.S3_RecordPath) {
            obj["S3_RecordPath"] = this.S3_RecordPath;
        }
        return obj;
    }
}

const videoPAPResultEntityFromObject = (item) => {
    let entity = new VideoPAPResultEntity("", "", "", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    entity.ProductType = item.ProductType;
    entity.ProductSKU = item.ProductSKU;
    if (item.status !== undefined && item.status !== null) {
        if (item.status) {
            entity.status = true;
        } else {
            entity.status = false;
        }
    }
    if (item.updateDateTime) {
        entity.updateDateTime = item.updateDateTime;
    }
    if (item.submitDateTime) {
        entity.submitDateTime = item.submitDateTime;
    }
    if (item.auditBy) {
        entity.auditBy = item.auditBy;
    }
    if (item.reason) {
        entity.reason = item.reason;
    }
    if (item.BucketName) {
        entity.BucketName = item.BucketName;
    }
    if (item.S3_RecordPath) {
        entity.S3_RecordPath = item.S3_RecordPath;
    }
    return entity;
}

export { VideoPAPResultEntity, videoPAPResultEntityFromObject };

// {
//     "PK":"VPAP#VPID"
//     "SK":"YES#VPID-TIMESTAMP"
//     "ProductType": "VPID",
//     "ProductSKU": "coreytu01",
//     "status": true,
//     "submitDateTime":"",
//     "auditBy":"AdminID"
//     "updateDateTime":""
//     "reason": "理由"
//     "BucketName": "video-stores-1"
//     "S3_RecordPath":"01.jpg,02.jpg"
//   }
import getFilmsByProducerID from "./getFilmsByProducerID.js";
import deleteFilmByPkAndSk from "./deleteFilmByPkAndSk.js";

async function deleteFilmsByProducerID(ProducerID) {
    let res = { successes: [], failures: [] };

    if (ProducerID) {
        if (ProducerID.length > 0) {
            const getRes = await getFilmsByProducerID(ProducerID);
            if (getRes) {
                if (getRes.Items) {
                    for(let i=0;i<getRes.Items.length;i++) {
                        let deleteErr;
                        await deleteFilmByPkAndSk(getRes.Items[i].PK, getRes.Items[i].SK).catch((error) => { deleteErr = error; });
                        if (deleteErr) {
                            res.failures.push(getRes.Items[i]);
                        } else {
                            res.successes.push(getRes.Items[i]);
                        }
                    }
                }
            }
        }
    }

    return res;
}
  
export default deleteFilmsByProducerID;
import kvnConfig from "../views/KeyValueNameConfig.js";
import { docClientQuery } from "./AWSConfig.js";

async function getOrdersPaidByMid(MemberID) {

    let res;
    if (MemberID) {
        if (MemberID.length > 0) {
            const paramsQuery = {
                TableName : kvnConfig.dbMainTableName,
                ExpressionAttributeNames: {
                    "#keyAlias1": "PK",
                    "#keyAlias2": "SK",
                    "#keyAlias3": "MemberID",
                    "#keyAlias4": "OrderStatusFlag",
                    "#keyAlias5": "PayResult"
                },
                ExpressionAttributeValues: {
                    ":valueAlias1": "Orders",
                    ":valueAlias2": "O#",
                    ":valueAlias3": MemberID,
                    ":valueAlias4": true,
                    ":valueAlias5": true
                },
                // 必須將分割區索引鍵名稱及數值指定為相等條件(#keyAlias1 = :valueAlias1)
                // a = b, a < b, a <= b, a > b, a >= b, a BETWEEN b AND c, begins_with (a, substr)
                KeyConditionExpression: "#keyAlias1 = :valueAlias1 and begins_with(#keyAlias2, :valueAlias2)",
                FilterExpression: "#keyAlias3 = :valueAlias3 and #keyAlias4 = :valueAlias4 and #keyAlias5 = :valueAlias5"
                ,ScanIndexForward: false // 預設true表示升序
                // ,Limit: '100' // 限制總資料筆數
                // ,ConditionExpression: "#keyAlias1 <> :valueAlias1" // 限制條件，條件成立才執行( <> 表示不等於 )
                // ,ExclusiveStartKey: "提取下一分頁所需參數，由上一次執行結果中取得LastEvaluatedKey，上次結果有此值才表示有下一頁，超過1MB會分頁"
            };
        
            res = await docClientQuery(paramsQuery);
        }
    }
    return res;
}
  
export default getOrdersPaidByMid;
import { updateItem } from "./DynamodbMethods.js";

async function updateCDNSecret(cdnSecretEntity) {
    
    let result;

    if (cdnSecretEntity) {
        cdnSecretEntity.PK = "CDN";
        cdnSecretEntity.SK = "Secret";
        if (cdnSecretEntity.Secret) {
            if (cdnSecretEntity.Secret.length > 0) {
                result = await updateItem(cdnSecretEntity);
            }
        }
    }
    return result;
}
  
export default updateCDNSecret;
import { getItem } from "./DynamodbMethods.js";

async function getProducerProfileCOMPByPid(ProducerIDString) {
    let res;
    if (ProducerIDString) {
        if (ProducerIDString.length > 0) {
            res = await getItem("P#"+ProducerIDString, "COMP#"+ProducerIDString);
        }
    }
    return res;
}
  
export default getProducerProfileCOMPByPid;
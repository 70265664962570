import { updateItem } from "./DynamodbMethods.js";

async function updateProducerProfileIDV(producerProfileIDVEntity) {
    
    let result;

    if (producerProfileIDVEntity) {

        producerProfileIDVEntity.TYPE = "P_PrivacyIDV";

        if (producerProfileIDVEntity.PK && producerProfileIDVEntity.SK) {
            if (producerProfileIDVEntity.PK.length > 0 && producerProfileIDVEntity.SK.length > 0) {
                result = await updateItem(producerProfileIDVEntity);
            }
        }
    }
    return result;
}
  
export default updateProducerProfileIDV;
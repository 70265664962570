import { updateItem } from "./DynamodbMethods.js";

async function updateVideoProfile(videoProfileEntity) {
    
    let result;

    if (videoProfileEntity) {

        videoProfileEntity.Type = "ContentInfo";

        if (videoProfileEntity.SK) {
            if (videoProfileEntity.SK.length > 0) {
                // do nothing
            } else {
                if (videoProfileEntity.ProductSKU){
                    if (videoProfileEntity.ProductSKU.length > 0) {
                        videoProfileEntity.SK = "VProfile#"+videoProfileEntity.ProductSKU;
                    }
                }
            }
        } else {
            if (videoProfileEntity.ProductSKU){
                if (videoProfileEntity.ProductSKU.length > 0) {
                    videoProfileEntity.SK = "VProfile#"+videoProfileEntity.ProductSKU;
                }
            }
        }

        if (videoProfileEntity.PK && videoProfileEntity.SK) {
            if (videoProfileEntity.PK.length > 0 && videoProfileEntity.SK.length > 0) {
                result = await updateItem(videoProfileEntity);
            }
        }
    }
    return result;
}
  
export default updateVideoProfile;
import React from "react";
function fbanner(props){
    return (<>
        <div className="fbanner">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <img src={require("assets/LandingPage/images/00_Banner.png").default} alt="" />
                </div>
                <div className="swiper-slide">
                    <img src={require("assets/LandingPage/images/01_Banner.png").default} alt="" />
                </div>
                <div className="swiper-slide">
                    <img src={require("assets/LandingPage/images/02_Banner.png").default} alt="" />
                </div>
                <div className="swiper-slide">
                    <img src={require("assets/LandingPage/images/03__Banner.png").default} alt="" />
                </div>
                <div className="swiper-slide">
                    <img src={require("assets/LandingPage/images/04_Banner.png").default} alt="" />
                </div>
                <div className="swiper-slide">
                    <img src={require("assets/LandingPage/images/05_Banner.png").default} alt="" />
                </div>
                <div className="swiper-slide">
                    <img src={require("assets/LandingPage/images/06_Banner.png").default} alt="" />
                </div>
            </div>
            <div className="spans">
                
            </div>
		</div>
    </>)

}
export default fbanner;
import React from "react";
import TagManager from 'react-gtm-module'
import "assets/FashionDraping/css/animate.css"
import "assets/FashionDraping/css/style.css"
import "assets/FashionDraping/css/swiper.min.css"
import "assets/FashionDraping/css/slicy.css"
import "assets/FashionDraping/css/responsive.css"
import HPage1 from "./hPage1.js";
import HPage2 from "./hPage2.js";
import HPage3 from "./hPage3.js";
import HPage4 from "./hPage4.js";
import HPage5 from "./hPage5.js";
import HPage6 from "./hPage6.js";
import HPage7 from "./hPage7.js";
import HPage8 from "./hPage8.js";
import HPage9 from "./hPage9.js";
import HPage10 from "./hPage10.js";
import HPage11 from "./hPage11.js";
import { Helmet } from 'react-helmet-async'
// 第一次執行時，所有的useState資料初始化會先執行，所有的useEffect會最後執行
// function內的其他代碼會在 useState初始化後，useEffect執行前，之間依序執行

// 第一次輪執行完後，如有任何useState資料的set動作
// 則會觸發function執行，然後觸發useEffect執行(陣列如果有涵蓋set的useState變數的話)

function FashionDraping(props) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
      pagePath: '/fashion-draping/basics/',
      pageTitle: 'Fashion-draping',
    },
  });
  return (
    <>
      <div className="layout">
      <Helmet>
            <title>課程系列-洋裝篇 - 服裝立裁打版 </title>
            <meta name='description' content='課程系列-洋裝篇 - 服裝立裁打版 ' />
        </Helmet>
        <div
          className="hlogo wow fadeInUp"
          data-wow-delay="0.4s"
          data-wow-duration="1s"
        >
          <img
            className="pc"
            alt=""
            src={require("assets/FashionDraping/images/logo.png").default}
          />
          <img
            className="sj"
            alt=""
            src={require("assets/FashionDraping/images/logo1.png").default}
          />
        </div>
      </div>
      <HPage1 {...props} />
      <HPage2 {...props} />
      <HPage3 {...props} />
      <HPage4 {...props} />
      <HPage5 {...props} />
      <HPage6 {...props} />
      <HPage7 {...props} />
      <HPage8 {...props} />
      <HPage9 {...props} />
      <HPage10 {...props} />
      <HPage11 {...props} />

      <div className="fokx">
        <a href="#">
          <img src={require("assets/FashionDraping/images/qm41.png").default} alt="" />
        </a>
      </div>
    </>
  );
}

export default FashionDraping;

import DataMethods from "data/DataMethods";
import {
  queryFashionMember,
  queryLineMember,
  queryMember,
} from "data/LineMethods";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useHistory  } from "react-router-dom";


function LineRedirect(props) {
  const [state, setState] = useState({});
  const [session, setSession] = useState();
  const [userInfo, setInfo] = useState(undefined);
  const [userName, setName] = useState("");
  const [isLoaded, setLoaded] = useState(false);
  const [MemberProfile, setMemberProfile] = useState(undefined);
  const [LineProfile, setLineProfile] = useState(undefined);
  const [userVpList, setUserVpList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const history = useHistory ();
  const getMemberProfile = async (payload) => {
    await queryMember(payload).then(async (res) => {
      if (res) {
        setMemberProfile(res);
        await getLineProfile(payload);
      } else {
        window.location.href = "/Register";
      }
    });
  };

  const getLineProfile = async (payload) => {
    await queryLineMember(payload).then(async (res) => {
      if (res) {
        setLineProfile(res);
        // await getFashionProfile(payload);
        // await getFashionLicenseAll(payload)
      } else {
        window.location.href = "/Register";
      }
    });
  };

  const loadSession = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const sessionToken = {
        idToken: currentSession.getIdToken().getJwtToken(),
        refreshToken: currentSession.getRefreshToken().getToken(),
        accessToken: currentSession.getAccessToken().getJwtToken(),
      };
      setSession(currentSession);
      return sessionToken;
    } catch {
      return null;
    }
  };

  const init = async () => {
    const sessionToken = await loadSession();
    if (sessionToken) {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", sessionToken.accessToken);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        "https://nnfeel0fu3.execute-api.ap-northeast-1.amazonaws.com/dev/productList",
        requestOptions
      );
      const data = await res.json();
      setUserVpList(data);
      if (!data || data.length === 0) {
        window.location.href = "/fashion-draping/basics/"
      } else {
        let start;
        let end;
        for (const item of data) {
          const s = dayjs(item.BuyDate, "YYYYMMDDHHmmss");
          const e = dayjs(item.ExpireDate, "YYYYMMDDHHmmss");
          if (!start || s.isBefore(start)) {
            start = s;
          }
          if (!end || e.isAfter(end)) {
            end = e;
          }
        }
        if (start) {
          setStartDate(start.format("YYYY-MM-DD HH:mm"));
        }
        if (end) {
          setEndDate(end.format("YYYY-MM-DD HH:mm"));
        }
        window.location.href = "/fashion-draping/basics/vp"
      }
    }
  };

  const [timer, setTimer] = useState(1);
  useEffect(() => {
    const id = setInterval(() => setTimer((c) => c - 1), 1000);
    if (timer === 0) {
      clearInterval(id);
    }
    if (props.info) {
      const info = props.info;
      setInfo(info);
      if (info && info.signInUserSession) {
        setName(info.signInUserSession.idToken.payload.nickname);
        const payLoad = info.signInUserSession.idToken.payload.identities[0].userId;
        getMemberProfile(payLoad);
        init();
        // getLineProfile(payLoad);
        // getFashionProfile(payLoad);
      } else {
        console.log("error");
      }
    }
    if (props.nickname) {
      const nickname = props.nickname;
      setName(nickname);
    }
   
    return () => {
      setState({}); // This worked for me
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.info]);

  return <>Redirect in {timer} seconds...</>;
}

export default LineRedirect;

import { updateItem } from "./DynamodbMethods.js";

async function updateMemberProfile(memberProfileEntity) {
    
    let result;

    if (memberProfileEntity) {

        memberProfileEntity.PK = "Members";
        memberProfileEntity.Type = "M_Main";

        if (memberProfileEntity.SK) {
            if (memberProfileEntity.SK.length > 0) {
                // do nothing
            } else {
                if (memberProfileEntity.MemberID){
                    if (memberProfileEntity.MemberID.length > 0) {
                        memberProfileEntity.SK = "M#"+memberProfileEntity.MemberID
                    }
                }
            }
        } else {
            if (memberProfileEntity.MemberID){
                if (memberProfileEntity.MemberID.length > 0) {
                    memberProfileEntity.SK = "M#"+memberProfileEntity.MemberID
                }
            }
        }

        if (memberProfileEntity.PK && memberProfileEntity.SK) {
            if (memberProfileEntity.PK.length > 0 && memberProfileEntity.SK.length > 0) {
                result = await updateItem(memberProfileEntity);
            }
        }
    }
    return result;
}
  
export default updateMemberProfile;
class InvoiceEntity {
    
    constructor(invoiceNumber) {
        this.PK = "Invoice";
        this.SK = invoiceNumber ? "I#"+invoiceNumber:"";
        this.Type = "Invoice";
        this.InvoiceNumber = invoiceNumber;
        this.InvoiceStatusFlag = false;
    }

    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK
        };
        if (this.Type) {
            obj["Type"] = this.Type;
        }
        if (this.InvoiceNumber) {
            obj["InvoiceNumber"] = this.InvoiceNumber;
        }
        if (this.InvoiceStatusFlag !== undefined && this.InvoiceStatusFlag !== null) {
            if (this.InvoiceStatusFlag) {
                obj["InvoiceStatusFlag"] = true;
            } else {
                obj["InvoiceStatusFlag"] = false;
            }
        }
        if (this.InvoiceContent) {
            obj["InvoiceContent"] = this.InvoiceContent;
        }

        // number
        if (this.InvoiceAmount !== undefined && this.InvoiceAmount !== null) {
            obj["InvoiceAmount"] = Math.floor(this.InvoiceAmount);
            if (isNaN(obj["InvoiceAmount"])) {
                obj["InvoiceAmount"] = 0;
            }
        }

        // NTD
        if (this.currecy) {
            obj["currecy"] = this.currecy;
        }
        if (this.OrderNumber) {
            obj["OrderNumber"] = this.OrderNumber;
        }

        // number
        if (this.tax !== undefined && this.tax !== null) {
            obj["tax"] = Math.floor(this.tax);
            if (isNaN(obj["tax"])) {
                obj["tax"] = 0;
            }
        }
        // number
        if (this.beforeTax !== undefined && this.beforeTax !== null) {
            obj["beforeTax"] = Math.floor(this.beforeTax);
            if (isNaN(obj["beforeTax"])) {
                obj["beforeTax"] = 0;
            }
        }
        
        if (this.MemberID) {
            obj["MemberID"] = this.MemberID;
        }
        if (this.ProducerID) {
            obj["ProducerID"] = this.ProducerID;
        }
        if (this.TotalPrice !== undefined && this.TotalPrice !== null) {
            obj["TotalPrice"] = Math.floor(this.TotalPrice);
            if (isNaN(obj["TotalPrice"])) {
                obj["TotalPrice"] = 0;
            }
        }
        if (this.Timestamp) {
            obj["Timestamp"] = this.Timestamp;
        }
        return obj;
    }
}

const invoiceEntityFromObject = (item) => {
    let entity = new InvoiceEntity("");
    entity.PK = item.PK;
    entity.SK = item.SK;
    if (item.Type) {
        entity.Type = item.Type;
    }
    if (item.InvoiceNumber) {
        entity.InvoiceNumber = item.InvoiceNumber;
    }
    if (item.InvoiceStatusFlag !== undefined && item.InvoiceStatusFlag !== null) {
        if (item.InvoiceStatusFlag) {
            entity.InvoiceStatusFlag = true;
        } else {
            entity.InvoiceStatusFlag = false;
        }
    }
    if (item.InvoiceContent) {
        entity.InvoiceContent = item.InvoiceContent;
    }
    if (item.InvoiceAmount !== undefined && item.InvoiceAmount !== null) {
        entity.InvoiceAmount = Math.floor(item.InvoiceAmount);
        if (isNaN(entity.InvoiceAmount)) {
            entity.InvoiceAmount = 0;
        }
    }
    if (item.currecy) {
        entity.currecy = item.currecy;
    }
    if (item.OrderNumber) {
        entity.OrderNumber = item.OrderNumber;
    }
    if (item.tax !== undefined && item.tax !== null) {
        entity.tax = Math.floor(item.tax);
        if (isNaN(entity.tax)) {
            entity.tax = 0;
        }
    }
    if (item.beforeTax !== undefined && item.beforeTax !== null) {
        entity.beforeTax = Math.floor(item.beforeTax);
        if (isNaN(entity.beforeTax)) {
            entity.beforeTax = 0;
        }
    }
    if (item.MemberID) {
        entity.MemberID = item.MemberID;
    }
    if (item.ProducerID) {
        entity.ProducerID = item.ProducerID;
    }
    if (item.TotalPrice !== undefined && item.TotalPrice !== null) {
        entity.TotalPrice = Math.floor(item.TotalPrice);
        if (isNaN(entity.TotalPrice)) {
            entity.TotalPrice = 0;
        }
    }
    if (item.Timestamp) {
        entity.Timestamp = item.Timestamp;
    }
    return entity;
}

export { InvoiceEntity, invoiceEntityFromObject };

// {
// 	PK: "Invoice",
// 	SK: "I#InvoiceNumber",
// 	Type: "Invoice",
// 	InvoiceNumber: "MemberID20211231095959",
// 	InvoiceStatusFlag: false,
// 	InvoiceContent: "",
// 	InvoiceAmount: 0,
// 	currecy: "NTD",
// 	OrderNumber: "",
// 	tax: 0,
// 	beforeTax: "0",
// 	MemberID: "",
// 	ProducerID: "",
// 	TotalPrice: 0,
// 	Timestamp: ""
// }
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import "assets/css/custom.css"
import { Auth } from "aws-amplify";
import {
  NavbarBrand,
  Navbar,
  Button,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
  Modal,
  ModalFooter,
} from "reactstrap";


function VideoStoreHeader(props){
    const [userInfo,setInfo] = useState(undefined);
    const [userName,setName] = useState("")
    const [modalName, setModalName] = useState(false);
    const [order,setOrders] = useState([])
    const [paymentHistory,setPayments] = useState([])
    const [modalOrder, setModalOrder] = useState(false);
    const [modalLoading,setModalLoading] = useState(false)

    const loadSession = async () => {
        try {
          const currentSession = await Auth.currentSession();
          const sessionToken = {
            idToken: currentSession.getIdToken().getJwtToken(),
            refreshToken: currentSession.getRefreshToken().getToken(),
            accessToken: currentSession.getAccessToken().getJwtToken(),
          };
          return sessionToken;
        } catch {
          return null;
        }
    };

    const getPaymentList = async () => {
        const sessionToken = await loadSession();
        if (sessionToken) {
          const myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Authorization", sessionToken.accessToken);
          const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };
          const res = await fetch(
            "https://nnfeel0fu3.execute-api.ap-northeast-1.amazonaws.com/dev/client/paymentList",
            requestOptions
          );
          const data = await res.json();
          if(data){
            setPayments(data)
          }
        }
      };

      const checkOrderList = async () =>{
        setModalLoading(true)
        await getOrdersList()
        await getPaymentList()
        setModalOrder(true)
        setModalLoading(false)
      }


      const getOrdersList = async () => {
        const sessionToken = await loadSession();
        if (sessionToken) {
          const myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Authorization", sessionToken.accessToken);
          const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };
          const res = await fetch(
            "https://nnfeel0fu3.execute-api.ap-northeast-1.amazonaws.com/dev/client/orderList",
            requestOptions
          );
          const data = await res.json();
          if(data){
            setOrders(data)
          }
        }
      };

    useEffect(() =>{
        if(props.info){
           const info = props.info
           setInfo(info)
           if(info && info.signInUserSession){
                setName(info.signInUserSession.idToken.payload.nickname)
           } 
           
        }
        if(props.nickname){
            const nickname = props.nickname
            setName(nickname)
        }
    },[props])

    const onClickHandler =  async () =>{
        const name = userName;
        if(name){
            await Auth.signOut();
        }else{
            await Auth.federatedSignIn({
                customProvider: "Line",
                autoSignIn: { // optional - enables auto sign in after user is confirmed
                    enabled: true,
                }
            }).then(cred => {
                // If success, you will get the AWS credentials
                return Auth.currentAuthenticatedUser();
            }).then(async user => {
                localStorage.setItem("info",JSON.stringify(user))
            }).catch(e => {
                console.log(e)
            });
        }
    }

    

    const ChangeCognitoName = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const name = prompt("請輸入你要更改的暱稱");
        let newAttribute = { nickname: name }
        if (name) {
            Auth.updateUserAttributes(user, newAttribute).then(async (res) => {
                const  newUser = await Auth.currentAuthenticatedUser();
                setName(newUser.attributes.nickname)
                setModalName(true)
             }).catch(e => {
                console.log("EEE", e);
             });
        }
    }

    const RegexId = (item) =>{
        if(!item){
            return  (  <div></div>)
        }
        if(!paymentHistory && paymentHistory.length <= 0) {
            return (<span></span>)
        }
        const isFound = paymentHistory.find((x) => x.ID === item)
        if(isFound){
          return  (
            <div>{isFound?.Msg}</div>
          )
    
        }else{
            return(
                <div></div>
            )
        }
    }

    const Logout = async () =>{
        const name = userName;
        if(name){
            await Auth.signOut();
            return
        }
        return
    }

    return (
        <>
        <Navbar  style={{zIndex:`3` }}className="" expand="lg">
            <Container fluid>
                <div className="navbar-translate">
                    <NavbarBrand to="/" tag={Link} id="navbar-brand">
                        <img src={require("assets/FashionDraping/images/videoStore.png").default}  alt="" style={{height:`100%`,width:`25vw`,maxWidth:`232px`,minWidth:`117px`}}/>
                    </NavbarBrand>
                    {userName ?
                        <UncontrolledDropdown  style={{left:`-15px`}}>
                            <DropdownToggle
                                aria-expanded={false}
                                aria-haspopup={true}
                                caret
                                color="link"
                                data-toggle="dropdown"
                                style={{height:`100%`,fontWeight:`bold`,fontSize:`16px`,color:`#000`,left:`-15px`}}
                                id="dropdownMenuButton"
                                type="button"
                                >
                                {userName}
                            </DropdownToggle>
                            <DropdownMenu aria-labelledby="dropdownMenuButton" >
                                <DropdownItem style={{fontWeight:`600`,fontSize:`14px`,color:`#000`}} onClick={(e) =>{ checkOrderList()}} >
                                    購買清單
                                </DropdownItem>
                                <DropdownItem style={{fontWeight:`600`,fontSize:`14px`,color:`#000`}}  onClick={(e) =>{ ChangeCognitoName()}} >
                                    更改姓名
                                </DropdownItem>
                                <DropdownItem style={{fontWeight:`600`,fontSize:`14px`,color:`#000`}}  onClick={(e) =>{ Logout()}} >
                                    Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>:
                        <Button 
                            color="neutral"
                            onClick={(e) =>{
                                onClickHandler()
                            }}  
                            type="button" 
                            label={userName ? `Line Logout`:'Line Login'}
                            style={{height:`100%`,fontWeight:`bold`,fontSize:`16px`,color:`#000`}} >
                            {userName ? userName:'會員登入'}
                        </Button>
                    }
                </div>
            </Container>
            <Modal
                isOpen={modalName}
                toggle={() => setModalName(false)}
              >
                <ModalFooter>
                <p>
                  名字修改成功!
                  </p>
                  <Button color="danger" onClick={() => setModalName(false)}>
                    X
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={modalOrder} toggle={() => setModalOrder(false)}>
                <div className="modal-header">
                  <button
                    aria-hidden={true}
                    className="close"
                    onClick={() => setModalOrder(false)}
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h5 className="modal-title" style={{ fontSize:'24px'}}> 
                        訂單記錄
                  </h5>
                </div>
                <div className="modal-body">
                    <hr className="solid"></hr>
                        {order.map((item, index) => (
                            <li key={index} style={{ fontSize:'18px'}}> {index + 1}. 
                                <span>  ID: {item.ID}</span>
                                <div>商品：{item.ProdDesc}</div>
                                <div>價格: {item.Amt}</div>
                                <div>{RegexId(item.ID)}</div>
                                  
                                 <hr className="solid"></hr>
                               
                            </li>
                        ))}
                    </div>
                <ModalFooter className="justify-content-center">
                    <div></div>
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={() => setModalOrder(false)}
                  >
                    關閉
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={modalLoading}
              ></Modal>
        </Navbar>
        </>
    )
}
export default VideoStoreHeader
class VideoProductionEntity {
    
    constructor(ProducerID, ProductSKU) {
        this.PK = (ProducerID ? ((ProducerID.length > 0) ? ("VP#"+ProducerID):""):"");
        this.SK = (ProductSKU ? ((ProductSKU.length > 0) ? ("VProduction#"+ProductSKU):""):"");
        this.Type = "ProduceTeam";
        this.ProductSKU = ProductSKU ? ProductSKU:"";
        // this.Actors = "";
        // this.AF = "";
        // this.Country = "";
        // this.EP = "";
        // this.PP = "";
        // this.Producers = "";
        // this.Publisher = "";
        // this.Sound = "";
    }

    changeProductSKU(ProductSKU) {
        this.SK = (ProductSKU ? ((ProductSKU.length > 0) ? ("VProduction#"+ProductSKU):""):"");
        this.ProductSKU = ProductSKU ? ProductSKU:"";
    }
    
    toObject() {
        let obj = {
            "PK": this.PK,
            "SK": this.SK,
            "ProductSKU": this.ProductSKU
        };
        if (this.Type) {
            obj["Type"] = this.Type;
        }
        if (this.Actors) {
            obj["Actors"] = this.Actors;
        }
        if (this.AF) {
            obj["AF"] = this.AF;
        }
        if (this.Country) {
            obj["Country"] = this.Country;
        }
        if (this.EP) {
            obj["EP"] = this.EP;
        }
        if (this.PP) {
            obj["PP"] = this.PP;
        }
        if (this.Producers) {
            obj["Producers"] = this.Producers;
        }
        if (this.Publisher) {
            obj["Publisher"] = this.Publisher;
        }
        if (this.Sound) {
            obj["Sound"] = this.Sound;
        }
        return obj;
    }
}

const videoProductionEntityFromObject = (item) => {
    let entity = new VideoProductionEntity("", "");
    entity.PK = item.PK;
    entity.SK = item.SK;
    entity.ProductSKU = item.ProductSKU;
    if (item.Type) {
        entity.Type = item.Type;
    }
    if (item.Actors) {
        entity.Actors = item.Actors;
    }
    if (item.AF) {
        entity.AF = item.AF;
    }
    if (item.Country) {
        entity.Country = item.Country;
    }
    if (item.EP) {
        entity.EP = item.EP;
    }
    if (item.PP) {
        entity.PP = item.PP;
    }
    if (item.Producers) {
        entity.Producers = item.Producers;
    }
    if (item.Publisher) {
        entity.Publisher = item.Publisher;
    }
    if (item.Sound) {
        entity.Sound = item.Sound;
    }
    return entity;
}

export { VideoProductionEntity, videoProductionEntityFromObject };

// {
//     "Actors": "錢欣郁",
//     "AF": "神野學",
//     "Country": "日本",
//     "EP": "三宅將典,高橋祐馬,藤尾明史",
//     "PK": "VP#coreytu",
//     "PP": "",
//     "Producers": "外崎春雄",
//     "Publisher": "東寶,Aniplex。",
//     "SK": "VProduction#coreytu01",
//     "ProductSKU": "coreytu01",
//     "Sound": "梶浦由記,椎名豪",
//     "Type": "ProduceTeam"
//   }